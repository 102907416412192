<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>
          <h3 class="deep-purple--text accent-4">
            <v-icon class="mr-2" color="yellow">mdi-alert</v-icon>Request for
            Account Activation
          </h3></v-card-title
        >
        <v-card-text>
          <p>Please send a request to the following contacts:</p>
          <ul>
            <li>+639306550892 - SMART</li>
            <li>+639762167424 - GLOBE</li>
          </ul>
          <p>or send an email to: support@kpcms.online</p>
          <p>Your message request should contain the following format:</p>
          <ol>
            <li>Full Name:</li>
            <li>Barangay:</li>
            <li>Municipality:</li>
          </ol>
          <br />
          <p>
            Activation of account usually takes up to 6-12hours. If your account
            is still inactive for atleast 2 days already, please do a resend of
            request format at the given numbers above.
          </p>
          <p>
            Once the accoutn has been activated, you will receive a text message
            confirming your account info and credentials.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false" color="deep-purple accent-4" dark>
            Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog"],
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
