<template>
  <div>
    <v-dialog v-model="alert" width="400px" persistent>
      <v-card flat width="400px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text ml-3">Alert</h3></v-sheet
        >
        <p class="text-center mt-9 ml-4 mr-4">
          Please register your barangay information before using this system!
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="deep-purple accent-4" dark @click="close()">
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("notification", ["alert"]),
  },
  methods: {
    ...mapActions({
      close_alert: "notification/close_alert",
    }),
    close() {
      this.close_alert(false).then(() => {
        this.$router.push("/userinformation");
      });
    },
  },
};
</script>
