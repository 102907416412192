<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Notice of Hearing for Conciliation</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">
            Notice of Hearing for Conciliation
          </h3></v-sheet
        >
        <!-- notice of hearing  -->
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <custom-date-picker
              @setDateTime="setDateData"
              label="Date and Time of Hearing"
            />

            <br />
            <v-text-field
              v-model="form.venue"
              class="body-2"
              label="Venue"
            ></v-text-field>

            <v-select
              label="Pangkat Chair"
              v-model="form.pangkat_chair"
              :items="complaint.lupon_cases"
              item-text="lupon.full_name"
            ></v-select>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
import moment from "moment";
import CustomDatePicker from "../../CustomDatePicker.vue";
export default {
  components: { CustomDatePicker },
  data() {
    return {
      json_dialog: false,
      form: {
        venue: "Barangay Hall",
        date: "",
      },
    };
  },
  computed: {
    conciliators() {
      let y = JSON.parse(this.complaint.conciliation.conciliators);

      return y;
    },
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      get_complaint: "complaint/get_complaint",
    }),
    check_date() {
      const { date } = this.form;
      const { date_of_last_hearing, date_of_first_hearing } =
        this.complaint.conciliation;

      const isBeforeFirstHearing = moment(date).isBefore(
        date_of_first_hearing,
        "day"
      );
      const isAfterLastHearing = moment(date).isAfter(
        date_of_last_hearing,
        "day"
      );

      if (isBeforeFirstHearing || isAfterLastHearing) {
        const notification = {
          message:
            "The Date and Time of Hearing should not go beyond the period of hearing",
          color: "red",
          showing: true,
          icon: "mdi-close",
        };

        this.set_notification(notification);
        return true;
      }
    },
    input_other_data() {
      this.json_dialog = true;
    },
    setDateData(data) {
      this.check_date();
      this.form.date = data;
    },
    print() {
      if (this.check_date()) return;
      if (this.form.date == "") return alert("Please enter date first");
      let other_data = {
        appearance_date: {
          hour: moment(this.form.date).format("h:mm"),
          month: moment(this.form.date).format("MMMM"),
          year: moment(this.form.date).format("YYYY"),
          day: moment(this.form.date).format("Do"),
          time_of_day: moment(this.form.date).format("a").toUpperCase(),
        },
        venue: this.form.venue,
        pangkat_chair: this.form.pangkat_chair,
      };
      window.open(
        this.print_url +
          this.user.id +
          "/notice_of_hearing_conciliation_proceedings/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
};
</script>
