<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          placeholder="type to search"
        ></v-text-field
      ></v-col>
    </v-row>
    <div v-if="unsettleds">
      <v-data-table
        :items="unsettleds"
        :headers="headers"
        fixed-header
        :search="search"
      >
        <template v-slot:[`item.complainant`]="{ item }">
          <div v-if="item.complainant != null">
            <div v-for="item in JSON.parse(item.complainant)" :key="item.id">
              {{ item.complainant_name }}
            </div>
          </div>
          <div v-else>
            <div>
              {{ item }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.respondent`]="{ item }">
          <div v-if="item.respondent != null">
            <div v-for="item in JSON.parse(item.respondent)" :key="item.id">
              {{ item.respondent_name }}
            </div>
          </div>
          <div v-else>
            <div>
              {{ item }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-col class="mt-1">
              <v-icon color="success" size="20" @click="view(item)"
                >mdi-eye-arrow-right
              </v-icon>
              <span
                class="font-weight-bold"
                style="color: green; font-size: 7pt"
                >VIEW</span
              >
            </v-col>
            <v-col class="mt-1">
              <v-icon
                color="red"
                size="20"
                :loading="submit_loading"
                @click="confirm_submit(item)"
                >mdi-keyboard-return
              </v-icon>
              <span class="font-weight-bold" style="color: red; font-size: 7pt"
                >UNDO</span
              >
            </v-col>
            <v-col class="mt-1">
              <v-icon color="red" size="20" @click="confirm_del(item)"
                >mdi-delete
              </v-icon>
              <span class="font-weight-bold" style="color: red; font-size: 7pt"
                >DELETE</span
              >
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <!-- CHANGE STATUS DIALOG -->
      <v-dialog v-model="dialog" width="400px" persistent>
        <v-card flat width="400px">
          <v-sheet color="deep-purple accent-4" class="" height="40px"
            ><h3 class="white--text ml-3">Select Status</h3></v-sheet
          >
          <v-card-text>
            <v-radio-group
              class="mt-6 ml-8"
              dense
              hide-details=""
              v-model="change_status"
              v-for="x in status"
              :key="x"
            >
              <v-radio :value="x" :label="x"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="deep-purple lighten-4" @click="dialog = false">
              cancel
            </v-btn>
            <v-btn
              small
              width="70px"
              color="deep-purple accent-4"
              dark
              @click="change(change_status)"
            >
              next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="show_alert">
        <confirm-notification
          icon="mdi-alert-outline"
          class="alert"
          type="warning"
          color="red"
          :message="this.alert_message"
          @confirm-event="undo"
          @cancel-event="close_confirm"
        />
      </div>
      <progress-linear :dialog="progress_dialog" />
    </div>
  </v-container>
</template>

<script>
import ProgressLinear from "@/components/ProgressLinear.vue";
import ConfirmNotification from "@/components/ConfirmNotification.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ConfirmNotification,
    ProgressLinear,
  },
  data() {
    return {
      search: "",
      submit_loading: false,
      show_alert: false,
      alert_message: "Are you sure you want to undo this complaint?",
      unsettled_or_arbitration: "",
      lupon_members: new Array(),
      executor: [],
      judges: [],
      change_status_item: "",
      dialog: false,
      dialog2: false,
      progress_dialog: false,
      default_status: "Ongoing",
      change_status: "",
      status: ["Unsettled", "Dismissed", "Repudiated"],

      headers: [
        {
          text: "Docket No.",
          value: "exclusive_case_no",
          width: "150px",
          // class: "grey white--text",
        },
        {
          text: "Case Title",
          value: "case_title",
          // class: "grey white--text",

          width: "350px",
        },
        {
          text: "Complainant/s",
          value: "complainant",
          // class: "grey white--text",
        },
        {
          text: "Respondent/s",
          value: "respondent",
          // class: "grey white--text",
        },

        {
          text: "Actions",
          value: "actions",
          // class: "grey white--text",

          width: "170px",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      // unsettleds: "complaint/unsettleds",
      complaints: "complaint/complaints",
      lupons: "lupon/lupons",
      user: "auth/user",
      complaint: "complaint/complaint",
      conciliations: "conciliation/conciliations",
      mediations: "mediation/mediations",
    }),
    unsettleds() {
      if (this.complaints == null) return [];
      else {
        let filtered = this.complaints.filter((m) => {
          return m.current_table == "Unsettled";
        });
        return filtered;
      }
    },
  },
  methods: {
    ...mapActions({
      undo_complaint: "complaint/undo_complaint",
      get_lupons: "lupon/get_lupons",
      set_complaint: "complaint/set_complaint",
      set_notification: "notification/set_notification",
      get_notifications: "notification/get_notifications",
      delete_complaint: "complaint/delete_complaint",
    }),
    confirm_del(item) {
      let confirm = window.confirm("Are you sure you want to proceed?");
      if (confirm) {
        this.delete_complaint(item).then(() => {
          alert("Successfuly deleted");
        });
      }
    },
    view(item) {
      this.progress_dialog = true;
      item.table_name = "unsettled";
      this.set_complaint(item).then(() => {
        // this.$emit("clicked");
        this.$router.push("/complaintprofile/" + item.exclusive_case_no);
        this.progress_dialog = false;
      });
    },

    open_dialog(item) {
      this.change_status_item = item;

      console.log(this.change_status_item);
      this.dialog = true;
    },
    confirm_submit(item) {
      console.log(item);
      this.submit_loading = true;
      this.change_status_item = item;
      this.show_alert = true;
    },
    close_confirm() {
      this.show_alert = false;
      this.submit_loading = false;
    },
    async undo() {
      this.show_alert = false;
      this.progress_dialog = true;
      let table_history = JSON.parse(
        this.change_status_item.table_route_history
      );
      let table_to = table_history[table_history.length - 2];

      this.change_status_item.table_name = table_to;
      await this.undo_complaint(this.change_status_item);
      await this.get_notifications();
      this.progress_dialog = false;
      this.set_notification({
        message: "Complaint successfully returned to previous table",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
      this.$router.push("/" + table_to);
    },
  },
  created() {},
};
</script>
<style scoped>
.v-data-table ::v-deep td {
  font-size: 12px !important;
}
</style>
