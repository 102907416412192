<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat class="pa-8 rounded-card mb-10 mt-n12">
          <v-card-text>
            <v-row class="mb-3">
              <v-col>
                <span class="subtitle-1 deep-purple--text accent-4">
                  {{ language == "English" ? english_heading : bisaya_heading }}
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row dense>
              <v-col v-if="language == 'English'">
                <v-switch
                  :disabled="item.hide"
                  v-model="item.value"
                  color="deep-purple accent-4"
                  v-for="item in questions"
                  :key="item.id"
                  :label="item.question"
                  dense
                  class="caption"
                  hide-details=""
                  @change="change_switch(item)"
                >
                </v-switch>
                <v-divider class="mt-5 mb-2"></v-divider>
              </v-col>
              <v-col v-else>
                <v-switch
                  :disabled="item.hide"
                  v-model="item.value"
                  color="deep-purple accent-4"
                  v-for="item in bisaya_questions"
                  :key="item.id"
                  :label="item.question"
                  dense
                  class="caption"
                  hide-details=""
                  @change="change_switch(item)"
                >
                </v-switch>
                <v-divider class="mt-5 mb-2"></v-divider>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :loading="button_loading"
              depressed
              class="rounded-xl"
              dark
              @click="submit"
              color="deep-purple accent-4"
              width="100px"
            >
              Proceed
            </v-btn>
            <v-btn
              depressed
              class="rounded-xl black--text"
              color="yellow"
              width="100px"
              @click="back"
            >
              Back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-dialog
        persistent
        v-model="show_question_3_sub_question"
        width="350"
        class="ma-auto"
      >
        <v-card>
          <v-card-text>
            <h3 class="text-center pt-5">
              {{
                language == "English" ? english_subquestion : bisaya_subquestion
              }}
            </h3>
            <div class="d-flex justify-center mt-3">
              <v-btn
                color="deep-purple accent-4 white--text"
                class="mr-2"
                @click="show_question_3_sub_question = false"
                >Yes</v-btn
              >
              <v-btn @click="false_question_3_sub_question">No</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <agency-modal :dialog="agency_dialog" @close="agency_dialog = false" />
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import ProgressLinear from "../ProgressLinear.vue";
import { mapActions, mapGetters } from "vuex";
import AgencyModal from "./AgencyModal.vue";
// import moment from "moment";
export default {
  components: {
    ProgressLinear,
    AgencyModal,
  },
  data() {
    return {
      english_subquestion:
        "Do the parties thereto agree to submit their difference to amicable settlement by an appropriate Lupon?",
      english_heading:
        "Some guide questions in determining whether the barangay has jurisdiction over the parties and the subject matter of the complaint pursuant to the Revised Rules on the Katarungang Pambarangay, to wit:",
      bisaya_subquestion:
        "Agree ba ang mga partido nga magpadayon sa ilang kinauyonan sa pagpamatuod pinaagi sa usa ka maayong pamaagi sa pagpamatuod sa usa ka angay nga Lupon?",
      bisaya_heading:
        "Pipila ka giya nga mga pangutana sa pagtino kung ang barangay adunay hurisdiksyon sa mga partido ug sa hilisgutan sa reklamo subay sa Revised Rules sa Katarungang Pambarangay:",
      show_question_3_sub_question: false,
      button_loading: false,
      agency_dialog: false,
      agency: "",
      value: "true",
      progress_dialog: false,
      mediator: new Array(),
      questions: [
        {
          id: "1",
          question:
            "Is any of the parties NOT a government, or any subdivision or instrumentality thereof?",
          value: false,
          hide: false,
        },
        {
          id: "2",
          question:
            "Is any of the parties NOT a public officer or employee, and the dispute relates NOT to the performance of his or her official function?",
          value: false,
          hide: false,
        },
        {
          id: "3",
          question:
            "Does the dispute involve real properties located in different cities and municipalities?",
          value: false,
          hide: false,
        },
        {
          id: "4",
          question:
            "Was the complaint filed NOT by or against corporations, partnership or juridical entities?",
          value: false,
          hide: false,
        },
        {
          id: "5",
          question:
            "Does the dispute involve parties who actually reside in barangays of same city or municipality?",
          value: false,
          hide: false,
        },
        {
          id: "6",
          question:
            "Or, where such barangay units adjoin each other, did the parties thereto agree to submit their differences to amicable settlement by an appropriate Lupon?",
          value: false,
          hide: false,
        },
        {
          id: "7",
          question:
            "Is the offense subject of the complaint punishable by a maximum penalty of imprisonment NOT exceeding one (1) year or a fine NOT over five thousand pesos (P5,000.00)?",
          value: false,
          hide: false,
        },
        {
          id: "8",
          question: "Did the above offense offend a private party?",
          value: false,
          hide: false,
        },
        {
          id: "9",
          question: "Is the Respondent NOT under police custody or detention?",
          value: false,
          hide: false,
        },
        {
          id: "10",
          question:
            "Does the dispute arise NOT from the Comprehensive Agrarian Reform Law?",
          value: false,
          hide: false,
        },
        {
          id: "11",
          question:
            "Does the dispute or controversy arise NOT from employer-employee relations?",
          value: false,
          hide: false,
        },
        {
          id: "12",
          question:
            "Does the dispute NOT involve an action to annul judgment upon a compromise?",
          value: false,
          hide: false,
        },
        {
          id: "13",
          question: "Did the complainant already pay the filing fee?",
          value: false,
          hide: false,
        },
      ],

      bisaya_questions: [
        {
          id: "1",
          question:
            "Ang bisan kinsa sa mga partido DILI ba usa ka gobyerno, o bisan unsang subdivision o instrumentalidad niini?",
          value: false,
          hide: false,
        },
        {
          id: "2",
          question:
            "Ang bisan kinsa sa mga partido DILI ba usa ka opisyal sa publiko o empleyado, ug ang reklamo DILI ba nalambigit sa pagbuhat sa iyang opisyal nga gimbuhaton?",
          value: false,
          hide: false,
        },
        {
          id: "3",
          question:
            "Ang reklamo ba may kalambigitan ba sa yuta nga nahimutang sa lain-laing mga siyudad o mga lungsod?",
          value: false,
          hide: false,
        },
        {
          id: "4",
          question:
            "Ang reklamo nga gisang-at DILI ba batok sa mga korporasyon, partnership o juridical entities?",
          value: false,
          hide: false,
        },
        {
          id: "5",
          question:
            "Ang reklamo ba naglambigit sa mga partido nga tinuod nga nagpuyo sa mga barangay sa samang siyudad o munisipyo?",
          value: false,
          hide: false,
        },
        {
          id: "6",
          question:
            "Kung diin ang maong mga barangay magkadugtong sa usag usa, miuyon ba ang mga partido niini nga isumiter ang ilang problema ngadto sa amicable settlement pinaagi sa angay nga Lupon?",
          value: false,
          hide: false,
        },
        {
          id: "7",
          question:
            "Ang salaod ba nga subject sa maong reklamo masilutan ug maximum penalty nga pagkabilanggo nga DILI molapas sa usa (1) ka tuig o multa nga DILI molapas sa lima ka libo ka pesos (P5,000.00)?",
          value: false,
          hide: false,
        },
        {
          id: "8",
          question: "Ang salaod nakapasakit ba sa usa ka pribadong partido?",
          value: false,
          hide: false,
        },
        {
          id: "9",
          question:
            "DILI ba ubos sa kustodiya o detensyon sa kapolisan ang respondent?",
          value: false,
          hide: false,
        },
        {
          id: "10",
          question:
            "DILI ba may kalambigitan sa Comprehensive Agrarian Reform Law ang reklamo?",
          value: false,
          hide: false,
        },
        {
          id: "11",
          question:
            "Ang reklamo ba DILI gikan sa relasyon sa amo ug empleyado?",
          value: false,
          hide: false,
        },
        {
          id: "12",
          question:
            "DILI ba naglambigit ang reklamo o aksyon sa pagkansela sa hukom sa usa ka kompromiso?",
          value: false,
          hide: false,
        },
        {
          id: "13",
          question: "Nabayran na ba sa complainant ang filing fee?",
          value: false,
          hide: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      complaint: "complaint/complaint",
      mediation: "mediation/mediation",
      pre_file_complaint: "complaint/pre_file_complaint",
      language: "auth/language",
    }),
  },
  created() {
    if (!this.pre_file_complaint) this.back();
    // this.questions = this.kp_questions;
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      add_complaint: "complaint/add_complaint",
      add_mediation: "mediation/add_mediation",
      get_mediations: "mediation/get_mediations",
      get_notifications: "notification/get_notifications",
      add_cases_handled: "auth/add_cases_handled",
      add_handled: "lupon/add_handled",
    }),
    false_question_3_sub_question() {
      this.questions[2].value = false;
      this.show_question_3_sub_question = false;
      this.set_notification({
        message:
          "The case is not within the jurisdiction of Katarungang Pambarangay",
        color: "error",
        showing: true,
        icon: "mdi-error",
      });
      // this reverts the questions array to the original status which is false.
    },
    back() {
      this.$router.go(-1);
    },
    change_switch(item) {
      if (item.id == "3" && item.value == true) {
        this.show_question_3_sub_question = true;
      }
      const questionSix = this.questions.find((q) => q.id === "6");
      const questionFive = this.questions.find((q) => q.id === "5");

      if (item.id === "5" || item.id === "6") {
        if (item.id === "5") {
          questionSix.hide = item.value;
        } else {
          questionFive.hide = item.value;
        }
      }

      item.value = !item.value;

      this.questions.forEach((q) => {
        if (q.id == item.id) q.value = !q.value;
      });
      console.log(this.questions);
    },
    revert_question_value() {
      this.questions.forEach((q) => {
        q.value = false;
      });
    },
    async submit() {
      this.button_loading = true;
      const is_not_complete = this.questions.some(
        (q) => q.value === false && !["3", "5", "6"].includes(q.id)
      );

      console.log(this.questions);
      if (is_not_complete) {
        this.button_loading = false;
        return (this.agency_dialog = true);
      }
      // this reverts the questions array to the original status which is false.
      this.revert_question_value();
      await this.add_complaint(this.pre_file_complaint);
      await this.get_notifications();
      // await this.add_cases_handled(this.user.id);
      // await this.add_mediation(this.pre_file_complaint);
      this.button_loading = false;
      this.set_notification({
        message: "You have successfully added a new complaint.",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
      this.$router.push(
        "/complaintprofile/" + this.pre_file_complaint.exclusive_case_no
      );
    },
    async submit_to_concerned_agency() {
      if (this.agency == "") {
        this.set_notification({
          message: "Please choose concerned agency",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
      } else {
        this.progress_dialog = true;
        this.pre_file_complaint.referred_to_concerned_agency = this.agency;
        await this.add_complaint(this.pre_file_complaint);
        this.progress_dialog = false;
        this.set_notification({
          message: "Successful",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style scoped>
.v-switch .v-label {
  font-size: 7px;
}

.rounded-card {
  border-radius: 20px;
}
</style>
