<template>
  <v-container class="mt-n2">
    <div v-if="ready" class="mb-15">
      <div class="mb-2">
        <v-row dense>
          <v-col>
            <v-card flat v-if="complaint">
              <v-card-title class="deep-purple--text accent-4 font-weight-bold">
                <v-btn
                  class="rounded-xl mr-2"
                  outlined
                  right
                  color="deep-purple accent-4"
                  dark
                  small
                  @click="back()"
                >
                  <v-icon small color="" class="mr-1">mdi-arrow-left</v-icon>
                  Back
                </v-btn>
                CASE PROFILE <v-spacer></v-spacer>
                <div class="d-flex justify-right">
                  <status-button :complaint="complaint" />

                  <mediation-forms
                    v-if="complaint.current_table == 'Mediation'"
                  />
                  <conciliation-forms
                    v-if="complaint.current_table == 'Conciliation'"
                  />
                  <arbitration-forms
                    v-if="complaint.current_table == 'Arbitration'"
                  />
                  <settled-forms
                    v-if="complaint.current_table == 'Execution'"
                  />
                  <unsettled-forms
                    v-if="complaint.current_table == 'Unsettled'"
                  />
                  <change-status-modal
                    v-if="complaint.current_table != 'Unsettled'"
                  />
                </div>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-if="user">
        <v-row v-if="complaint" dense>
          <v-col cols="8">
            <v-row class="subtitle-2" dense>
              <v-col cols="6">
                <v-card height="100">
                  <v-row dense class="ml-3">
                    <v-col cols="4" class="pt-3">
                      <span>Docket No.:</span><br />
                      <span>Case Title:</span>
                    </v-col>
                    <v-col cols="8" class="pt-3">
                      <span>{{ complaint.exclusive_case_no }}</span
                      ><br />
                      <span>{{ complaint.case_title }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card height="100">
                  <v-row dense class="ml-3">
                    <v-col cols="5" class="pt-3">
                      <span>Date of Filing:</span><br />
                      <span>{{
                        complaint.case_status == "Unsettled"
                          ? "Date of Last Hearing:"
                          : complaint.case_status != "Settled"
                          ? "Period of Hearing:"
                          : "Date of Agreement:"
                      }}</span>
                    </v-col>

                    <v-col cols="7" class="pt-3">
                      <span>{{ formatDate(complaint.created_at) }}</span
                      ><br />
                      <span v-if="complaint.current_table == 'Mediation'"
                        >{{
                          formatDate(complaint.mediation.date_of_first_hearing)
                        }}
                        -
                        {{
                          formatDate(complaint.mediation.date_of_last_hearing)
                        }}</span
                      >
                      <span
                        v-if="
                          complaint.current_table == 'Conciliation' &&
                          complaint.conciliation.date_of_first_hearing
                        "
                        >{{
                          formatDate(
                            complaint.conciliation.date_of_first_hearing
                          )
                        }}
                        -
                        {{
                          formatDate(
                            complaint.conciliation.date_of_last_hearing
                          )
                        }}</span
                      >
                      <span
                        v-if="
                          complaint.current_table == 'Conciliation' &&
                          !complaint.conciliation.date_of_first_hearing
                        "
                      >
                        None<add-period-of-hearing
                          v-if="complaint.conciliation.conciliators"
                        />
                      </span>

                      <span v-if="complaint.current_table == 'Arbitration'"
                        >{{
                          formatDate(
                            complaint.arbitration.date_of_first_hearing
                          )
                        }}
                        -
                        {{
                          formatDate(complaint.arbitration.date_of_last_hearing)
                        }}</span
                      >

                      <span v-if="complaint.current_table == 'Execution'">{{
                        formatDate(complaint.updated_at)
                      }}</span>

                      <span v-if="complaint.current_table == 'Unsettled'">{{
                        formatDate(complaint.unsettled.date_of_last_hearing)
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-card :height="col_height">
                  <v-card-title style="color: grey" class="subtitle-2 mb-0"
                    >COMPLAINANT/S</v-card-title
                  ><v-card
                    flat
                    v-for="complaint in JSON.parse(complaint.complainant)"
                    :key="complaint.complainant_name"
                  >
                    <v-card-subtitle class="subtitle-2">
                      <v-row dense>
                        <v-col cols="4">
                          <span style="color: grey">Name:</span><br />
                          <span style="color: grey">Age:</span><br />
                          <span style="color: grey">Civil Status:</span><br />
                          <span style="color: grey">Address:</span>
                        </v-col>
                        <v-col cols="8">
                          <span style="color: black">{{
                            complaint.complainant_name
                          }}</span
                          ><br />
                          <span style="color: black">{{
                            complaint.complainant_age
                          }}</span
                          ><br />
                          <span style="color: black">{{
                            complaint.complainant_civil_status
                          }}</span
                          ><br />
                          <span style="color: black">{{
                            complaint.complainant_address
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-card-subtitle>
                  </v-card>
                </v-card>
              </v-col>
              <v-col>
                <v-card :height="col_height">
                  <v-card-title style="color: grey" class="subtitle-2"
                    >RESPONDENT/S</v-card-title
                  ><v-card
                    flat
                    v-for="respondent in JSON.parse(complaint.respondent)"
                    :key="respondent.complainant_name"
                  >
                    <v-card-subtitle class="subtitle-2">
                      <v-row dense>
                        <v-col cols="4">
                          <span style="color: grey">Name:</span><br />
                          <span style="color: grey">Age:</span><br />
                          <span style="color: grey">Civil Status:</span><br />
                          <span style="color: grey">Address:</span>
                        </v-col>
                        <v-col cols="8">
                          <span style="color: black">{{
                            respondent.respondent_name
                          }}</span
                          ><br />
                          <span style="color: black">{{
                            respondent.respondent_age
                          }}</span
                          ><br />
                          <span style="color: black">{{
                            respondent.respondent_civil_status
                          }}</span
                          ><br />
                          <span style="color: black">{{
                            respondent.respondent_address
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-card-subtitle>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
            <div v-if="complaint.current_table == 'Mediation'">
              <mediator-display :complaint="complaint" />
            </div>
            <div v-if="complaint.current_table == 'Conciliation'">
              <conciliator-display :complaint="complaint" />
            </div>
            <div v-if="complaint.current_table == 'Arbitration'">
              <arbitrator-display :complaint="complaint" />
            </div>
            <div v-if="complaint.current_table == 'Execution'">
              <judge-display
                :complaint="complaint"
                :is_settled="1"
                :from_table="complaint.settled.from_table"
              />
            </div>

            <div v-if="complaint.current_table == 'Unsettled'">
              <judge-display
                :complaint="complaint"
                :is_settled="0"
                :from_table="complaint.unsettled.from_table"
              />
            </div>
            <v-row dense>
              <v-col>
                <v-card>
                  <v-card-title style="color: grey" class="subtitle-2">
                    <v-icon class="mr-2">mdi-chat-processing-outline</v-icon
                    >STATEMENT OF FACTS
                  </v-card-title>
                  <v-card-subtitle class="subtitle-2 mt-1 text-indent">
                    {{ complaint.statement_of_facts }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-card>
                  <v-card-title style="color: grey" class="subtitle-2">
                    <v-icon class="mr-2">mdi-hand-coin-outline</v-icon>RELIEFS
                    SOUGHT</v-card-title
                  >
                  <v-card-subtitle class="subtitle-2 mt-1 text-indent">
                    {{ complaint.reliefs_sought }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="complaint.settled">
              <v-col>
                <v-card>
                  <v-card-title
                    style="color: grey"
                    class="subtitle-2 text-uppercase"
                  >
                    <v-icon class="mr-2">mdi-draw-pen</v-icon
                    >Agreement</v-card-title
                  >
                  <v-card-subtitle class="subtitle-2 mt-1 text-indent">
                    <ol>
                      <li
                        v-for="(agreement, index) in JSON.parse(
                          complaint.settled.agreement
                        )"
                        :key="index"
                      >
                        {{ agreement.agreement }}
                      </li>
                    </ol>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
            <!-- </v-card> -->
          </v-col>
          <!-- generate form component -->
          <v-col cols="4">
            <v-card flat style="margin-top: -25px">
              <history-chart :complaint="complaint" />
            </v-card>
          </v-col>
        </v-row>
      </div>

      <progress-linear :dialog="progress_dialog" />
    </div>
    <loading-alert-modal
      :dialog="loading_alert_dialog"
      :data="loading_alert_data"
      :id="loading_alert_id"
    />
    <new-complaint-forms v-if="new_complaint" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "../components/ProgressLinear.vue";
// import PrintForm from "../components/PrintForm.vue";
import HistoryChart from "../components/HistoryChart.vue";
import moment from "moment";
import AddPeriodOfHearing from "@/components/ConciliationManagement/AddPeriodOfHearing.vue";
import LoadingAlertModal from "../components/LoadingAlertModal.vue";
import StatusButton from "../components/StatusButton.vue";
// displays
import MediatorDisplay from "@/components/Displays/MediatorDisplay.vue";
import ConciliatorDisplay from "@/components/Displays/ConciliatorDisplay.vue";
import ArbitratorDisplay from "@/components/Displays/ArbitratorDisplay.vue";
import JudgeDisplay from "@/components/Displays/JudgeDisplay.vue";

import NewComplaintForms from "../components/FilingOfComplaintManagement/NewComplaintForms.vue";

import MediationForms from "../components/Forms/MediationForms.vue";
import ConciliationForms from "../components/Forms/ConciliationForms.vue";
import ArbitrationForms from "../components/Forms/ArbitrationForms.vue";
import SettledForms from "../components/Forms/SettledForms.vue";
import UnsettledForms from "../components/Forms/UnsettledForms.vue";

export default {
  components: {
    AddPeriodOfHearing,
    // PrintForm,
    ProgressLinear,
    ChangeStatusModal: () => import("@/components/ChangeStatusModal.vue"),
    HistoryChart,
    LoadingAlertModal,
    StatusButton,
    MediatorDisplay,
    ConciliatorDisplay,
    ArbitratorDisplay,
    JudgeDisplay,
    NewComplaintForms,
    MediationForms,
    ConciliationForms,
    ArbitrationForms,
    SettledForms,
    UnsettledForms,
  },
  data() {
    return {
      ready: false,
      loading_alert_dialog: false,
      loading_alert_data: "",
      loading_alert_id: "",
      agreement_array: [],
      first_hearing: "",
      last_hearing: "",
      status_color: "",
      col_height: 220,
      table_to: "",

      lupon_members: new Array(),
      edit_item: { id: "", lupons: "", table_name: "" },
      dialog: false,

      progress_dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      new_complaint: "complaint/new_complaint",
      lupons: "lupon/lupons",
      user: "auth/user",
    }),
  },
  methods: {
    loading_alert_modal(id, data, dialog) {
      this.loading_alert_data = data;
      this.loading_alert_id = id;
      this.loading_alert_dialog = dialog;
    },
    ...mapActions({
      edit_arbitration_lupons: "complaint/edit_arbitration_lupons",
    }),

    close_add_lupon_dialog() {
      this.add_lupon_dialog = false;
    },
    edit_lupons() {
      this.edit_item.id = this.complaint.exclusive_case_no;
      this.edit_item.lupons = this.lupon_members;
      this.edit_item.table_name = this.complaint.current_table;

      this.progress_dialog = true;
      if (this.complaint.current_table == "Conciliation") {
        this.edit_conciliation_lupons(this.edit_item).then(() => {
          this.progress_dialog = false;
          // this.dialog2 = false;
        });
      }
      if (this.complaint.current_table == "Arbitration") {
        this.edit_arbitration_lupons(this.edit_item).then(() => {
          this.progress_dialog = false;
          // this.dialog2 = false;
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
    formatDate(date) {
      return moment(date).format("MMM D, YYYY");
    },
    formatDate_time1(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [month, day, year].join("/");
    },
    formatDate_time2(date) {
      var d = new Date(date),
        hour = "" + d.getHours(),
        min = "" + d.getMinutes();
      if (hour.length < 2) hour = "0" + hour;
      if (min.length < 2) min = "0" + min;

      return [hour, min].join(":");
    },
    am_pm(data) {
      if (data > 12) {
        return "PM";
      } else {
        return "AM";
      }
    },
  },
  mounted() {},
  created() {
    if (this.complaint == null) {
      this.$router.go(-1);
    } else {
      this.loading_alert_modal("1", "Complaint", true);
      this.ready = true;
      this.loading_alert_modal("1", "Complaint", false);

      const c = JSON.parse(this.complaint.complainant).length;
      const r = JSON.parse(this.complaint.respondent).length;
      if (c > 1 || r > 1) {
        this.col_height += 120;
      }
    }
  },
};
</script>
<style scoped>
.text-indent {
  text-indent: 30px;
}
</style>
