<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Arbitration Award</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">
            Arbitration Award
          </h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <div>
              <v-row>
                <v-col cols="11">
                  <v-textarea
                    v-model="form.award"
                    label="Award"
                    prepend-icon="mdi-draw-pen"
                  ></v-textarea>
                  <v-text-field
                    dense
                    outlined
                    v-model="form.venue"
                    label="Venue"
                  ></v-text-field>
                  <v-text-field
                    dense
                    outlined
                    v-model="form.signatory"
                    label="Arbitrator"
                  ></v-text-field>
                  <v-radio-group
                    v-model="form.signatory_position"
                    label="Arbitrator"
                    @change="check_signatory_position('signatory')"
                  >
                    <v-radio
                      value="Punong Barangay"
                      label="Punong Barangay"
                    ></v-radio>
                    <v-radio
                      value="Pangkat Chair"
                      label="Pangkat Chair"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                    dense
                    outlined
                    v-model="form.member1"
                    label="Member"
                  ></v-text-field>
                  <v-text-field
                    dense
                    outlined
                    v-model="form.member2"
                    label="Member"
                  ></v-text-field>
                  <v-text-field
                    dense
                    outlined
                    v-model="form.attested_signatory"
                    label="Attested by:"
                  ></v-text-field>
                  <v-radio-group
                    v-model="form.attested_signatory_position"
                    label="Attested by"
                    @change="check_signatory_position('attested_signatory')"
                  >
                    <v-radio
                      value="Punong Barangay"
                      label="Punong Barangay"
                    ></v-radio>
                    <v-radio
                      value="Lupon Secretary"
                      label="Lupon Secretary"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
export default {
  data() {
    return {
      json_dialog: false,
      form: {
        award: "",
        venue: "Barangay Hall",
        signatory: "",
        signatory_position: "Lupon Chairman",
        member1: "",
        member2: "",
        attested_signatory: "",
        attested_signatory_position: "Lupon Chairman",
      },
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    check_signatory_position(type) {
      if (type === "signatory") {
        if (this.form.signatory_position == "Punong Barangay")
          this.form.signatory = this.user.name_of_punong_barangay;
        else this.form.signatory = "";
      } else {
        if (this.form.attested_signatory_position == "Punong Barangay")
          this.form.attested_signatory = this.user.name_of_punong_barangay;
        else this.form.attested_signatory = "";
      }
    },
    input_other_data() {
      this.json_dialog = true;
    },

    print() {
      let other_data = {
        award: this.form.award,
        venue: this.form.venue,
        signatory: this.form.signatory,
        signatory_position: this.form.signatory_position,
        member1: this.form.member1,
        member2: this.form.member2,
        attested_signatory: this.form.attested_signatory,
        attested_signatory_position: this.form.attested_signatory_position,
      };

      window.open(
        this.print_url +
          this.user.id +
          "/arbitration_award/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
  created() {
    this.form.mediator = this.user.name_of_punong_barangay;
  },
};
</script>
