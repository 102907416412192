<template>
  <div>
    <v-btn
      dark
      color="deep-purple accent-4"
      class="rounded-xl"
      @click="dialog = true"
    >
      <v-icon small left>mdi-plus-circle</v-icon>
      Add Member
    </v-btn>
    <v-dialog persistent v-model="this.dialog" max-width="500">
      <v-sheet
        color="deep-purple accent-4"
        class="pt-2 d-flex justify-center"
        height="40px"
        ><h3 class="white--text">Add Lupon Member</h3></v-sheet
      >

      <v-card class="pa-4" flat tile
        ><br />
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              :rules="[rules.required]"
              v-model="form.full_name"
              prepend-icon="mdi-account-tie"
              label="Name"
            ></v-text-field>
            <v-select
              :rules="[rules.required]"
              :items="civil_status"
              v-model="form.civil_status"
              prepend-icon="mdi-account-heart"
              label="Civil Status"
            ></v-select>
            <v-select
              :rules="[rules.required]"
              :items="gender"
              v-model="form.gender"
              prepend-icon="mdi-gender-male-female"
              label="Gender"
            ></v-select>
            <v-text-field
              :rules="[rules.required]"
              v-model="form.permanent_address"
              prepend-icon="mdi-map-marker"
              label="Permanent Address"
            ></v-text-field>

            <v-text-field
              :rules="[rules.required]"
              prepend-icon="mdi-school"
              label="Highest Educational Attainment"
              v-model="form.highest_educational_attainment"
            ></v-text-field>
            <v-text-field
              :rules="[rules.required]"
              prepend-icon="mdi-calendar-question"
              label="Term of Office"
              v-model="form.term_of_office"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="deep-purple accent-4"
            dark
            class="ml-2 rounded-xl"
            @click="submit"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false" color="" class="rounded-xl"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
// import moment from "moment";
export default {
  components: {
    ProgressLinear,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },
      gender: ["Male", "Female"],
      notifications: { notification: "" },
      progress_dialog: false,
      form: {
        full_name: "",
        civil_status: "",
        permanent_address: "",
        highest_educational_attainment: "",
        term_of_office: "",
        number_of_cases_handled: 0,
        number_of_cases_settled: 0,
        number_of_cases_unsettled: 0,
      },
      civil_status: ["Single", "Married", "Widow/Widower"],
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      add_lupon: "lupon/add_lupon",
      set_notification: "notification/set_notification",
      add_notification: "notification/add_notification",
    }),
    is_form_validated() {
      if (this.$refs.form.validate()) return true;
      else
        this.set_notification({
          message: "All fields are required!",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
    },
    async submit() {
      if (!this.is_form_validated()) return;
      this.progress_dialog = true;
      await this.add_lupon(this.form);
      this.$refs.form.reset();
      this.set_notification({
        message: "You have successfully added a new lupon member",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
      this.progress_dialog = false;
      this.dialog = false;
    },
  },
};
</script>
