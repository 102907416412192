<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Motion For Execution</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">
            motion for execution
          </h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <v-dialog
              ref="date_settled_dialog"
              v-model="date_settled_dialog"
              :return-value.sync="form.date_settled"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="form.date_settled"
                  label="Date Settled"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.date_settled" scrollable>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="date_settled_dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.date_settled_dialog.save(form.date_settled)"
                >
                  OK
                </v-btn>
              </v-date-picker> </v-dialog
            ><br />
            <v-dialog
              ref="date_printed_dialog"
              v-model="date_printed_dialog"
              :return-value.sync="form.date_printed"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="form.date_printed"
                  label="Date Printed"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.date_printed" scrollable>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="date_printed_dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.date_printed_dialog.save(form.date_printed)"
                >
                  OK
                </v-btn>
              </v-date-picker> </v-dialog
            ><br />
            <v-text-field
              dense
              v-model="form.action_by"
              label="Action by"
            ></v-text-field>
            <v-radio-group v-model="form.side" label="Side">
              <v-radio value="Complainant" label="Complainant/s"></v-radio>
              <v-radio value="Respondent" label="Respondent/s"></v-radio>
            </v-radio-group>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
import moment from "moment";
export default {
  data() {
    return {
      json_dialog: false,
      date_settled_dialog: false,
      date_printed_dialog: false,
      form: {
        date_settled: "",
        date_printed: "",
        action_by: "",
        side: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),

    input_other_data() {
      this.json_dialog = true;
    },
    print() {
      let other_data = {
        date_settled: this.form.date_settled,
        date_printed: this.form.date_printed,
        action_by: this.form.action_by,
        side: this.form.side,
      };
      window.open(
        this.print_url +
          this.user.id +
          "/motion_for_execution/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
  created() {
    this.form.date_settled = moment(this.complaint.settled.date_settled).format(
      "MMMM D, YYYY"
    );
    this.form.date_printed = moment(new Date()).format("MMMM D, YYYY");
  },
};
</script>
