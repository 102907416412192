<template>
  <v-row dense align="center" justify="center">
    <v-col>
      <v-card>
        <v-card-title style="color: grey" class="subtitle-2">
          {{ judge }}:
        </v-card-title>
        <v-card-text class="justify-center">
          <v-row class="" style="margin-top: ">
            <v-col
              cols="4"
              v-for="(lupon, i) in JSON.parse(complaint.settled.executor)"
              :key="i"
              ><span
                ><v-img src="@/assets/profile.jpg" width="200"></v-img
              ></span>
              <p class="text-center font-weight-bold">
                {{ display_full_name(lupon) }}
              </p>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: ["complaint", "from_table", "is_settled"],
  data: () => ({
    add_conciliators_dialog: false,
    edit_conciliators_dialog: false,
  }),
  computed: {
    ...mapGetters({
      lupons: "lupon/lupons",
      user: "auth/user",
    }),
    judge() {
      let judge = "";
      if (this.from_table == "mediation") judge = "MEDIATOR";
      if (this.from_table == "conciliation") judge = "CONCILIATOR";
      if (this.from_table == "arbitration") judge = "ARBITRATOR";
      return judge;
    },
  },

  methods: {
    display_full_name(lupon_id) {
      let lupon = "";
      this.lupons.forEach((element) => {
        if (element.id == lupon_id) lupon = element.full_name;
      });
      2;
      return lupon;
    },
  },
  created() {
    console.log("is settled", this.is_settled);
  },
};
</script>

<style></style>
