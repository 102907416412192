<template>
  <div>
    <v-snackbar
      value="true"
      timeout="-1"
      right
      shaped
      top
      :color="color"
      :icon="icon"
      dark
      border="left"
      prominent
      width="650"
    >
      <v-row>
        <v-icon class="ml-2" color="yellow" x-large>{{ icon }}</v-icon>
        <span class="mt-3 ml-4">
          {{ message }}
        </span>
        <v-spacer></v-spacer>
        <v-btn dark text small class="mt-2" @click="cancel"> cancel </v-btn>
        <v-btn dark text small class="mt-2" @click="confirm"> confirm </v-btn>
      </v-row>

      <!-- <v-col cols="2">
          <v-btn  color="success" class="mb-2" @click="confirm">Yes</v-btn>
          <v-btn  color="red" @click="cancel">Cancel</v-btn>
        </v-col> -->
    </v-snackbar>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
export default {
  props: ["type", "message", "will_confirm", "color", "icon"],
  data() {
    return {};
  },
  created() {},
  methods: {
    // ...mapActions({
    //   confirm_action: "notification/confirm",
    // }),
    confirm() {
      this.$emit("confirm-event");
    },
    cancel() {
      this.$emit("cancel-event");
    },
  },
};
</script>

<style scoped></style>
