<template>
  <!-- REGISTER TAB -->
  <div class="mt-10 mr-11 ml-5 text-center">
    <v-form ref="form">
      <v-text-field
        :rules="[rules.required]"
        prepend-inner-icon="mdi-account"
        placeholder="Full Name"
        outlined
        dense
        v-model="form.name"
      ></v-text-field>
      <v-text-field
        :rules="[rules.required]"
        prepend-inner-icon="mdi-account-outline"
        placeholder="Username"
        outlined
        dense
        v-model="form.username"
      ></v-text-field>
      <v-text-field
        prepend-inner-icon="mdi-mail"
        placeholder="Email Address"
        outlined
        dense
        v-model="form.email_address"
        :rules="[rules.email]"
      ></v-text-field>
      <v-select
        prepend-inner-icon="mdi-chat-question"
        placeholder="Secret Question"
        outlined
        dense
        :items="secret_questions"
        v-model="form.secret_question"
        :rules="[rules.required]"
      ></v-select>
      <v-text-field
        type="text"
        prepend-inner-icon="mdi-pencil"
        placeholder="Secret Answer"
        outlined
        dense
        :rules="[rules.required]"
        v-model="form.secret_answer"
      ></v-text-field>
      <v-text-field
        type="password"
        prepend-inner-icon="mdi-lock-outline"
        placeholder="Password"
        outlined
        dense
        :rules="passwordRules"
        v-model="form.password"
      ></v-text-field>
      <v-text-field
        type="password"
        prepend-inner-icon="mdi-lock-outline"
        placeholder="Confirm password"
        outlined
        dense
        :rules="confirmPasswordRules"
        v-model="confirmPassword"
      ></v-text-field>
    </v-form>
    <v-btn
      dark
      block
      elevation="2"
      color="deep-purple accent-4"
      class="mr-2"
      @click="submit()"
      >REGISTER</v-btn
    >
    <v-divider class="mt-"></v-divider>
    <p class="caption grey--text text--lighten-1 mt-5">
      Powered by: JMBComputers & IT Solutions | Version 1.0
    </p>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="submit"
        @cancel-event="close_confirm"
      />
    </div>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
import ConfirmNotification from "@/components/ConfirmNotification.vue";

export default {
  components: {
    ProgressLinear,
    ConfirmNotification,
  },
  data() {
    return {
      secret_questions: [
        "What is your mother's maiden name?",
        "What is the name of your first pet?",
        "What is the name of the school you first enrolled?",
        "What is your nick name?",
      ],
      progress_dialog: false,
      show_alert: false,
      form: {
        name: "",
        username: "",
        password: "",
        email_address: "",
        secret_questions: "",
        secret_answer: "",
      },

      confirmPassword: "",
      passwordRules: [(v) => !!v || "Password is required"],
      confirmPasswordRules: [
        (v) => !!v || "Password is required",
        (v) => v === this.form.password || "The password does not match.",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        // password: value => {
        //   const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        //   return (
        //     pattern.test(value) ||
        //     "Min. 8 characters with at least one capital letter, a number and a special character."
        //   );
        // }
      },
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || "Password must match";
    },
  },
  methods: {
    ...mapActions({
      register: "auth/register",
      set_notification: "notification/set_notification",
    }),
    confirm_submit() {
      this.show_alert = true;
    },
    close_confirm() {
      this.show_alert = false;
    },
    submit() {
      this.progress_dialog = true;
      if (this.$refs.form.validate()) {
        this.register(this.form)
          .then(() => {
            this.progress_dialog = false;
            this.$emit("open_login");
            this.set_notification({
              message: "You have successfully added a new account.",
              color: "success",
              showing: true,
              icon: "mdi-check",
            });
          })
          .catch(() => {
            this.set_notification({
              message:
                "Something went wrong. Please contact the administrator.",
              color: "red",
              showing: true,
              icon: "mdi-alert",
            });

            this.loading = false;
          });
      } else {
        this.set_notification({
          message: "All fields are required!",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
        this.progress_dialog = false;
      }
    },
  },
};
</script>
