<template>
  <div>
    <h3>{{ Heading }}</h3>
    <v-select
      label="Province"
      :items="provinces_arr"
      item-text="province_name"
      item-value="province_code"
      v-model="province_code"
      @change="get_cities"
    ></v-select>
    <v-select
      label="Municipality"
      :items="municipalities_arr"
      item-text="city_name"
      v-model="municipality"
      @change="get_barangays"
    ></v-select>
    <v-select
      :disabled="!municipality"
      label="Barangay"
      :items="barangays_arr"
      item-text="brgy_name"
      v-model="barangay"
      @change="extract_address_value"
    ></v-select>
  </div>
</template>

<script>
import provinces from "@/json/provinces.json";
import barangays from "@/json/barangays.json";
import cities from "@/json/cities.json";
export default {
  props: ["Heading", "Fields"],
  data() {
    return {
      enable_purok: false,
      province_code: [],
      province_name: "",
      municipality: null,
      barangay: null,
      purok: null,
      button_text: "save address",
      button_text2: "get brgys",
      barangays_arr: ["Please click GET BRGYS Button"],
      municipalities_arr: [],
      provinces_arr: [],
    };
  },
  methods: {
    sort_provinces() {
      provinces.sort((a, b) => {
        return a.province_name.localeCompare(b.province_name);
      });
      this.provinces_arr = provinces;
    },
    get_cities() {
      var city = cities.filter((c) => {
        return c.province_code == this.province_code;
      });
      this.municipalities_arr = city;
      this.extract_address_value();
      this.extract_province_name();
    },
    extract_province_name() {
      var province_name = provinces.filter((p) => {
        return p.province_code == this.province_code;
      });
      //   console.log(province_name[0]);
      this.province_name = province_name[0].province_name;
    },

    extract_city_code(city_name) {
      // extract city code from city name
      var city = cities.filter((c) => {
        return c.city_name == city_name;
      });
      var c = city[0];
      return c.city_code;
    },

    get_barangays() {
      var city_code = this.extract_city_code(this.municipality);

      var brgys = barangays.filter((b) => {
        return b.city_code == city_code;
      });
      this.barangays_arr = brgys;
      this.button_text2 = "Saved";
      setTimeout(() => {
        this.button_text2 = "get brgys";
      }, 3000);

      this.extract_address_value();
    },
    extract_address_value() {
      var address_arr = [this.province_name, this.municipality, this.barangay];
      this.button_text = "Saved";
      setTimeout(() => {
        this.button_text = "Save address";
      }, 3000);

      this.$emit("extract_address_value", address_arr);
    },
  },
  mounted() {
    this.sort_provinces();
    this.get_cities();
  },
};
</script>

<style></style>
