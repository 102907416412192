<template>
  <div>
    <v-snackbar
      :value="notification.showing"
      right
      shaped
      top
      :color="notification.color"
      width="420px"
      timeout="7000"
    >
      <div class="text-center">
        <v-icon class="pr-2">{{ notification.icon }}</v-icon
        >{{ this.notification.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      snackbar: true,
      timeout: 10000,
    };
  },
  computed: {
    ...mapState("notification", ["notification"]),
  },
  created() {
    // console.log(this.notification);
  },
};
</script>

<style></style>
