<template>
  <div>
    <v-btn
      depressed
      small
      color="green"
      class="ml-1 rounded-xl white--text"
      @click="dialog = true"
      ><v-icon class="mr-1" small>mdi-printer-outline</v-icon> Generate
      form</v-btn
    >
    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-5">
        <v-card-text>
          <div>
            <p style="font-weight: bold">GENERATE FORMS</p>
            <v-divider class="mb-5"></v-divider>
          </div>
          <div class="pl-2">
            <agreement-for-arbitration />
            <failure-to-appear />
            <subpoena />
            <arbitration-award />
            <repudiation />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import FailureToAppear from "./ArbitrationFormComponents/FailureToAppear.vue";
import AgreementForArbitration from "./ArbitrationFormComponents/AgreementForArbitration.vue";
import Repudiation from "./ArbitrationFormComponents/Repudiation.vue";
import Subpoena from "./MediationFormComponents/Subpoena.vue";
import ArbitrationAward from "./ArbitrationFormComponents/ArbitrationAward.vue";

export default {
  components: {
    FailureToAppear,
    AgreementForArbitration,
    Repudiation,
    Subpoena,
    ArbitrationAward,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
