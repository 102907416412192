<template>
  <div class="pt-2 pb-2">
    <v-dialog v-model="dialog" width="400">
      <v-sheet color="deep-purple accent-4" class="pa-4"
        ><p class="font-weight-bold white--text">ENTER NEW PASSWORD</p>

        <div>
          <p class="white--text caption mt-n3">Username: {{ item.username }}</p>
          <p class="white--text mt-n4 caption mb-n2">Name: {{ item.name }}</p>
        </div>
      </v-sheet>
      <v-card tile>
        <v-card-text class="pa-4 pt-8">
          <v-text-field
            type="password"
            prepend-inner-icon="mdi-lock-outline"
            placeholder="New password"
            outlined
            dense
            :rules="passwordRules"
            v-model="form.password"
          ></v-text-field>
          <v-text-field
            type="password"
            prepend-inner-icon="mdi-lock-outline"
            placeholder="Confirm new password"
            outlined
            dense
            :rules="confirmPasswordRules"
            v-model="confirmPassword"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="d-flex justify-end pr-4 pl-4 pb-4 mt-n4">
          <v-btn
            class="rounded-xl mr-1 white--text"
            color="deep-purple accent-4"
            small
            @click="change_password"
            >Submit</v-btn
          >
          <v-btn class="rounded-xl" color="yellow" @click="close_dialog" small
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  props: ["dialog", "item"],
  components: {
    ProgressLinear,
  },
  data() {
    return {
      progress_dialog: false,
      form: {
        password: "",
      },
      confirmPassword: "",
      passwordRules: [(v) => !!v || "Password is required"],
      confirmPasswordRules: [
        (v) => !!v || "Password is required",
        (v) => v === this.form.password || "The password does not match.",
      ],
    };
  },
  methods: {
    ...mapActions({
      new_password: "auth/new_password",
      set_notification: "notification/set_notification",
    }),
    close_dialog() {
      this.$emit("close_reset_modal");
    },
    async change_password() {
      this.progress_dialog = true;
      await axios
        .put("/change_password/" + this.item.id + "/" + this.form.password)
        .then((res) => {
          console.log(res);
          alert("Successfully changed password");
        })
        .catch((err) => {
          alert(err.message);
        });
      this.progress_dialog = false;
      this.$emit("close_reset_modal");
    },
  },
};
</script>

<style></style>
