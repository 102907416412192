import axios from "axios";

export default {
  namespaced: true,
  state: {
    notification: {
      message: "",
      color: "",
      icon: "",
      showing: false,
    },
    confirm_notification: {
      item: [],
      message:
        "lorem ipsum dolor sit amet, consectetur lorem ipsum dolor sit amet, consectetur?",
      icon: "mdi-help-circle",
      is_confirmed: false,
      type: "warning",
    },
    notifications: [],
    alert: false,
    search: false,
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    CONFIRM(state) {
      state.confirm_notification.is_confirmed =
        !state.confirm_notification.is_confirmed;
      console.log(state.confirm_notification.is_confirmed);
    },
    SET_CONFIRM_NOTIFICATION(state, confirm_notification) {
      state.confirm_notification = confirm_notification;
    },
    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
      setTimeout(() => {
        state.notification = {
          message: "",
          color: "",
          icon: "",
          showing: false,
        };
      }, 5000);
    },
    SET_ALERT(state, alert) {
      state.alert = alert;
    },
    SET_SEARCH(state, search) {
      state.search = search;
    },
    CLOSE_ALERT(state, alert) {
      state.alert = alert;
    },
    ADD_NOTIFICATION(state, notification) {
      state.notifications.unshift(notification);
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
    },
    DELETE_NOTIFICATION(state, notification) {
      let filtered = state.notifications.filter((m) => m.id != notification.id);
      state.notifications = filtered;
    },
  },
  actions: {
    confirm({ commit }) {
      commit("CONFIRM");
    },
    set_confirm_notification({ commit }, confirm_notification) {
      commit("SET_CONFIRM_NOTIFICATION", confirm_notification);
    },
    set_notification({ commit }, notification) {
      commit("SET_NOTIFICATION", notification);
    },
    set_alert({ commit }, alert) {
      commit("SET_ALERT", alert);
    },
    set_search({ commit }, alert) {
      commit("SET_SEARCH", alert);
    },
    close_alert({ commit }, alert) {
      commit("CLOSE_ALERT", alert);
    },
    async add_notification({ commit }, notification) {
      console.log(notification);
      let response = await axios.post("/notification", notification);
      commit("ADD_NOTIFICATION", response.data);
    },
    async get_notifications({ commit }) {
      let response = await axios.get("/notifications");
      commit("SET_NOTIFICATIONS", response.data);
    },
    async read_notification({ commit }, id) {
      let response = await axios.put("/notification/" + id);
      commit("DELETE_NOTIFICATION", response.data);
    },
    set_notifications({ commit }, item) {
      commit("SET_NOTIFICATIONS", item);
    },
  },
};
