<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <div>
          <v-card class="mt-2 pa-4 mb-10">
            <v-row class="pt-4">
              <v-col cols="2">
                <v-select
                  prepend-icon="mdi-filter"
                  dense
                  v-model="form.year"
                  label="Year"
                  :items="yearly_items"
                  @change="check_if_yearly"
                ></v-select>
              </v-col>
              <v-col cols="2" v-if="param == 'quarterly'">
                <v-select
                  :disabled="this.form.year == null"
                  dense
                  v-model="form.quarter"
                  label="Quarter"
                  :items="quarterly_items"
                  @change="get_report"
                ></v-select>
              </v-col>
              <v-col cols="2" v-if="param == 'semestral'">
                <v-select
                  :disabled="this.form.year == null"
                  dense
                  v-model="form.semester"
                  label="Semester"
                  :items="semestral_items"
                  @change="get_report"
                ></v-select>
              </v-col>
              <v-col cols="2" v-if="param == 'monthly'">
                <v-select
                  :disabled="this.form.year == null"
                  dense
                  v-model="form.month"
                  label="Month"
                  :items="monthly_items"
                  item-value="id"
                  @change="get_report"
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.month }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.month }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="1">
                <v-btn
                  :disabled="export_btn_status"
                  class="white--text rounded-xl"
                  small
                  left
                  color="deep-purple accent-4"
                  @click="export_report()"
                >
                  export
                </v-btn>
              </v-col>
            </v-row>
            <v-simple-table v-if="report" dense class="mb-10">
              <template v-slot:default>
                <thead>
                  <tr class="">
                    <th class="text-center white--text">{{ form.year }}</th>
                    <th class="text-center white--text" colspan="3">
                      Nature of Disputes(2a)
                    </th>
                    <th class="text-center white--text">Total(2a.4)</th>
                    <th class="text-center white--text" colspan="3">
                      Settled Cases(2b)
                    </th>
                    <th class="text-center white--text">Total(2b.4)</th>
                    <th class="text-center white--text" colspan="6">
                      Unsettled Cases(2c)
                    </th>
                    <th class="text-center white--text">
                      ESTIMATED GOVERNMENT'S SAVINGS (In Php)
                    </th>
                  </tr>
                  <tr>
                    <th class="text-center white--text">Date</th>
                    <th class="text-center white--text">Criminal(2a.1)</th>
                    <th class="text-center white--text">Civil(2a.2)</th>
                    <th class="text-center white--text">Others(2a.3)</th>
                    <th></th>
                    <th class="text-center white--text">Mediation(2b.1)</th>
                    <th class="text-center white--text">Conciliation(2b.2)</th>
                    <th class="text-center white--text">Arbitration(2b.3)</th>
                    <th></th>
                    <th class="text-center white--text">
                      Repudiated Cases(2c.1)
                    </th>
                    <th class="text-center white--text">
                      Withdrawn Cases(2c.2)
                    </th>
                    <th class="text-center white--text">Pending Cases(2c.3)</th>
                    <th class="text-center white--text">
                      Dismissed Cases(2c.4)
                    </th>
                    <th class="text-center white--text">
                      Certified Cases(2c.5)
                    </th>
                    <th class="text-center white--text">
                      Referred to Concerned Agency
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in report" :key="i">
                    <td
                      :class="[
                        i + 1 == report.length ? 'font-weight-bold' : '',
                      ]"
                      v-for="(val, x) in item"
                      :key="x"
                    >
                      <span
                        :class="[
                          x == 4 || x == 8 || x == 15 ? 'font-weight-bold' : '',
                        ]"
                      >
                        {{ format(val, x) }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table v-else dense class="mb-10">
              <template v-slot:default>
                <thead>
                  <tr class="">
                    <th></th>
                    <th class="text-center white--text" colspan="3">
                      Nature of Disputes(2a)
                    </th>
                    <th class="text-center white--text">Total(2a.4)</th>
                    <th class="text-center white--text" colspan="3">
                      Settled Cases(2b)
                    </th>
                    <th class="text-center white--text">Total(2b.4)</th>
                    <th class="text-center white--text" colspan="6">
                      Unsettled Cases(2c)
                    </th>
                    <th class="text-center white--text">
                      ESTIMATED GOVERNMENT'S SAVINGS (In Php)
                    </th>
                  </tr>
                  <tr>
                    <th class="text-center white--text">Date</th>
                    <th class="text-center white--text">Criminal(2a.1)</th>
                    <th class="text-center white--text">Civil(2a.2)</th>
                    <th class="text-center white--text">Others(2a.3)</th>
                    <th></th>
                    <th class="text-center white--text">Mediation(2b.1)</th>
                    <th class="text-center white--text">Conciliation(2b.2)</th>
                    <th class="text-center white--text">Arbitration(2b.3)</th>
                    <th></th>
                    <th class="text-center white--text">
                      Repudiated Cases(2c.1)
                    </th>
                    <th class="text-center white--text">
                      Withdrawn Cases(2c.2)
                    </th>
                    <th class="text-center white--text">Pending Cases(2c.3)</th>
                    <th class="text-center white--text">
                      Dismissed Cases(2c.4)
                    </th>
                    <th class="text-center white--text">
                      Certified Cases(2c.5)
                    </th>
                    <th class="text-center white--text">
                      Referred to Concerned Agency
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" v-for="item in arr" :key="item">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr class="font-weight-bold">
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "../ProgressLinear.vue";
import moment from "moment";
export default {
  props: ["param"],
  components: {
    ProgressLinear,
  },
  data() {
    return {
      emphasize_value: "",
      progress_dialog: false,
      export_btn_status: true,
      arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      form: {
        months: [],
        month: "",
        quarter: "",
        semester: "",
        year: null,
      },
      quarterly_items: [
        "First Quarter",
        "Second Quarter",
        "Third Quarter",
        "Fourth Quarter",
      ],
      semestral_items: ["First Semester", "Second Semester"],
      monthly_items: [
        {
          id: 1,
          month: "January",
        },
        {
          id: 2,
          month: "February",
        },
        {
          id: 3,
          month: "March",
        },
        {
          id: 4,
          month: "April",
        },
        {
          id: 5,
          month: "May",
        },
        {
          id: 6,
          month: "June",
        },
        {
          id: 7,
          month: "July",
        },
        {
          id: 8,
          month: "August",
        },
        {
          id: 9,
          month: "September",
        },
        {
          id: 10,
          month: "October",
        },
        {
          id: 11,
          month: "November",
        },
        {
          id: 12,
          month: "December",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      default_url2: "auth/default_url2",
      report: "statistics/report",
      complaints: "complaint/complaints",
      user: "auth/user",
      default_server_url: "auth/default_server_url",
    }),
    yearly_items() {
      let arr = [];
      for (let i = 2022; i < 2100; i++) {
        arr.push(i);
      }
      return arr;
    },

    date_today() {
      var date = new Date();
      date = moment(date).format("YYYY-MM-DD");
      return date;
    },
  },
  methods: {
    ...mapActions({
      get_report_data: "statistics/get_report_data",
      set_alert: "notification/set_alert",
      set_notification: "notification/set_notification",
      get_complaints: "complaint/get_complaints",

      get_lupons: "lupon/get_lupons",
      get_notifications: "notification/get_notifications",
      get_search_items: "complaint/get_search_items",
      get_dashboard_summary: "complaint/get_dashboard_summary",
      get_backups: "backup/get_backups",
    }),
    //FORMAT THE VALUE OF SAVINGS COLUMN
    format(val, x) {
      if (x == 15) return this.separator(val.toFixed(2));
      else return val;
    },
    export_report() {
      // console.log(this.param);
      let report_type =
        this.param == "yearly"
          ? "Annual Report"
          : this.param == "semestral"
          ? "Semestral Report"
          : this.param == "quarterly"
          ? "Quarterly Report"
          : "Monthly Report";

      window.open(
        this.default_url2 +
          this.form.year +
          "/" +
          JSON.stringify(this.report) +
          "/" +
          this.user.id +
          "/" +
          report_type
      );
    },
    //IF YEARLY - CALL GET REPORT FUNCTION
    check_if_yearly() {
      if (this.param == "yearly") this.get_report();
      else return;
    },
    async get_report() {
      // add user id to form
      this.form.user_id = this.user.id;
      this.progress_dialog = true;
      //CHECK IF MONTHLY/QUARTERLY/SEMESTRAL/YEARLY
      switch (this.param) {
        case "monthly":
          this.form.months = [this.form.month];
          break;
        case "quarterly":
          switch (this.form.quarter) {
            //IF QUARTERLY, ASSIGN ARRAY VALUES FOR MONTHS TO FILTER DATA FROM COMPLAINTS DATABASE
            case "First Quarter":
              this.form.months = [1, 2, 3];
              break;
            case "Second Quarter":
              this.form.months = [4, 5, 6];
              break;
            case "Third Quarter":
              this.form.months = [7, 8, 9];
              break;
            case "Fourth Quarter":
              this.form.months = [10, 11, 12];
              break;
            default:
              break;
          }
          break;
        case "semestral":
          //IF SEMESTRAL, ASSIGN ARRAY VALUE FOR MONTHS TO FILTER DATA FROM COMPLAINTS DATABASE
          switch (this.form.semester) {
            case "First Semester":
              this.form.months = [1, 2, 3, 4, 5, 6];
              break;
            case "Second Semester":
              this.form.months = [7, 8, 9, 10, 11, 12];
              break;
            default:
              break;
          }
          break;
        case "yearly":
          //IF YEARLY ASSIGN ARRAY VALUE OF MONTHS FOR THE WHOLE YEAR
          this.form.months = this.arr;
          break;
        default:
          break;
      }
      console.log(this.form.months);
      console.log(this.form.month);
      await this.get_report_data(this.form);
      this.export_btn_status = false;
      this.progress_dialog = false;
    },
    //ADD COMMA AND DECIMAL POINT TO THE SAVINGS VALUE
    separator(saving) {
      var str = saving.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    },
  },
};
</script>
<style scoped>
table,
thead,
th {
  border: 1px solid lightgrey;
  border-collapse: collapse;
  background-color: #757575;
}
.center-text {
  text-align: center;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
td {
  text-align: center;
}
</style>
