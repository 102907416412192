<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Certificate to File Action</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text">
            Please enter required information
          </h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <v-text-field
              v-if="table_from == 'arbitration'"
              dense
              v-model="form.repudiator"
              label="Repudiator"
            ></v-text-field>
            <v-text-field
              v-if="table_from == 'arbitration'"
              dense
              v-model="form.grounds"
              label="Grounds"
            ></v-text-field>
            <v-text-field
              dense
              v-model="form.secretary"
              label="Lupon Secretary"
            ></v-text-field>
            <v-text-field
              dense
              v-model="form.chairman"
              label="Pangkat Chairman"
            ></v-text-field>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
export default {
  data() {
    return {
      json_dialog: false,
      date_settled_dialog: false,
      date_printed_dialog: false,
      form: {
        repudiator: "",
        grounds: "",
        secretary: "",
        chairman: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
    table_from() {
      let route_length = JSON.parse(this.complaint.table_route_history).length;
      let from = JSON.parse(this.complaint.table_route_history)[
        route_length - 2
      ].toLowerCase();
      return from;
    },
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),

    input_other_data() {
      this.json_dialog = true;
    },
    print() {
      let other_data = {
        repudiator: this.form.repudiator,
        grounds: this.form.grounds,
        secretary: this.form.secretary,
        chairman: this.form.chairman,
      };
      // let route_length = JSON.parse(this.complaint.table_route_history).length;
      // let table_from = JSON.parse(this.complaint.table_route_history)[
      //   route_length - 2
      // ].toLowerCase();

      if (this.complaint)
        window.open(
          this.print_url +
            this.user.id +
            "/certificate_to_file_action_" +
            this.table_from +
            "/" +
            this.complaint.id +
            "/" +
            this.language +
            "/" +
            this.complaint.current_table +
            "/" +
            JSON.stringify(other_data)
        );
    },
  },
};
</script>
