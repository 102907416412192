<template>
  <v-container fluid>
    <v-row dense>
      <v-col class="pa-5">
        <span class="deep-purple--text accent-4 font-weight-bold headline">
          CASES FOR MEDIATION
        </span>
      </v-col>
      <v-col class="col-3 grey--text mt-5">
        <v-icon small>mdi-account-multiple</v-icon>
        Total Number of Cases:
        <span
          ><v-chip
            v-if="mediations"
            color="deep-purple accent-4"
            class="white--text ml-2"
            >{{ mediations.length }}</v-chip
          >
        </span></v-col
      >
      <v-col class="col-3 grey--text mt-5">
        <v-icon small>mdi-calendar</v-icon>
        As of:
        <v-chip class="white--text ml-2" color="grey"> {{ date_today }}</v-chip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card class="mb-15">
          <mediation-table />
        </v-card>
      </v-col>
    </v-row>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";

export default {
  components: {
    MediationTable: () =>
      import("../components/MediationManagement/MediationTable.vue"),
    ProgressLinear,
  },
  data() {
    return {
      width: 2,
      lineCap: "round",
      gradient: ["#00c6ff", "#F0F", "#FF0"],
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8],
      gradientDirection: "top",

      fill: false,
      type: "trend",
      autoLineWidth: false,

      padding: 8,
      radius: 10,
      sparkline_values: [
        { text: "January", value: 0 },
        { text: "February", value: 2 },
        { text: "March", value: 8 },
        { text: "April", value: 10 },
        { text: "May", value: 4 },
        { text: "June", value: 20 },
        { text: "July", value: 3 },
        { text: "August", value: 4 },
        { text: "September", value: 2 },
        { text: "October", value: 15 },
        { text: "November", value: 2 },
        { text: "December", value: 1 },
      ],

      gradients: [
        ["#222"],
        ["#42b3f4"],
        ["red", "orange", "yellow"],
        ["purple", "violet"],
        ["#00c6ff", "#F0F", "#FF0"],
        ["#f72047", "#ffd200", "#1feaea"],
      ],

      progress_dialog: false,
    };
  },
  computed: {
    date_today() {
      var date = new Date();
      date = moment(date).format("MMM DD, YYYY");
      return date;
    },
    values() {
      return this.sparkline_values.map((x) => x.value);
    },
    labels() {
      return this.sparkline_values.map((y) => y.text);
    },
    ...mapGetters({
      // mediations: "complaint/mediations",
      complaints: "complaint/complaints",
    }),
    mediations() {
      if (this.complaints == null) return [];
      else {
        let filtered = this.complaints.filter((m) => {
          return m.current_table == "Mediation";
        });
        return filtered;
      }
    },
  },
  methods: {
    ...mapActions({
      get_complaints: "complaint/get_complaints",
      get_lupons: "lupon/get_lupons",
      get_notifications: "notification/get_notifications",
      get_dashboard_summary: "complaint/get_dashboard_summary",
    }),
  },
  async created() {
    if (this.complaints == null) {
      this.progress_dialog = true;
      await this.get_complaints();
      await this.get_lupons();
      await this.get_notifications();
      await this.get_dashboard_summary();
      this.progress_dialog = false;
    }
  },
};
</script>
