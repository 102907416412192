<template>
  <v-container>
    <v-row dense class="mb-10">
      <v-col>
        <v-list>
          <v-list-group v-for="item in items" :key="item.question" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.question"></v-list-item-title>
              </v-list-item-content>
            </template>
            <h4 class="ml-10">Answer:</h4>
            <v-list-item v-for="child in item.answers" :key="child.answer">
              <v-card flat>
                <!-- <v-card-title>Answer</v-card-title> -->
                <v-card-text v-text="child.answer"></v-card-text>
              </v-card>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          question: "What is katarungang pambarangay?",
          answers: [
            {
              answer:
                "The Katarungang Pambarangay is a system for amicable settlement of disputes at the barangay. It is an out-of-court resolution of conflicts by the community members themselves under the guidance of the unong barangay and without intervention of legal counsels and representatives.",
            },
          ],
        },
        {
          question: "Who is the Chairman of the lupon?",
          answers: [
            {
              answer: "The Punong Barangay is the Chairman of the lupon.",
            },
          ],
        },
        {
          question: "How many lupon members are there?",
          answers: [
            {
              answer:
                "According to the Revised KP Law, the lupon is composed of the punong barangay and ten (10) to twenty (20) members. The lupon shall be constituted every three (3) years.",
            },
          ],
        },
        {
          question: "What are the qualifications of a lupon member?",
          answers: [
            {
              answer:
                "The person is a resident or working in the barangay and not disqualified by law for some reason and must possess integrity, impartiality, independence of mind, sense of fairness and reputation of probity may be appointed member of the lupon.",
            },
          ],
        },
        {
          question: "What are the functions of the lupon?",
          answers: [
            {
              answer:
                "1. Exercise administrative supervision over the conciliation panels",
            },
            {
              answer:
                "2. Meet regularly once a month to provide forum for exchange of ideas among its members and the public on matters relevant to the amicable settlement of disputes and to enable various conciliation panel members to share with one another their observations and experiences in effecting speedy resolution of dispute; and",
            },
            {
              answer:
                "3. Exercise such other powers and perform such other duties and functions as may be prescribed by law or ordinance.",
            },
          ],
        },
        {
          question: "What is the Term of Office of the lupon members?",
          answers: [
            {
              answer:
                "Lupon Members shall hold office until a new lupon is constituted on the third year following their appointment, unless the term of any of the lupon members is sooner terminated by resignation, transfer of residence or place of work or withdrawal of appointment by the Punong Barangay with the concurrence of the majority of the members of lupon.",
            },
          ],
        },
        {
          question: "Are lupon members entitled to compensation?",
          answers: [
            {
              answer:
                "They serve without compensation except for certain honoraria, allowances and other emoluments as authorized by law or barangay, municipal or city ordinance.",
            },
          ],
        },
        {
          question: "How much is the filing fee?",
          answers: [
            {
              answer:
                "Minimum of five pesos (P5.00) nor more than twenty pesos (P20.00)",
            },
          ],
        },
        {
          question: "What disputes and offenses covered by KP?",
          answers: [
            {
              answer:
                "Almost all civil disputes and every criminal offense punishable by imprisonment not exceeding one year or a fine not exceeding P5,000.00 are subject to amicable settlement under the KP when parties involved are individuals who are residing in the same city or municipality.",
            },
          ],
        },
        {
          question:
            "What disputes and offenses are not covered by the katarungang pambarangay?",
          answers: [
            {
              answer:
                "1. Where one party is the government or any subdivision or instrumentality thereof",
            },
            {
              answer:
                "2. Where one party is a public officer or employee and the dispute relates to the per",
            },
            {
              answer:
                "3. Offenses punishable by imprisonment exceeding one year or a fine P5,000.00",
            },
            {
              answer:
                "4. Offenses where no private party or individual is offended (like traffic violations, jaywalking, vagrancy, sidewalk vending, mendicancy, and prostitution)",
            },
            {
              answer:
                "5. Where the dispute involves real properties located in different cities or municipalities, unless the parties thereto agree to submit their differences to amicable settlement by an appropriate lupon.",
            },
            {
              answer:
                "6. Disputes involving parties who actually reside in barangays of different cities or municipalities, except where such barangay units adjoin each other and the parties thereto agree to submit their differences to amicable settlement by the appropriate lupon",
            },
            {
              answer:
                "7. Such other classes of disputes which the President of the Philippines may determine in the interest of justice or upon the recommendation of the Secretary of Justice",
            },
          ],
        },
        {
          question: "Can an individual file a case directly in court?",
          answers: [
            {
              answer:
                "No, unless personal confrontation of parties has already taken place and resulted to a failure in the settlement of such case.",
            },
          ],
        },
        {
          question: "Non- conciliable cases brought to the barangay",
          answers: [
            {
              answer:
                "This is a common occurrence, wherein disputes in the neighborhood, civil or criminal, beyond the jurisdiction of the lupon to settle are accepted by the punong barangay and even conciliated by the pangkat. There is no law, issuance or ruling prohibiting or penalizing a punong barangay from accepting complaints and grievances, especially when both contending parties are his constituents.",
            },
          ],
        },
        {
          question:
            "What happens when a case outside the jurisdiction of the KP is settled amicably at the barangay level?",
          answers: [
            {
              answer:
                "Any settlement or arbitration on disputes not covered by the KP does not have the force and effect of a court judgment; they are merely private contracts and cannot be enforced through execution by the lupon or court.",
            },
          ],
        },
        {
          question: "What is mediation?",
          answers: [
            {
              answer:
                "Mediation of the first process of settling a dispute under the KP. It is performed by the punong barangay as lupon chairman who is given authority to summon the disputants for a face to face meeting before him for the purpose of expeditiously and amicably settling their differences.",
            },
          ],
        },
        {
          question: "What is conciliation?",
          answers: [
            {
              answer:
                "Conciliation is the extension of the amicable settlement in the case the mediatory effort of the punong baranagy did not result to reconciliation within the 15 days from the start of the proceedings.",
            },
          ],
        },
        {
          question: "What is arbitration?",
          answers: [
            {
              answer:
                "Arbitration is the alternative way of settling disputes under the KP whereby contesting parties agree in writing on the decision or judgment of the punong barangay or the pangkat.",
            },
          ],
        },
        {
          question: "Lupong Tagapamayapa Source",
          answers: [
            {
              answer:
                "These FAQs are sourced from the book titled, “COMPREHENSIVE GUIDEBOOK ON KATARUNGANG PAMBARANGAY,” authored by Juanito S. Ortiz. For other information on Katarungang Pambarangay, contact the author thru telephone no. 3846865 or 09209011634.",
            },
          ],
        },
      ],
    };
  },
};
</script>
