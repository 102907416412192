import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    language: "English",
    token: null,
    user: null,
    subscription: null,
    check_account: null,
    default_prescribed_url:
      "https://accessserver.kpcms.online/print_prescribed_form/",
    default_url: "https://accessserver.kpcms.online/print/",
    default_url2: "https://accessserver.kpcms.online/export_report/",
    default_url3: "https://accessserver.kpcms.online/print_prescribed_form/",

    // default_prescribed_url: "http://192.168.68.108:8000/print_prescribed_form/",
    // default_url: "http://192.168.68.108:8000/print/",
    // default_url2: "http://192.168.68.108:8000/export_report/",
    // default_url3: "http://192.168.68.108:8000/print_prescribed_form/",

    // default_prescribed_url: "http://127.0.0.1:8000/print_prescribed_form/",
    // default_url: "http://127.0.0.1:8000/print/",
    // default_url2: "http://127.0.0.1:8000/export_report/",
    // default_url3: "http://127.0.0.1:8000/print_prescribed_form/",
  },
  // read info from state
  getters: {
    language(state) {
      return state.language;
    },
    authenticated(state) {
      return state.token && state.user;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    subscription(state) {
      return state.subscription;
    },
    check_account(state) {
      return state.check_account;
    },
    default_prescribed_url(state) {
      return state.default_prescribed_url;
    },
    default_url(state) {
      return state.default_url;
    },
    default_url2(state) {
      return state.default_url2;
    },
    default_url3(state) {
      return state.default_url3;
    },
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      state.language = language;
    },
    // update the state
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    EDIT(state, data) {
      console.log(state.user);
      console.log(data);
    },
    SET_SUBSCRIPTION(state, data) {
      /*CODE STATUS LEGEND
       0 - Open for Activation
       1 - Active
       2 - Deactivated
       3 - Trial
         * */
      state.subscription = data;
    },
    SET_CHECK_ACCOUNT(state, data) {
      state.check_account = data;
    },
    EDIT_CAPTAIN(_, captain) {
      console.log(captain);
    },
  },
  actions: {
    async set_language({ commit }, language) {
      commit("SET_LANGUAGE", language);
    },

    async update_barangay_info({ commit }, request) {
      let response = await axios.post("/update_barangay_info", request);
      commit("SET_USER", response.data);
    },

    //reset password
    async check_account_exist({ commit }, request) {
      let response = await axios.post("/check_account_exist", request);
      commit("SET_CHECK_ACCOUNT", response.data);
      return response.data;
    },
    async new_password({ commit }, request) {
      let response = await axios.put("/check_account_exist", request);
      commit("SET_CHECK_ACCOUNT", response.data);
    },
    //new user
    async register({ commit }, request) {
      let response = await axios.post("/new_user", request);
      console.log(response.data);
      commit("SET_USER", null);
    },
    //upload seal
    async upload_seal({ commit }, request) {
      let response = await axios.post("/upload_seal", request);
      console.log(response.data);
      commit("SET_USER", response.data);
    },
    //add credit
    async add_credit({ commit }, request) {
      let response = await axios.put("/add_credit/" + request.captain_id, {
        request,
      });
      console.log(response.data);
      commit("SET_USER", response.data);
    },
    async add_cases_handled({ commit }, id) {
      let response = await axios.put("/add_cases_handled/" + id);
      console.log(response.data);
      commit("EDIT_CAPTAIN", response.data);
    },
    // make request to api but commit to mutations
    async signin({ dispatch }, credentials) {
      let response = await axios.post("auth/signin", credentials);
      return dispatch("attempt", response.data);
    },
    // attempt to access me api to check if token is valid
    // and give us back the users info
    async attempt({ commit, state }, token) {
      // if there is a token, commit
      if (token) {
        commit("SET_TOKEN", token);
      }
      // if there is no token in the sate, stop the execution
      if (!state.token) {
        return;
      }
      // check token if it is valid in me api
      try {
        // pass header for authorization
        let response = await axios.get("auth/attempt");
        // if it is true, set_user to response
        commit("SET_USER", response.data);
        // get services
        // this.$store.dispatch('services/get', response.data.branch_id, { root: true });
      } catch (e) {
        // if errors occurs set state to null
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    signout({ commit }) {
      return axios.post("auth/signout").then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      });
    },
    async on_page_reload({ dispatch, rootState }) {
      // if page gets refreshed
      if (!rootState.contract.contracts_consolidation) {
        await dispatch("contract/get_contracts_consolidation", "", {
          root: true,
        });
      }
      if (!rootState.contract.contracts) {
        await dispatch("contract/get_contracts", "", { root: true });
      }
      if (!rootState.agent.agents) {
        await dispatch("agent/get_agents", "", { root: true });
      }
      if (!rootState.branch.branches) {
        await dispatch("branch/get_branches", "", { root: true });
      }

      return true;
    },
    async check_if_user_exists() {
      let response = await axios.get("/check_if_user_exists");
      return response.data;
    },

    // SUBSCIRPTION
    async check_subscription({ commit }) {
      let response = await axios.get("code/check_subscription");
      // console.log(response.data);
      commit("SET_SUBSCRIPTION", response.data);
    },
    async activate_code(_, request) {
      // console.log(request);
      let response = await axios.put("code/activate/" + request.request_code);
      // activated trial
      return response.data;
    },
    async generate_codes(_, request) {
      let response = await axios.get(
        "code/generate_code/" +
          request.city_or_municipality +
          "/" +
          request.barangay
      );
      if (response.data == "already have codes") {
        // alert(response.data);
        return;
      } else {
        console.log(response.data);
      }
    },
  },
};
