qq
<template>
  <div>
    <v-img src="@/assets/kp_back2.jpg" class="mt-n7">
      <v-container class="mt-16">
        <v-container class="fill-height mt-16" fluid>
          <v-row align="center" justify="center" class="mt-2">
            <v-col cols="15" sm="8" md="8">
              <v-card class="elevation-12 rounded-l" max-width="">
                <v-row>
                  <v-col class="deep-purple accent-4 white--text" cols="6">
                    <br />
                    <v-container class="mt-16 mb-15">
                      <div class="d-flex justify-center">
                        <v-icon color="white" size="75">
                          mdi-scale-balance
                        </v-icon>
                      </div>
                      <div class="d-flex justify-center">
                        <h2>KP-CMS</h2>
                      </div>
                      <div class="d-flex justify-center">
                        <span class="heading-5">KATARUNGANG PAMBARANGAY</span>
                      </div>
                      <div class="d-flex justify-center">
                        <span class="heading-5">CASE MANAGEMENT SYSTEM</span>
                      </div>
                      <div class="d-flex justify-center">
                        <span class="caption">{{ version }}</span>
                      </div>
                      <div class="d-flex justify-center">
                        <span class="caption">
                          <v-icon color="white" small>mdi-copyright</v-icon>
                          {{ date.year() }} All Rights Reserved
                        </span>
                      </div>
                    </v-container>
                  </v-col>
                  <v-col cols="6">
                    <br /><br />
                    <v-row>
                      <v-col cols="6">
                        <div class="ml-15">
                          <v-btn
                            text
                            :color="register_selected"
                            @click="open_register"
                          >
                            REGISTER
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="d-flex justify-left">
                          <v-btn
                            text
                            :color="login_selected"
                            @click="open_login"
                          >
                            LOGIN
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- LOGIN TAB -->
                    <div class="mt-10 mr-11 ml-5 text-center" v-if="login_tab">
                      <v-form ref="form">
                        <v-text-field
                          :rules="[rules.required]"
                          outlined
                          prepend-icon="mdi-account-outline"
                          label="Username"
                          placeholder="Enter your username"
                          dense
                          v-model="form.username"
                        >
                        </v-text-field>
                        <v-text-field
                          :rules="[rules.required]"
                          type="password"
                          dense
                          prepend-icon="mdi-lock-outline"
                          label="Password"
                          placeholder="Enter your password"
                          outlined
                          v-model="form.password"
                          @keyup.enter="submit()"
                        ></v-text-field>
                      </v-form>
                      <v-btn
                        color="primary darken-4"
                        class="mr-2"
                        @click="submit()"
                        :loading="loading"
                        >Login</v-btn
                      >
                      <v-btn color="yellow" @click="reset">Reset</v-btn>
                      <password-recovery-modal />
                      <v-divider class="mt-"></v-divider>
                      <p class="caption grey--text text--lighten-1 mt-5">
                        Powered by: JMBComputers IT Solutions | {{ version }}.
                      </p>
                    </div>
                    <registration
                      v-if="register_tab"
                      @open_login="open_login()"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <activation-modal :dialog="activation_dialog" />
    </v-img>
  </div>
</template>

<script>
import packageJson from "../../package.json";

import { mapActions, mapGetters } from "vuex";
import Registration from "../components/Registration.vue";
import moment from "moment";
import PasswordRecoveryModal from "../components/PasswordRecovery/PasswordRecoveryModal.vue";
import ActivationModal from "../components/Alerts/activationModal.vue";
// import RichText from "../components/RichText.vue";

export default {
  components: {
    Registration,
    PasswordRecoveryModal,
    ActivationModal,
    // RichText,
  },

  data() {
    return {
      activation_dialog: false,
      version: packageJson.version,
      registration: null,
      date: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
      form: {
        username: "",
        password: "",
      },
      login_tab: true,
      register_tab: false,
      login_selected: "deep-purple accent-4",
      register_selected: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      subscription: "auth/subscription",
    }),
  },
  methods: {
    ...mapActions({
      signin: "auth/signin",
      set_notification: "notification/set_notification",
      check_subscription: "auth/check_subscription",
      check_if_user_exists: "auth/check_if_user_exists",
    }),
    async check_activator() {
      await this.check_subscription();
    },
    open_register() {
      this.register_tab = true;
      this.login_tab = false;
    },
    open_login() {
      this.register_tab = false;
      this.login_tab = true;
      this.register_selected = "";
      this.login_selected = "deep-purple accent-4";
    },
    reset() {
      (this.form.username = ""), (this.form.password = "");
    },
    submit() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        this.signin(this.form)
          .then(() => {
            this.loading = false;
            this.$router.push("/");
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.set_notification({
                message: "Username or Password Incorrect. Please try again.",
                color: "red",
                showing: true,
                icon: "mdi-alert",
              });
            } else {
              this.activation_dialog = true;
              // this.set_notification({
              //   message:
              //     "Something went wrong. Please contact the administrator",
              //   color: "red",
              //   showing: true,
              //   icon: "mdi-alert",
              // });
            }
            this.loading = false;
          });
      } else {
        this.set_notification({
          message: "All fields are required!",
          color: "red",
          showing: true,
          icon: "mdi-alert",
        });
        this.loading = false;
      }
    },
  },
  async created() {
    var date = moment();
    this.date = date;
  },
};
</script>

<style></style>
