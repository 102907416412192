<template>
  <v-dialog v-model="dialog" width="230" persistent>
    <v-card class="pa-5 d-flext justify-center text-center" flat elevation="1">
      <v-progress-circular
        class="mt-1"
        indeterminate
        color="deep-purple accent-4"
        rounded
        height="4"
        size="50"
      ></v-progress-circular>
      <p class="mt-3 mb-n2 deep-purple--text accent-4 overline">
        Loading please wait...
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog"],
  data: () => ({
    // dialog_true: true,
  }),
};
</script>

<style></style>
