<template>
  <div v-if="lupons">
    <v-card flat class="mb-10">
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="type to search"
            ></v-text-field
          ></v-col>
          <v-col class="d-flex justify-end" cols="2" offset="7">
            <add-lupon-modal />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-data-table
          :search="search"
          :items="lupons"
          :headers="headers"
          fixed-header
        >
          <template v-slot:[`item.is_captain`]="{ item }">
            <span v-if="item.is_captain">Punong Barangay</span>
            <span v-else>Lupon</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-row>
              <v-col cols="6">
                <edit-lupon-modal :item="item" />
              </v-col>
              <v-col cols="6">
                <v-icon color="red" @click="open_alert(item)"
                  >mdi-delete</v-icon
                >
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        color="red"
        :message="this.alert_message"
        @confirm-event="destroy"
        @cancel-event="close_confirm"
      />
    </div>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
import EditLuponModal from "./EditLuponModal.vue";
import AddLuponModal from "./AddLuponModal.vue";

export default {
  components: {
    ProgressLinear,
    EditLuponModal,
    AddLuponModal,
    ConfirmNotification: () => import("@/components/ConfirmNotification.vue"),
  },
  data() {
    return {
      delete_item: null,
      show_alert: false,
      alert_message: "Are you sure you want to delete this lupon member?",
      search: "",
      progress_dialog: false,
      headers: [
        { text: "Name", value: "full_name" },
        { text: "Gender", value: "gender" },
        {
          text: "Civil Status",
          value: "civil_status",
        },
        {
          text: "Permanent Address",
          value: "permanent_address",
        },
        {
          text: "Highest Educational Attainment",
          value: "highest_educational_attainment",
        },
        {
          text: "Term of Office",
          value: "term_of_office",
        },
        {
          text: "No. of Cases Handled",
          value: "handled.length",
        },
        {
          text: "No. of Cases Settled",
          value: "settled.length",
        },
        {
          text: "No. of Cases Unsettled",
          value: "unsettled.length",
        },
        {
          text: "Position",
          value: "is_captain",
        },
        {
          text: "Actions",
          value: "action",
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      lupons: "lupon/lupons",
      complaints: "complaint/complaints",
    }),
  },

  methods: {
    ...mapActions({
      get_lupons: "lupon/get_lupons",
      delete_lupon: "lupon/delete_lupon",
      set_notification: "notification/set_notification",
    }),
    open_alert(item) {
      this.delete_item = item;
      this.show_alert = true;
    },
    close_confirm() {
      this.show_alert = false;
    },
    async destroy() {
      this.progress_dialog = true;
      await this.delete_lupon(this.delete_item.id);
      this.progress_dialog = false;
      this.show_alert = false;
      this.set_notification({
        message: "Lupon successfully deleted!",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
    },
    show(item) {
      console.log(item);
    },
  },
  created() {},
};
</script>
<style scoped>
.v-data-table ::v-deep td {
  font-size: 12px !important;
}
</style>
