import bell_notifications from "../json/bell_notifications.json";
export default {
    namespaced: true,
    state: {
        bell_notifications:bell_notifications
    },
    getters: {

    },
    mutations: {
  
    },
    actions: {
    async get_todays_notifications(){
        
     }
    },
  };
  