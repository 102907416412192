<template>
  <v-footer padless height="50" class="" fixed>
    <v-sheet outlined color="deep-purple lighten-5" elevation="5" width="1920">
      <v-row class="d-flex justify-left">
        <v-col cols="2"></v-col>

        <v-col class="mt-2 mr-8"></v-col>
        <v-spacer> </v-spacer>
        <v-col cols="ml-4" class="mr-10">
          <v-card-text class="deep-purple--text darken-4">
            <span class="font-weight-bold">KP-CMS | {{ version }}</span
            ><span class="font-weight-light">
              <v-icon class="ml-1" color="deep-purple" small
                >mdi-copyright</v-icon
              >
              All rights reserved</span
            >
            {{ new Date().getFullYear() }}
          </v-card-text></v-col
        >
        <backup />
      </v-row>
    </v-sheet>
  </v-footer>
</template>
<script>
import Backup from "./Backup.vue";
import packageJson from "../../package.json";
export default {
  components: {
    Backup,
  },
  data: () => ({
    version: packageJson.version,
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style></style>
