<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-sheet class="d-flex justify-center">
          <h3 class="text-center pa-6 mt-2">
            <v-icon>mdi-alert</v-icon>
            Sorry, the case you have filed is not within the jurisdiction of the
            Katarungang Pambarangay. You may file the said case before the
            following:
          </h3>
        </v-sheet>

        <v-card-text>
          <v-radio-group v-model="agency" class="pl-4">
            <v-radio
              label="National Government Agency concerned"
              value="National Government Agency concerned"
            ></v-radio>
            <v-radio
              label="Court of competent jurisdiction"
              value="Court of competent jurisdiction"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions class="mt-n5">
          <v-spacer></v-spacer>
          <v-btn
            color="deep-purple accent-4"
            class="rounded-xl mb-2"
            dark
            @click="submit_to_concerned_agency"
          >
            submit
          </v-btn>
          <v-btn color="" class="rounded-xl mb-2" @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "../ProgressLinear.vue";
export default {
  components: {
    ProgressLinear,
  },
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      agency: null,
      progress_dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      pre_file_complaint: "complaint/pre_file_complaint",
    }),
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      referred_to_concerned_agency: "complaint/referred_to_concerned_agency",
    }),
    async submit_to_concerned_agency() {
      // if (!this.agency) return;
      if (!this.agency) {
        this.set_notification({
          message: "Please choose concerned agency",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return;
      } else {
        this.progress_dialog = true;

        await this.referred_to_concerned_agency(this.agency);
        this.progress_dialog = false;
        this.set_notification({
          message: "Successful referred to concerned agency",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.$router.go(-1);
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
