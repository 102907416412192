<template>
  <div class="pt-2 pb-2">
    <v-btn text color="deep-purple accent-4" x-small @click="dialog = true"
      >Forgot your Password?</v-btn
    >
    <v-dialog v-model="dialog" width="400">
      <v-sheet color="deep-purple accent-4" class="pa-4"
        ><span class="font-weight-bold white--text"
          >Forgot Password</span
        ></v-sheet
      >
      <v-card>
        <v-card-text class="pa-4">
          <v-text-field
            prepend-inner-icon="mdi-account-outline"
            placeholder="Username"
            outlined
            dense
            v-model="form.user_name"
          ></v-text-field>
          <v-select
            prepend-inner-icon="mdi-chat-question"
            placeholder="Secret Question"
            outlined
            dense
            :items="secret_questions"
            v-model="form.secret_question"
          ></v-select>
          <v-text-field
            type="text"
            prepend-inner-icon="mdi-pencil"
            placeholder="Secret Answer"
            outlined
            dense
            v-model="form.secret_answer"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="d-flex justify-end pr-4 pl-4 pb-4 mt-n4">
          <v-btn
            class="rounded-xl mr-1 white--text"
            color="deep-purple accent-4"
            small
            @click="submit"
            >Submit</v-btn
          >
          <v-btn class="rounded-xl" color="yellow" @click="dialog = false" small
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <password-reset-modal
      @close_reset_modal="close"
      :dialog="reset_dialog"
      :item="selected_item"
    />
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
import PasswordResetModal from "./PasswordResetModal.vue";
export default {
  components: {
    ProgressLinear,
    PasswordResetModal,
  },
  data: () => ({
    selected_item: "",
    reset_dialog: false,
    dialog: false,
    progress_dialog: false,
    secret_questions: [
      "What is your mother's maiden name?",
      "What is the name of your first pet?",
      "What is the name of the school you first enrolled?",
      "What is your nick name?",
    ],
    form: {
      user_name: "",
      secret_question: "",
      secret_answer: "",
    },
  }),
  computed: {
    ...mapGetters({
      check_account: "auth/check_account",
    }),
  },
  methods: {
    ...mapActions({
      check_account_exist: "auth/check_account_exist",
      set_notification: "notification/set_notification",
    }),
    close() {
      this.reset_dialog = false;
    },
    submit() {
      // this.progress_dialog = true;
      // console.log(this.form);
      this.check_account_exist(this.form).then((response) => {
        // console.log(response);
        if (response) {
          this.selected_item = response;
          this.dialog = false;
          this.reset_dialog = true;
        } else alert("No accounts found");
      });
      // if (this.check_account == "yes") {
      //   this.dialog = false;
      //   this.reset_dialog = true;
      // } else {
      //   this.set_notification({
      //     message: "Can't find your account",
      //     color: "red",
      //     showing: true,
      //     icon: "mdi-close",
      //   });
      // }
      // this.progress_dialog = false;
    },
  },
};
</script>

<style></style>
