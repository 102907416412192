<template>
  <v-dialog v-model="dialog" max-width="440" class="mt-n16" persistent>
    <!-- ERROR RESPONSE -->
    <div v-if="message == 'Error: You have entered an invalid code!'">
      <v-sheet class="pa-5" color="red accent-3 darken-4 white--text">
        <h1 class="subtitle-1 font-weight-bold text-center">
          <v-icon class="mr-2 white--text">mdi-alert-circle-outline</v-icon>
          {{ message }}
          <br />
          <span class="caption">Make sure you enter the right code.</span>
        </h1>
        <div class="d-flex justify-center">
          <v-btn class="rounded-xl mt-3" small @click="close">Close</v-btn>
        </div>
      </v-sheet>
    </div>
    <!-- FREE TRIAL EXPIRED -->
    <div
      v-else-if="
        message ==
        'Sorry, your free trial has expired. Please register your software or contact the administrator.'
      "
    >
      <v-sheet class="pa-5" color="red accent-3 darken-4 white--text">
        <h1 class="subtitle-1 font-weight-bold text-center">
          <v-icon class="mr-2 white--text">mdi-alert-circle-outline</v-icon>
          {{ message }}
          <br />
        </h1>
        <div class="d-flex justify-center">
          <v-btn class="rounded-xl mt-3" small @click="close">Close</v-btn>
        </div>
      </v-sheet>
    </div>
    <!-- OTHER MESSAGE -->
    <div v-else-if="message == 'Please enter the required information first.'">
      <v-sheet class="pa-5" color="green accent-4 darken-4 white--text">
        <h1 class="subtitle-1 font-weight-bold text-center">
          <v-icon class="mr-2 white--text">mdi-alert-circle-outline</v-icon>
          {{ message }}
        </h1>
        <div class="d-flex justify-center">
          <v-btn class="rounded-xl mt-3" small @click="proceed">Proceed</v-btn>
        </div>
      </v-sheet>
    </div>
    <!-- SUCCESS -->
    <div v-else>
      <v-sheet class="pa-5" color="green accent-4 darken-4 white--text">
        <h1 class="subtitle-1 font-weight-bold text-center">
          <v-icon class="mr-2 white--text"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
          {{ message }}
          <br />
        </h1>
        <div class="d-flex justify-center">
          <v-btn class="rounded-xl mt-3" small @click="refresh">Close</v-btn>
        </div>
      </v-sheet>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: { message: String, dialog: Boolean },
  methods: {
    close() {
      this.$emit("close_code_alert_modal");
    },
    refresh() {
      location.reload();
    },
    proceed() {
      this.$router.push("/userinformation");
    },
  },
};
</script>

<style></style>
