<template>
  <v-container>
    <div v-if="!progress_dialog" class="mb-15">
      <!-- TITLE -->
      <v-row class="mb mt-2">
        <v-col class="">
          <span
            class="font-weight-bold display-1 text-uppercase deep-purple--text"
          >
            Dashboard
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4" class="d-flex justify-center">
          <v-sheet class="mr-2 grey--text pa-3 rounded caption">
            <v-icon class="mr-1" color="grey" small>mdi-calendar</v-icon>
            <span> {{ date_today }}</span>
          </v-sheet>
          <!-- <v-sheet class="caption mr-2 grey--text pa-3 rounded">
            <v-icon small class="mr-1" color="grey">mdi-clock</v-icon>
            <span>{{ time_today }}</span>
          </v-sheet> -->
        </v-col>
      </v-row>

      <!-- <v-divider class="mt-10"></v-divider> -->

      <v-row v-if="dashboard_summary">
        <v-col>
          <div class="d-flex justify-center">
            <total-card
              class="ml-4"
              color="teal accent-4"
              outline_color="amber lighten-5"
              title="Ongoing Cases"
              icon="mdi-file-account"
              :data="dashboard_summary.ongoing_cases"
            />
            <total-card
              color="green accent-4"
              outline_color="green lighten-5"
              title="Settled Cases"
              icon="mdi-handshake"
              :data="dashboard_summary.settled_cases"
            />

            <total-card
              class="ml-4"
              color="amber accent-4"
              outline_color="amber lighten-5"
              title="Unsettled Cases"
              icon="mdi-lightning-bolt"
              :data="dashboard_summary.unsettled_cases"
            />

            <total-card
              class="ml-4"
              color="red"
              outline_color="red lighten-5"
              title="Criminal Cases"
              icon="mdi-handcuffs"
              :data="dashboard_summary.criminal_case"
            />

            <total-card
              class="ml-4"
              color="blue"
              outline_color="blue lighten-5"
              title="Civil Cases"
              icon="mdi-account-multiple"
              :data="dashboard_summary.civil_case"
            />
          </div>
        </v-col>
      </v-row>
      <!-- SECOND ROW CONTENT -->
      <v-row class="mb-">
        <v-col cols="12">
          <linear-scale
            v-if="this.linear_scale_data"
            id_name="Summary of Cases"
          />
        </v-col>
        <v-col></v-col>
        <div></div>
      </v-row>
    </div>
    <progress-linear :dialog="progress_dialog" />
    <the-code-activator-alert-modal
      :dialog="code_activator_alert_modal"
      :message="code_activator_alert_message"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import complainants_respondents from "../json/complainants_respondents.json";
import ProgressLinear from "../components/ProgressLinear.vue";
import TotalCard from "../components/Dashboard/TotalCard.vue";
import LinearScale from "../components/Dashboard/LinearScale.vue";
import moment from "moment";
import TheCodeActivatorAlertModal from "../components/TheCodeActivatorAlertModal.vue";

export default {
  components: {
    ProgressLinear,
    TotalCard,
    LinearScale,
    TheCodeActivatorAlertModal,
  },
  data() {
    return {
      complainants_respondents_json: complainants_respondents,
      code_activator_alert_modal: null,
      code_activator_alert_message:
        "Please enter the required information first.",
      notifications: new Array(),
      date_today: moment(new Date()).format("MMMM DD, YYYY (dddd)"),
      time_today: "",
      progress_dialog: false,
      dialog: false,
      linear_scale_data_from_data: null,
      value: [12, 10, 3, 8, 14, 20, 15, 2, 21, 17, 11, 12],
    };
  },

  computed: {
    ...mapGetters({
      complaints: "complaint/complaints",
      user: "auth/user",
      dashboard_summary: "complaint/dashboard_summary",
      linear_scale_data: "complaint/linear_scale_data",
      mediations: "mediation/mediations",
      conciliations: "conciliation/conciliations",
      arbitrations: "arbitration/arbitrations",
      executions: "execution/executions",
      unsettleds: "unsettled/unsettleds",
    }),
  },
  methods: {
    ...mapActions({
      get_complaints: "complaint/get_complaints",
      get_case_history: "history/get_case_history",
      get_lupons: "lupon/get_lupons",
      set_alert: "notification/set_alert",
      set_notifications: "notification/set_notifications",
      get_notifications: "notification/get_notifications",
      get_dashboard_summary: "complaint/get_dashboard_summary",
      check_if_user_exists: "auth/check_if_user_exists",
      get_backups: "backup/get_backups",
    }),
    add_complaint() {
      this.$router.push("/filingofcomplaint");
    },

    checkSingleDigit(digit) {
      return ("0" + digit).slice(-2);
    },
    barangay_info() {
      this.$router.push("/userinformation");
    },
    check_notification(element) {
      if (
        moment(new Date("2022-04-18")).format("YYYY-MM-DD") ==
        moment(element.date_of_first_hearing).format("YYYY-MM-DD")
      ) {
        let obj = {
          notification_message:
            "Today's hearing is from complaint #" + element.exclusive_case_no,
          read: false,
          other_data: {
            exclusive_case_no: element.exclusive_case_no,
            from_table: "mediation",
          },
        };
        this.notifications.push(obj);
      }
    },
  },
  mounted() {
    // this.startTime();
    this.complainants_respondents_json[0].name = "John Doe";
    console.log(this.complainants_respondents_json);
  },
  async created() {
    await this.get_dashboard_summary();
    if (this.complaints == null) {
      this.progress_dialog = true;
      await this.get_complaints();
      await this.get_lupons();
      await this.get_notifications();
      await this.get_dashboard_summary();
      // await this.get_backups();
      if (this.user.name_of_punong_barangay) {
        this.code_activator_alert_modal = false;
      } else {
        this.code_activator_alert_modal = true;
      }
      this.progress_dialog = false;
    }
    // let response = await this.check_if_user_exists();
    // this.code_activator_alert_modal = response == "1" ? false : true;
  },
};
</script>

<style scoped>
.overlap_this {
  transform: translateX(400);
}
</style>
