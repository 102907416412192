<template>
  <div>
    <div v-if="!case_history_ready" class="mt-10 ml-9">
      <v-progress-linear indeterminate></v-progress-linear>
      <p class="overline">History is Loading</p>
    </div>
    <v-card flat v-else>
      <v-card-text>
        <div v-if="this.case_history">
          <p style="font-weight: bold">CASE HISTORY</p>
          <v-timeline dense align-top>
            <v-timeline-item
              right
              v-for="(c_history, i) in case_history"
              :key="i"
              small
              :color="get_case_color(c_history.from_table)"
            >
              <template v-slot:opposite>
                <span
                  :class="` text-right headline font-weight-light mb-4 ${get_case_color(
                    c_history.from_table
                  )}--text`"
                  >Sample</span
                >
              </template>
              <div class="py-4">
                <h2
                  :class="`overline font-weight-bold mb-4 ${get_case_color(
                    c_history.from_table
                  )}--text`"
                >
                  {{ c_history.from_table }}
                </h2>

                <div class="grey--text">
                  {{ c_history.action }}
                  <br />

                  {{ transform_date(c_history.created_at) }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  props: { complaint: Object },
  data() {
    return {
      case_history_ready: false,
    };
  },
  computed: {
    ...mapGetters({
      case_history: "history/case_history",
    }),
  },
  methods: {
    ...mapActions({
      get_case_history: "history/get_case_history",
    }),
    get_case_color(table) {
      var color;
      if (table == null) color = "black";
      if (table == "Complaint") color = "blue";
      if (table == "Conciliation") color = "orange";
      if (table == "Mediation") color = "deep-purple";
      if (table == "Arbitration") color = "pink";
      if (table == "Settled") color = "green";
      if (table == "Unsettled") color = "red";
      return color;
    },
    transform_date(d) {
      return moment(d).format("MMMM DD, YYYY");
    },
  },

  async created() {
    // console.log("hey");
    await this.get_case_history(this.complaint.id);
    this.case_history_ready = true;
  },
};
</script>

<style></style>
