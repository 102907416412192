<template>
  <v-container fluid>
    <v-row dense>
      <v-col class="pa-5">
        <span class="deep-purple--text accent-4 font-weight-bold headline">
          DIRECTORY OF LUPON MEMBERS
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <lupon-table />
        </v-card>
      </v-col>
    </v-row>

    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import LuponTable from "../components/DirectoryManagement/LuponTable.vue";
import ProgressLinear from "@/components/ProgressLinear.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    LuponTable,
    ProgressLinear,
  },
  data() {
    return {
      progress_dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      lupons: "lupon/lupons",
      complaints: "complaint/complaints",
    }),
  },

  methods: {
    ...mapActions({
      get_complaints: "complaint/get_complaints",
      get_lupons: "lupon/get_lupons",
      get_notifications: "notification/get_notifications",
      get_dashboard_summary: "complaint/get_dashboard_summary",
    }),
  },
  async created() {
    if (this.complaints == null) {
      this.progress_dialog = true;
      await this.get_complaints();
      await this.get_lupons();
      await this.get_notifications();
      await this.get_dashboard_summary();
      this.progress_dialog = false;
    }
  },
};
</script>
