<template>
  <div>
    <v-icon color="deep-purple accent-4" size="20" @click="dialog = true"
      >mdi-pencil
    </v-icon>

    <v-dialog persistent v-model="this.dialog" max-width="500">
      <v-sheet
        color="deep-purple accent-4"
        class="pt-2 d-flex justify-center"
        height="40px"
        ><h3 class="white--text">Edit Lupon Information</h3></v-sheet
      >

      <v-card class="pa-4" flat tile
        ><br />
        <v-card-text>
          <v-form ref="item">
            <v-text-field
              v-model="item.full_name"
              prepend-icon="mdi-account-tie"
              label="Name"
            ></v-text-field>
            <v-select
              :items="civil_status"
              v-model="item.civil_status"
              prepend-icon="mdi-account-heart"
              label="Civil Status"
            ></v-select>
            <v-text-field
              v-model="item.permanent_address"
              prepend-icon="mdi-map-marker"
              label="Permanent Address"
            ></v-text-field>

            <v-text-field
              prepend-icon="mdi-school"
              label="Highest Educational Attainment"
              v-model="item.highest_educational_attainment"
            ></v-text-field>
            <v-text-field
              prepend-icon="mdi-calendar-question"
              label="Term of Office"
              v-model="item.term_of_office"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="deep-purple accent-4"
            dark
            class="ml-2 rounded-xl"
            @click="submit"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false" color="" class="rounded-xl"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  props: ["item"],
  components: {
    ProgressLinear,
  },
  data() {
    return {
      notifications: { notification: "" },
      progress_dialog: false,
      civil_status: ["Single", "Married", "Widow/Widower"],
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      edit_lupon: "lupon/edit_lupon",
      set_notification: "notification/set_notification",
    }),
    submit() {
      this.progress_dialog = true;
      this.edit_lupon(this.item).then(() => {
        this.set_notification({
          message: "Lupon member's information successfully edited",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.progress_dialog = false;
        this.dialog = false;
      });
    },
  },
};
</script>
