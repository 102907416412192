<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="dialog = true"
      >Repudiation</v-btn
    >

    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card flat>
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">Repudiation</h3></v-sheet
        >
        <v-card-title></v-card-title>
        <v-card-text>
          <v-radio-group row v-model="form.reason" label="Reason">
            <v-radio value="Fraud" label="Fraud"></v-radio>
            <v-radio value="Violence" label="Violence"></v-radio>
            <v-radio value="Intimidation" label="Intimidation"></v-radio>
          </v-radio-group>
          <v-textarea
            label="Statement/Details"
            v-model="form.statement"
          ></v-textarea>
          <v-radio-group
            row
            v-model="form.signed_by"
            label="Signed by"
            @change="check_signatory()"
          >
            <v-radio value="Punong Barangay" label="Punong Barangay"></v-radio>
            <v-radio
              value="Pangkat Chairman"
              label="Pangkat Chairman"
            ></v-radio>
            <v-radio value="Member" label="Member"></v-radio>
          </v-radio-group>
          <v-text-field
            label="Signatory"
            v-model="form.signatory"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="deep-purple lighten-4" @click="dialog = false">
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
export default {
  data() {
    return {
      other_data: {},
      dialog: false,
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    check_signatory() {
      if (this.form.signed_by == "Punong Barangay") {
        this.form.signatory = this.user.name_of_punong_barangay;
      }
    },
    print() {
      let other_data = this.form;
      window.open(
        this.print_url +
          this.user.id +
          "/repudiation/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
};
</script>
