<template>
  <!-- nav drawer -->
  <v-navigation-drawer
    app
    v-model="drawer"
    width="250"
    v-if="user"
    class="elevation-"
  >
    <v-list>
      <!-- link 1 -->
      <template v-if="authenticated">
        <v-list-item color="success">
          <v-list-item-avatar v-if="user.seal_url != null">
            <img :src="user.seal_url" />
          </v-list-item-avatar>
          <v-list-item-avatar v-else>
            <img src="../assets/default_icon.png" />
          </v-list-item-avatar>
          <v-list-item-content v-if="user.barangay != null">
            <!-- <template v-if="authenticated"> -->
            <v-list-item-title class="text--lighten-4 overline"
              >BRGY. {{ user.barangay }}</v-list-item-title
            >
            <v-list-item-subtitle class="primary--text caption text--darken-1"
              >{{ user.city_or_municipality }},
              {{ user.province }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-content v-else>
            <!-- <template v-if="authenticated"> -->
            <v-list-item-title class="text--lighten-4 overline"
              >UNKNOWN BARANGAY</v-list-item-title
            >
            <v-list-item-subtitle class="primary--text text--darken-1"
              >Unknown Province</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          dense
          active-class="active"
          v-for="link in links"
          v-bind:key="link.icon"
          route
          :to="link.route"
        >
          <v-list-item-icon>
            <v-icon class="text--darken-2">{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text--lighten-2 caption">{{
              link.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- signout -->
        <!-- <v-list-item @click="signOut" route>
            <v-list-item-icon>
              <v-icon class="blue--text text--lighten-4">
                mdi-exit_to_app
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="blue--text text--lighten-4">
                Sign-Out
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
      </template>
      <!-- link 2 -->
    </v-list>

    <!-- <template v-slot:append class="">
        <div class="pa-2 d-flex justify ml-3">
          <p class="caption white--text">
            <v-icon small color="white" class="pr-2">mdi-flash</v-icon>Powered
            by:
            <strong>NWAS IT Solutions</strong>
            <br />
            <span class="grey--text text--darken-1"
              ><v-icon color="white" small class="pr-2">mdi-gmail</v-icon>
              NWASITServices@gmail.com</span
            >
          </p>
      
        </div>
      </template> -->
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["hide"],
  name: "Navbar",
  computed: {
    ...mapGetters({
      user: "auth/user",
      loading_out: "1",
    }),
  },
  data() {
    return {
      authenticated: true,
      drawer: true,
      links: [
        { icon: "mdi-home", name: "Dashboard", route: "/" },
        {
          icon: "mdi-plus-circle",
          name: "Filing of Complaint",
          route: "/filingofcomplaint",
        },
        {
          icon: "mdi-account-multiple",
          name: "Mediation",
          route: "/mediation",
        },
        {
          icon: "mdi-account-group-outline",
          name: "Conciliation",
          route: "/conciliation",
        },
        {
          icon: "mdi-gavel",
          name: "Arbitration",
          route: "/arbitration",
        },
        {
          icon: "mdi-shield-star",
          name: "Settled Cases",
          route: "/execution",
        },
        {
          icon: "mdi-arrow-decision",
          name: "Unsettled Cases",
          route: "/unsettled",
        },
        {
          icon: "mdi-file-chart",
          name: "Reports",
          route: "/reports",
        },
        {
          icon: "mdi-form-select",
          name: "Forms",
          route: "/forms",
        },
        {
          icon: "mdi-account-search-outline",
          name: "Directory",
          route: "/directory",
        },
        {
          icon: "mdi-frequently-asked-questions",
          name: "FAQs",
          route: "/faqs",
        },
        {
          icon: "mdi-information",
          name: "User Information",
          route: "/userinformation",
        },
      ],
      links2: [
        { icon: "mdi-login", name: "Sign-in", route: "/signin", small: true },
        { icon: "mdi-book", name: "About", route: "/about", small: true },
      ],
    };
  },
  methods: {
    ...mapActions({
      signOutActions: "auth/signOut",
    }),
    signOut() {
      this.loading_out = "2";
      this.signOutActions().then(() => {
        this.loading_out = "1";
        this.$router.push(`/signin`);
      });
    },
  },
};
</script>
<style scoped>
.active {
  background-image: #1565c0;
}
</style>
