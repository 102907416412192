<template>
  <v-app class="myFont">
    <div v-if="page_ready">
      <TheNavBar />
      <TopNavBar :page_name="currentRouteName" />
      <v-main>
        <!-- alert notification -->
        <notification />
        <search />
        <alert />
        <transition name="router_anim" mode="out-in">
          <router-view />
        </transition>
        <the-footer v-if="user" />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TopNavBar from "@/components/TopNavBar.vue";
import TheNavBar from "@/components/TheNavBar.vue";
import alert from "@/components/alert.vue";
import Notification from "./components/Notification.vue";
import TheFooter from "./components/TheFooter.vue";
import Search from "@/components/SearchComplaint.vue";

export default {
  components: {
    TheNavBar,
    TopNavBar,
    Notification,
    alert,
    TheFooter,
    Search,
  },
  name: "App",

  data: () => ({
    code_activator_message: "",
    code_activator_dialog: false,
    acn: "",
    page_ready: true,
    hide_nav: true,
    hide_top_nav: true,
  }),
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
      check_subscription: "auth/check_subscription",
    }),
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      subscription: "auth/subscription",
    }),
    currentRouteName() {
      return this.$route.name;
    },

    theme() {
      return this.$vuetify.theme ? "dark" : "light";
    },
  },
  watch: {
    $route() {
      document.title = this.currentRouteName + " KP-CMS";
    },
  },

  async created() {},
};
</script>

<style>
.title {
  font-size: 120px;
}

.router_anim-enter {
  opacity: 0;
  transform: translateX(-200px);
}

.router_anim-enter-active {
  transition: all 0.2s ease;
}

.router_anim-leave-to {
  opacity: 0;
  transform: translateX(200px);
}

.router_anim-leave-active {
  transition: all 0.2s ease;
}

/* .page {
  position: fixed;
  width: inherit;
} */
</style>
