<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Agreement for Arbitration</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">
            Agreement for arbitration
          </h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <div>
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="form.mediator"
                    label="Mediator"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
export default {
  data() {
    return {
      json_dialog: false,
      form: {
        mediator: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    input_other_data() {
      this.json_dialog = true;
    },

    print() {
      let other_data = {
        mediator: [
          {
            mediator_name: this.form.mediator,
          },
        ],
      };
      window.open(
        this.print_url +
          this.user.id +
          "/agreement_for_arbitration/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
  created() {
    this.form.mediator = this.user.name_of_punong_barangay;
  },
};
</script>
