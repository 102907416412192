import axios from "axios";
export default {
  namespaced: true,
  state: {
    backups: null,
    backup: null,
  },
  getters: {
    backups(state) {
      return state.backups;
    },
    backup(state) {
      return state.backup;
    },
  },
  mutations: {
    SET_BACKUPS(state, backups) {
      let arr = [];
      backups.forEach((backup) => {
        let obj = {
          backup_path: backup,
        };
        arr.push(obj);
      });
      state.backups = arr;
    },
    ADD_BACKUP(state, backup) {
      state.backups.unshift(backup);
    },
  },
  actions: {
    async get_backups({ commit }) {
      let response = await axios.get("/test_backup");
      commit("SET_BACKUPS", response.data);
    },
    async add_backup({ commit }) {
      let response = await axios.get("/backup");
      commit("ADD_BACKUP", response.data);
    },
  },
};
