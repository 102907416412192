import axios from "axios";
export default {
  namespaced: true,
  state: {
    case_history: [],
  },
  getters: {
    case_history(state) {
      return state.case_history;
    },
  },
  mutations: {
    SET_CASE_HISTORY(state, request) {
      state.case_history = request;
    },
    ADD_CASE_HISTORY(state, history) {
      state.case_history.push(history);
    },
  },
  actions: {
    async get_case_history({ commit }, id) {
      let response = await axios.get("/history/" + id);
      commit("SET_CASE_HISTORY", response.data);
    },
    async add_case_history({ commit }, request) {
      let response = await axios.post("/history", request);
      commit("ADD_CASE_HISTORY", response.data);
    },
  },
};
