<template>
  <v-container fluid>
    <v-row dense class="mt-n6">
      <v-col>
        <div class="mb-15" v-if="edit_item">
          <v-card elevation="3" class="pa-5 mb-15">
            <v-card-text>
              <v-row class="mb-2">
                <v-col class="pa-5" cols="">
                  <span
                    class="
                      font-weight-bold
                      display-1
                      text-uppercase
                      deep-purple--text
                    "
                  >
                    Edit Complaint
                  </span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br />
              <br />
              <v-form ref="edit_item">
                <v-row>
                  <v-col cols="8">
                    <v-row
                      v-for="(complainant, index) in edit_item.complainant"
                      :key="index"
                    >
                      <v-col>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              prepend-icon="mdi-account-outline"
                              class="body-2 rounded-xl"
                              :rules="[rules.required]"
                              hide-details=""
                              dense
                              label="Name of Complainant"
                              placeholder="Name of Complainant"
                              v-model="complainant.complainant_name"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              class="body-2"
                              :rules="[rules.required]"
                              dense
                              label="Age"
                              placeholder="Age"
                              hide-details=""
                              v-model="complainant.complainant_age"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              prepend-icon="mdi-account-multiple-outline"
                              class="body-2"
                              :rules="[rules.required]"
                              :complaints="civil_status"
                              :items="civil_status"
                              dense
                              label="Civil Status"
                              placeholder="Civil Status"
                              hide-details=""
                              v-model="complainant.complainant_civil_status"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col>
                            <v-text-field
                              prepend-icon="mdi-map-marker-outline"
                              class="body-2"
                              :rules="[rules.required]"
                              dense
                              label="Permanent Address"
                              placeholder="Permanent Address"
                              hide-details=""
                              v-model="complainant.complainant_address"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row class="justify-center">
                      <v-col cols="2" offset="2">
                        <h4>VS</h4>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="(respondent, i) in edit_item.respondent"
                      :key="'A' + i"
                    >
                      <!-- <v-row> -->
                      <v-col>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              prepend-icon="mdi-account-outline"
                              class="body-2"
                              :rules="[rules.required]"
                              hide-details=""
                              dense
                              label="Name of Respondent"
                              placeholder="Name of Respondent"
                              v-model="respondent.respondent_name"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              class="body-2"
                              :rules="[rules.required]"
                              dense
                              label="Age"
                              placeholder="Age"
                              hide-details=""
                              v-model="respondent.respondent_age"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              prepend-icon="mdi-account-multiple-outline"
                              class="body-2"
                              :rules="[rules.required]"
                              :complaints="civil_status"
                              dense
                              :items="civil_status"
                              label="Civil Status"
                              placeholder="Civil Status"
                              hide-details=""
                              v-model="respondent.respondent_civil_status"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col>
                            <v-text-field
                              prepend-icon="mdi-map-marker-outline"
                              class="body-2"
                              :rules="[rules.required]"
                              dense
                              label="Permanent Address"
                              placeholder="Permanent Address"
                              hide-details=""
                              v-model="respondent.respondent_address"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row> </v-row>
                    <v-row>
                      <v-col offset="2">
                        <v-text-field
                          class="mb-4 body-2"
                          :rules="[rules.required]"
                          dense
                          label="Case Type"
                          readonly
                          hide-details=""
                          v-model="edit_item.case_type"
                          disabled
                        >
                        </v-text-field>
                        <v-text-field
                          class="mb-4 body-2"
                          :rules="[rules.required]"
                          dense
                          label="Case Number"
                          readonly
                          hide-details=""
                          v-model="edit_item.exclusive_case_no"
                          disabled
                        >
                        </v-text-field>

                        <v-text-field
                          class="select mb-4 body-2"
                          :rules="[rules.required]"
                          dense
                          label="Case Title"
                          readonly
                          hide-details=""
                          v-model="edit_item.case_title"
                          disabled
                        >
                        </v-text-field>

                        <v-text-field
                          v-if="edit_item.case_title == 'Others'"
                          class="body-2"
                          :rules="[rules.required]"
                          dense
                          label="Case Title"
                          hide-details=""
                          v-model="edit_item.others"
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="justify-center">
                      <v-col cols="2" offset="2">
                        <h4 style="visibility: hidden">VS</h4>
                      </v-col>
                    </v-row>
                    <v-row class="">
                      <v-col offset="2">
                        <v-text-field
                          label="Date of Filing"
                          dense
                          class="mb-0 body-2"
                          v-model="edit_item.created_at"
                          readonly
                          disabled
                        ></v-text-field>
                        <v-datetime-picker
                          class="body-2 mt-0 mb-0"
                          :rules="[rules.required]"
                          label="Date and Time of Hearing"
                          v-model="edit_item.mediation.date_of_first_hearing"
                          @input="is_valid_date(edit_item, 6)"
                        ></v-datetime-picker>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      prepend-icon="mdi-chat-processing-outline"
                      class="body-2"
                      :rules="[rules.required]"
                      label="Statements of Facts"
                      placeholder="Statements of Facts"
                      hide-details=""
                      v-model="edit_item.statement_of_facts"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      prepend-icon="mdi-hand-coin-outline"
                      class="body-2"
                      :rules="[rules.required]"
                      label="Reliefs Sought"
                      placeholder="Reliefs Sought"
                      hide-details=""
                      v-model="edit_item.reliefs_sought"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-right">
                  <v-col>
                    <v-btn
                      color="deep-purple accent-4"
                      class="rounded-xl"
                      dark
                      @click="submit"
                    >
                      <v-icon></v-icon>
                      Submit
                    </v-btn>
                    <v-btn
                      color="yellow"
                      class="rounded-xl black--text ml-2"
                      dark
                      @click="back"
                    >
                      <v-icon></v-icon>
                      cancel
                    </v-btn></v-col
                  >
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <progress-linear :dialog="progress_dialog" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import ConfirmNotification from "@/components/ConfirmNotification.vue";
import ProgressLinear from "@/components/ProgressLinear.vue";
import moment from "moment";

export default {
  components: {
    // ConfirmNotification,
    ProgressLinear,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },
      case_number_value: "",
      case_number1: null,
      // case_number2: "",
      dialog1: false,
      show_alert: false,
      progress_dialog: false,
      alert_message: "Are you sure you want to add this complaint?",
      date_of_filing_dialog: false,
      time_of_hearing_dialog: false,
      civil_status: ["Single", "Married", "Widow/Widower"],
      case_selected: [],
      form: {
        id: "",
        exclusive_case_no: "",
        case_type: "",
        case_title: "",
        others: "",
        date_of_filing: "",
        time_of_hearing: "",
        statement_of_facts: "",
        reliefs_sought: "",
        judge: "",
        complainant: [
          {
            complainant_name: "",
            complainant_age: "",
            complainant_civil_status: "",
            complainant_address: "",
          },
        ],
        respondent: [
          {
            respondent_name: "",
            respondent_age: "",
            respondent_civil_status: "",
            respondent_address: "",
          },
        ],
      },
      type_of_case: ["Criminal Case", "Civil Case"],
      criminal_case: [
        "Unlawful use of means of publication and unlawful utterances",
        "Alarms and scandals",
        "Using false certificates",
        "Using fictitious names and concealing true names",
        "Illegal use of uniforms and insignias",
        "Physical injuries inflicted in a tumultuous affray",
        "Giving assistance to consummated suicide",
        "Less serious physical injuries",
        "Slight physical injuries and maltreatment",
        "Unlawful arrest",
        "Inducing a minor to abandon his/her home",
        "Abandonment of a person in danger and abandonment of one’s own victim",
        "Abandoning a minor (a child under seven years old)",
        "Abandonment of a minor by persons entrusted with his/her custody;",
        "Indifference of parents",
        "Qualified trespass to dwelling (without the use of violence and intimidation)",
        "Other forms of trespass",
        "Light threats",
        "Other light threats",
        "Grave coercion",
        "Light coercion",
        "Other similar coercions (compulsory purchase of merchandise and payment of wages by means of tokens)",
        "Formation, maintenance and prohibition of combination of capital or labor through violence or threats",
        "Discovering secrets through seizure and correspondence",
        "Revealing secrets with abuse of authority",
        "Theft (if the value of the property stolen does not exceed Php 50)",
        "Qualified theft (if the amount does not exceed Php 500)",
        "Occupation of real property or usurpation of real rights in property",
        "Altering boundaries or landmarks",
        "Swindling or estafa (if the amount does not exceed Php 200)",
        "Other forms of swindling",
        "Swindling a minor",
        "Other deceits",
        "Removal, sale or pledge of mortgaged property",
        "Special cases of malicious mischief (if the value of the damaged property does not exceed Php 1,000)",
        "Other mischiefs (if the value of the damaged property does not exceed Php1,000)",
        "Simple seduction",
        "Acts of lasciviousness with the consent of the offended party",
        "Threatening to publish and offer to prevent such publication for compensation",
        "Prohibiting publication of acts referred to in the course of official proceedings",
        "Incriminating innocent persons",
        "Intriguing against honor",
        "Issuing checks without sufficient funds (Bouncing Checks Law or BP 22);",
        "Fencing of stolen properties (if the property involved is not more than Php 50 under PD 1612.",
      ],
      civil_case: [
        "Cancellation of Contract",
        "Collection of Sum of Money",
        "Damages",
        "Specific Performance of Obligation",
        "Others",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      edit_item: "complaint/edit_item",
    }),
  },
  methods: {
    ...mapActions({
      add_complaint: "complaint/add_complaint",
      set_notification: "notification/set_notification",
      get_notifications: "notification/get_notifications",
      set_mediation: "mediation/set_mediation",
      set_complaint: "complaint/set_complaint",
      get_complaints: "complaint/get_complaints",
      edit_complaint: "complaint/edit_complaint",
      edit_conciliation: "conciliation/edit_conciliation",
      edit_arbitration: "arbitration/edit_arbitration",
      edit_execution: "execution/edit_execution",
      edit_unsettled: "unsettled/edit_unsettled",
    }),
    add_complainant() {
      this.form.complainant.push({
        complainant_name: "",
        complainant_age: "",
        complainant_civil_status: "",
        complainant_address: "",
      });
      console.log(this.form.complainant);
    },
    add_respondent() {
      this.form.respondent.push({
        respondent_name: "",
        respondent_age: "",
        respondent_civil_status: "",
        respondent_address: "",
      });
    },
    remove_complainant(index) {
      this.form.complainant.splice(index, 1);
    },
    remove_respondent(index) {
      this.form.respondent.splice(index, 1);
    },
    back() {
      this.$router.go(-1);
    },
    is_valid_date(edit_item, days) {
      this.format_datetime(edit_item.mediation.date_of_first_hearing);
      var date_of_filing = moment(edit_item.created_at);
      var date_today = moment();
      var threshold_date = date_today.add(days, "days");
      var date_of_first_hearing = moment(
        edit_item.mediation.date_of_first_hearing
      );

      // if above
      if (date_of_first_hearing.isAfter(threshold_date, "day")) {
        this.set_notification({
          message:
            "The date of hearing should not exceed five(5) days from the date of filing the complaint.",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return false;
      }
      // if below
      else if (date_of_first_hearing.isBefore(date_of_filing, "day")) {
        this.set_notification({
          message:
            "The date of hearing should not be below the date of filing of the complaint.",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return false;
      }
      return true;
    },
    format_datetime(date) {
      var formated_date = moment(date).format("Y-M-D HH:mm");
      return formated_date;
    },
    is_form_validated() {
      if (this.edit_item.mediation.date_of_first_hearing == "")
        this.set_notification({
          message: "All fields are required!",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
      else if (this.$refs.edit_item.validate()) return true;
      else
        this.set_notification({
          message: "All fields are required!",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
    },
    //THIS FUNCTION WILL GENERATE THE NEXT EXCLUSIVE CASE NO.

    async submit() {
      if (!this.is_form_validated()) return;
      if (!this.is_valid_date(this.edit_item, 5)) return;
      this.edit_item.mediation.date_of_first_hearing = moment(
        this.edit_item.mediation.date_of_first_hearing
      ).format("Y-M-D HH:mm");
      this.progress_dialog = true;
      await this.edit_complaint(this.edit_item);
      await this.get_notifications();
      this.progress_dialog = false;
      // this.$router.go(-1);
      this.set_notification({
        message: "Successfully edited a complaint",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
    },

    close_confirm() {
      this.show_alert = false;
      this.submit_loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  mounted() {
    if (this.edit_item) {
      this.edit_item.created_at = moment(this.edit_item.created_at).format(
        "YYYY-MM-DD"
      );
    }
  },
  created() {
    if (this.edit_item == null) this.$router.go(-1);
    else
      this.edit_item.mediation.date_of_first_hearing = new Date(
        this.edit_item.mediation.date_of_first_hearing
      );
  },
};
</script>
<style scoped>
.v-select {
  max-width: 272px;
}
</style>
