<template>
  <v-row
    v-if="complaint.current_table == 'Mediation'"
    dense
    align="center"
    justify="center"
  >
    <v-col>
      <v-card>
        <v-card-title style="color: grey" class="subtitle-2">
          MEDIATOR:
        </v-card-title>

        <v-row class="d-flex justify-center">
          <v-col cols="3" dense>
            <span class="d-flex justify-center"
              ><v-img src="@/assets/profile.jpg" width="30"></v-img
            ></span>
            <span class="d-flex justify-center">
              {{
                get_mediator(JSON.parse(complaint.mediation.mediator)[0])
              }}</span
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: { complaint: Object },
  computed: {
    ...mapGetters({
      user: "auth/user",
      lupons: "lupon/lupons",
    }),
  },
  methods: {
    get_mediator(id) {
      console.log(id);
      let filtered = this.lupons.filter((m) => {
        return m.id == id;
      });
      console.log(filtered);
      return filtered[0].full_name;
    },
  },
};
</script>

<style></style>
