<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Subpoena</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">subpoena</h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <div>
              <v-row v-for="(input, name, k) in witnesses" v-bind:key="k">
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    hide-details=""
                    class="body-2 mb-0"
                    v-model="input.name"
                    label="Witness name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-icon
                    @click="remove_witness(k)"
                    color="deep-purple accent-4"
                    v-show="k || (!k && witnesses.length > 1)"
                    >mdi-minus-circle</v-icon
                  >
                </v-col>
              </v-row>
              <v-tooltip right color="deep-purple accent-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mb-1 mt-1"
                    @click="add_witness"
                    color="deep-purple accent-4"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Add witness</span>
              </v-tooltip>

              <v-row>
                <v-col cols="11">
                  <!-- <v-datetime-picker
                    class="body-2 mb-4"
                    v-model="form.date"
                    label="Appearance Date"
                  ></v-datetime-picker
                  > -->

                  <custom-date-picker
                    @setDateTime="setDateData"
                    label="Appearance Date"
                  />

                  <br />
                  <v-text-field
                    dense
                    outlined
                    v-model="form.mediator"
                    label="Mediator"
                  ></v-text-field
                  ><br />
                  <v-text-field
                    v-model="form.venue"
                    class="body-2"
                    label="Venue"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
import moment from "moment";
import CustomDatePicker from "../../CustomDatePicker.vue";
export default {
  components: { CustomDatePicker },
  data() {
    return {
      json_dialog: false,
      witnesses: [
        {
          name: "",
        },
      ],
      form: {
        date: "",
        mediator: "",
        venue: "Barangay Hall",
        language: "auth/language",
      },
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    add_witness() {
      this.witnesses.push({
        name: "",
      });
    },
    remove_witness(index) {
      this.witnesses.splice(index, 1);
    },
    input_other_data() {
      this.json_dialog = true;
    },
    setDateData(data) {
      this.form.date = data;
    },
    print() {
      let other_data = {
        witnesses: this.witnesses,
        date: [
          {
            hour: moment(this.form.date).format("h:mm"),
            month: moment(this.form.date).format("MMMM"),
            year: moment(this.form.date).format("YYYY"),
            day: moment(this.form.date).format("Do"),
            // insert time of date here eg PM or AM
            timeOfDay:
              moment(this.form.date).format("A") == "AM"
                ? "morning"
                : "afternoon",
          },
        ],
        mediator: [
          {
            mediator_name: this.form.mediator,
          },
        ],
        venue: this.form.venue,
      };
      window.open(
        this.print_url +
          this.user.id +
          "/subpoena/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
  created() {
    this.form.mediator = this.user.name_of_punong_barangay;
  },
};
</script>
