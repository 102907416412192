<template>
  <v-container fluid>
    <v-row dense>
      <v-col class="pa-5">
        <span class="deep-purple--text accent-4 font-weight-bold headline">
          SUMMARY REPORTS
        </span>
      </v-col>

      <v-col class="col-3 grey--text mt-5">
        <v-icon small>mdi-calendar</v-icon>
        As of:
        <v-chip class="white--text ml-2" color="grey"> {{ date_today }}</v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
        dark
        class="ml-3 mr-3 rounded"
        v-model="tab"
        background-color="#757575"
        color="white"
        grow
      >
        <v-tab @click="filter = 'monthly'"> Monthly </v-tab>
        <v-tab @click="filter = 'quarterly'"> Quarterly </v-tab>
        <v-tab @click="filter = 'semestral'"> Semestral </v-tab>
        <v-tab @click="filter = 'yearly'"> Yearly </v-tab>
      </v-tabs>

      <v-card>
        <report-component :param="filter" />
      </v-card>
    </v-row>

    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "../components/ProgressLinear.vue";
import moment from "moment";
import ReportComponent from "../components/ReportManagement/ReportComponent.vue";

export default {
  components: {
    ReportComponent,
    ProgressLinear,
  },
  data() {
    return {
      filter: "monthly",
      param: null,
      tab: null,
      emphasize_value: "",
      progress_dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      report: "statistics/report",
      complaints: "complaint/complaints",
      user: "auth/user",
    }),
    date_today() {
      var date = new Date();
      date = moment(date).format("MMM D, YYYY");
      return date;
    },
  },
  methods: {
    ...mapActions({
      get_report_data: "statistics/get_report_data",
      get_complaints: "complaint/get_complaints",
      get_lupons: "lupon/get_lupons",
      get_notifications: "notification/get_notifications",
      get_dashboard_summary: "complaint/get_dashboard_summary",
    }),
    format(val, x) {
      if (x == 15) return this.separator(val.toFixed(2));
      else return val;
    },
  },
  async created() {
    if (this.complaints == null) {
      this.progress_dialog = true;
      await this.get_complaints();
      await this.get_lupons();
      await this.get_notifications();
      await this.get_dashboard_summary();
      this.progress_dialog = false;
    }
  },
};
</script>
<style scoped>
table,
thead,
th {
  border: 1px solid lightgrey;
  border-collapse: collapse;
  background-color: #757575;
}
.center-text {
  text-align: center;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
td {
  text-align: center;
}
</style>
