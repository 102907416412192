<template>
  <v-container fluid>
    <v-row dense>
      <v-col class="pa-5">
        <span class="deep-purple--text accent-4 font-weight-bold headline">
          CASES FOR CONCILIATION
        </span>
      </v-col>
      <!-- asdsa -->
      <v-col class="col-3 grey--text mt-5">
        <v-icon small>mdi-account-multiple</v-icon>
        Total Number of Cases:
        <span v-if="conciliations"
          ><v-chip color="orange" class="white--text ml-2">{{
            conciliations.length
          }}</v-chip></span
        ></v-col
      >
      <v-col class="col-3 grey--text mt-5">
        <v-icon small>mdi-calendar</v-icon>
        As of:
        <v-chip class="white--text ml-2" color="grey"> {{ date_today }}</v-chip>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-card class="mb-15">
          <conciliation-table />
        </v-card>
      </v-col>
    </v-row>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import moment from "moment";
import ConciliationTable from "../components/ConciliationManagement/ConciliationTable.vue";
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  components: {
    ConciliationTable,
    ProgressLinear,
  },
  data() {
    return {
      progress_dialog: false,
    };
  },
  computed: {
    date_today() {
      var date = new Date();
      date = moment(date).format("MMM DD, YYYY");
      return date;
    },
    ...mapGetters({
      // conciliations: "complaint/conciliations",
      complaints: "complaint/complaints",
      lupons: "lupon/lupons",
    }),
    conciliations() {
      if (this.complaints == null) return [];
      else {
        let filtered = this.complaints.filter((m) => {
          return m.current_table == "Conciliation";
        });
        return filtered;
      }
    },
  },
  methods: {
    ...mapActions({
      get_complaints: "complaint/get_complaints",
      get_lupons: "lupon/get_lupons",
      get_notifications: "notification/get_notifications",
      get_dashboard_summary: "complaint/get_dashboard_summary",
    }),
  },
  async created() {
    if (this.complaints == null) {
      this.progress_dialog = true;
      await this.get_complaints();
      await this.get_lupons();
      await this.get_notifications();
      await this.get_dashboard_summary();
      this.progress_dialog = false;
    }
  },
};
</script>
