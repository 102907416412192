<template>
  <div>
    <v-btn
      text
      class="rounded-xl"
      dark
      small
      color="deep-purple accent-4"
      @click="dialog = true"
      >add date</v-btn
    >
    <!-- test -->
    <v-dialog v-model="dialog" max-width="400px" persistent>
      <v-card>
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text">
            Set the date and time of first hearing
          </h3></v-sheet
        >
        <v-card-text class="mt-5">
          <p>
            Date of Constitution of Pangkat:
            <strong
              ><span>{{ date_of_constitution_of_pangkat }}</span></strong
            >
          </p>

          <custom-date-picker
            @setDateTime="setDateData"
            label="Date and Time of Hearing"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="add_hearing()"
          >
            save
          </v-btn>
          <v-btn small color="deep-purple lighten-4" @click="dialog = false">
            cancel
          </v-btn>

          <!-- <p class="caption grey--text small--text">AddPeriodOfHearing.vue</p> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>
<script>
import ProgressLinear from "@/components/ProgressLinear.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import CustomDatePicker from "../CustomDatePicker.vue";
export default {
  components: {
    ProgressLinear,
    CustomDatePicker,
  },

  data() {
    return {
      dialog: false,
      progress_dialog: false,
      date_of_first_hearing: "",
      date_set: false,
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      lupons: "lupon/lupons",
    }),
    date_of_constitution_of_pangkat() {
      let date = moment(
        this.complaint.conciliation.date_of_constitution_of_pangkat
      ).format("MMMM DD, YYYY");
      return date;
    },
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      get_notifications: "notification/get_notifications",
      add_period_of_hearing: "complaint/add_period_of_hearing",
    }),
    ...mapMutations({
      set_complaint: "complaint/SET_COMPLAINT",
    }),

    setDateData(data) {
      this.date_set = true;
      this.check_date();
      this.date_of_first_hearing = data;
    },
    async add_hearing() {
      if (!this.date_set) return alert("Please set the date first");
      this.progress_dialog = true;
      if (this.check_date() == false) {
        this.progress_dialog = false;
        return this.set_notification({
          message:
            "The first day of hearing shall be within three (3) days from the date of constitution of pangkat.",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
      } else {
        let obj = {
          complaint_id: this.complaint.id,
          date_of_first_hearing: moment(this.date_of_first_hearing).format(
            "YYYY-MM-DD"
          ),
        };
        await this.add_period_of_hearing(obj);
        await this.get_notifications();
        this.$emit("close_add_lupon_dialog", false);
        this.dialog = false;
        this.set_notification({
          message: "Successful",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.progress_dialog = false;
      }
    },

    check_date() {
      const date_of_first_hearing = moment(this.date_of_first_hearing);
      const date_of_constitution_of_pangkat = moment(
        this.complaint.conciliation.date_of_constitution_of_pangkat
      );
      //  if selected date is before or after 3 days upon the date of consitution of pangkat, send error
      if (
        date_of_first_hearing.diff(date_of_constitution_of_pangkat, "days") <
          0 ||
        date_of_first_hearing.diff(date_of_constitution_of_pangkat, "days") > 3
      ) {
        this.set_notification({
          message:
            "First day of hearing is only within three(3) days from the date of constitution of pangkat.",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });

        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
