<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn
        right
        small
        text
        color="deep-purple accent-4"
        @click="add_concilator()"
      >
        {{ button_name }}
      </v-btn>
    </div>

    <!-- ADD LUPONS DIALOG -->
    <v-dialog v-model="dialog" max-width="400px" persistent>
      <v-card flat width="400px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text ml-3">Select Lupon</h3></v-sheet
        >
        <v-card-text class="mt-6">
          <v-row>
            <v-col>
              <v-row v-for="lupon in filtered_lupons" :key="lupon.id">
                <v-col cols="1" class="mt-2">
                  <v-avatar color="indigo" size="50">
                    <img src="../assets/profile.jpg" alt="John" /> </v-avatar
                ></v-col>
                <v-col>
                  <v-checkbox
                    class="ml-8"
                    hide-details=""
                    v-model="selected_lupon_members"
                    :label="lupon.full_name"
                    :value="lupon"
                  >
                    <template v-slot="item">
                      {{ item }}
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="add_lupons()"
          >
            {{ button_name == "add conciliator" ? "Add" : "Edit" }}
          </v-btn>
          <v-btn small color="deep-purple lighten-4" @click="dialog = false">
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- question modal -->
    <v-dialog v-model="show_question_modal" persistent width="400">
      <v-card class="pa-5">
        <v-card-text>
          <h2 class="text-center deep-purple--text accent-4">
            Send a Notice to Constitute Pangkat.
          </h2>

          <notice-for-constitution-of-pangkat class="mt-2" />
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="show_question_modal = false" color="yellow"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import ProgressLinear from "@/components/ProgressLinear.vue";
import { mapActions, mapGetters } from "vuex";
import NoticeForConstitutionOfPangkat from "./Forms/ConciliationFormComponents/NoticeForConstitutionOfPangkat.vue";
export default {
  props: ["complaint"],
  components: {
    ProgressLinear,
    NoticeForConstitutionOfPangkat,
  },
  data() {
    return {
      show_question_modal: false,
      progress_dialog: false,
      // button_name: "",
      dialog: false,
      date_of_first_hearing: "",
      date_of_first_hearing_dialog: false,
      selected_lupon_members: [],

      change_status_item: null,
    };
  },
  computed: {
    ...mapGetters({
      lupons: "lupon/lupons",
    }),
    filtered_lupons() {
      let x = this.lupons.filter((l) => {
        return l.is_captain != 1;
      });
      return x;
    },
    button_name() {
      if (!JSON.parse(this.complaint.conciliation.conciliators)) {
        return "add conciliator";
      } else {
        return "edit conciliator";
      }
    },
  },

  methods: {
    add_concilator() {
      // this function assures that whenever add concilator is clicked, notice to constitute pangkat form is already sent. if not, then print
      if (!this.complaint.conciliation.date_of_constitution_of_pangkat) {
        this.show_question_modal = true;
      } else {
        this.dialog = true;
      }
    },
    check_if_conciliators_exist() {
      if (!JSON.parse(this.complaint.conciliation.conciliators)) return false;
      return true;
    },
    ...mapActions({
      add_conciliators: "complaint/add_conciliators",
      edit_conciliators: "complaint/edit_conciliators",
      add_handled: "lupon/add_handled",
      add_conciliation: "conciliation/add_conciliation",
      delete_mediation: "mediation/delete_mediation",
      set_notification: "notification/set_notification",
      add_constitution_of_pangkat_date:
        "conciliation/add_constitution_of_pangkat_date",
      add_period_of_hearing: "conciliation/add_period_of_hearing",
      add_notification: "notification/add_notification",
    }),
    lupon_exceeded() {
      if (
        this.selected_lupon_members.length > 3 ||
        this.selected_lupon_members.length < 3
      ) {
        this.set_notification({
          message: "Please pick three (3) lupon members.",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return true;
      } else {
        return false;
      }
    },
    async add_lupons() {
      if (this.button_name == "edit conciliator") {
        await this.edit_conciliators1();
      } else {
        if (this.lupon_exceeded()) return;
        else this.progress_dialog = true;
        let obj = {
          is_edit: false,
          complaint_id: this.complaint.id,
          conciliators: this.selected_lupon_members,
        };
        await this.add_conciliators(obj).catch((err) => {
          alert(err);
          this.progress_dialog = false;
        });
        this.progress_dialog = false;
        this.dialog = false;
        this.set_notification({
          message: "You have successfully added 3 lupon members!",
          color: "green",
          showing: true,
          icon: "mdi-info",
        });
      }
    },
    async edit_conciliators1() {
      this.progress_dialog = true;
      let obj = {
        is_edit: true,
        previous_conciliators: this.complaint.conciliation.conciliators,
        complaint_id: this.complaint.id,
        conciliators: this.selected_lupon_members,
      };

      if (obj.conciliators.length != 3) {
        this.progress_dialog = false;

        this.set_notification({
          message: "Please pick three (3) lupon members.",
          color: "red",
          showing: true,
          icon: "mdi-error",
        });
      } else {
        await this.edit_conciliators(obj);
        this.progress_dialog = false;
        this.dialog = false;
        this.set_notification({
          message: "You have successfully edited the conciliators.",
          color: "green",
          showing: true,
          icon: "mdi-info",
        });
      }
    },
    merge_lupon() {
      // insert lupon members to array
      let lupons_id = JSON.parse(this.complaint.conciliation.conciliators);
      console.log(lupons_id);
      this.lupons.forEach((lupon) => {
        this.add_lupon_value(lupon, lupons_id);
      });
    },
    add_lupon_value(lupon, lupons_id) {
      lupons_id.forEach((id) => {
        if (lupon.id == id) this.selected_lupon_members.push(lupon);
      });
    },
  },
  async mounted() {
    if (this.button_name == "edit conciliator") {
      await this.merge_lupon();
      console.log(this.selected_lupon_members);
    }
  },
};
</script>
