<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Notice of Hearing</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text">Notice Of Hearing</h3></v-sheet
        >
        <!-- notice of hearing  -->
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <custom-date-picker
              @setDateTime="setDateData"
              label="Date of Hearing"
            />
            <br />
            <v-text-field
              v-model="form.venue"
              class="body-2"
              label="Venue"
            ></v-text-field>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CustomDatePicker from "../../CustomDatePicker.vue";
export default {
  components: { CustomDatePicker },
  data() {
    return {
      json_dialog: false,
      form: {
        venue: "Barangay Hall",
        date: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),
    check_date() {
      let table = "mediation";
      let date = this.form.date;
      let date_of_first_hearing = this.complaint[table].date_of_first_hearing;
      let date_of_last_hearing = this.complaint[table].date_of_last_hearing;
      if (
        moment(date, "YYYY-MM-DD").isBefore(
          moment(date_of_first_hearing).format("YYYY-MM-DD")
        ) ||
        moment(date, "YYYY-MM-DD").isAfter(
          moment(date_of_last_hearing).format("YYYY-MM-DD")
        )
      ) {
        this.set_notification({
          message:
            "The Date and Time of Hearing should not go beyond the period of hearing!",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return true;
      }
    },
    input_other_data() {
      this.json_dialog = true;
    },
    setDateData(data) {
      this.check_date();
      this.form.date = data;
    },
    print() {
      if (this.check_date()) return;
      let other_data = {
        appearance_date: {
          hour: moment(this.form.date).format("h:mm"),
          month: moment(this.form.date).format("MMMM"),
          year: moment(this.form.date).format("YYYY"),
          day: moment(this.form.date).format("Do"),
          time_of_day: moment(this.form.date).format("a").toUpperCase(),
        },
        venue: this.form.venue,
      };
      window.open(
        this.print_url +
          this.user.id +
          "/notice_of_hearing/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
};
</script>
