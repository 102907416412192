<template>
  <div>
    <v-dialog v-model="search" width="880px" persistent>
      <v-card flat>
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
        >
          <v-spacer></v-spacer>
          <h3 class="white--text pl-8">Search Complaint</h3>
          <v-spacer></v-spacer>
          <v-icon dark @click="close" class="mr-2">mdi-close</v-icon>
        </v-sheet>

        <v-text-field
          class="pr-8 pl-8"
          v-model="search_string"
          prepend-inner-icon="mdi-magnify"
          placeholder="Type to search a complaint. . ."
        ></v-text-field>
        <v-data-table
          :search="search_string"
          :items="complaints"
          :headers="headers"
          hide-default-footer
          class="pa-5"
        >
          <template v-slot:[`item.view`]="{ item }">
            <v-row>
              <v-col class="mt-1">
                <v-icon color="success" size="20" @click="view(item)"
                  >mdi-eye-arrow-right
                </v-icon>
                <span
                  class="font-weight-bold"
                  style="color: green; font-size: 7pt"
                  >VIEW</span
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.complainant`]="{ item }">
            <div v-if="item.complainant != null">
              <div v-for="item in JSON.parse(item.complainant)" :key="item.id">
                {{ item.complainant_name }}
              </div>
            </div>
            <div v-else>
              <div>
                {{ item }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.respondent`]="{ item }">
            <div v-if="item.respondent != null">
              <div v-for="item in JSON.parse(item.respondent)" :key="item.id">
                {{ item.respondent_name }}
              </div>
            </div>
            <div v-else>
              <div>
                {{ item }}
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  components: {
    ProgressLinear,
  },
  data() {
    return {
      search_string: "",
      items_to_search: null,
      progress_dialog: false,
      dialog: false,
      headers: [
        {
          text: "Docket No.",
          value: "exclusive_case_no",
        },
        {
          text: "Complainant/s",
          value: "complainant",
        },
        {
          text: "Respondent/s",
          value: "respondent",
        },
        {
          text: "Current Table",
          value: "current_table",
        },
        {
          text: "Case Status",
          value: "case_status",
        },
        {
          text: "Case Profile",
          value: "view",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      complaints: "complaint/complaints",
    }),
    ...mapState("notification", ["search"]),
    items() {
      if (this.search_string == "") {
        return 0;
      } else {
        return this.complaints.filter((i) => {
          return i.name
            .toLowerCase()
            .includes(this.search_string.toLowerCase());
        });
      }
    },
  },
  methods: {
    ...mapActions({
      set_complaint: "complaint/set_complaint",
      set_search: "notification/set_search",
    }),
    close() {
      this.set_search(false);
    },
    async get_complaint(item) {
      this.progress_dialog = true;
      await this.get_search_item(item);
      this.$router.push("/complaintprofile/" + item.case_no);
      this.set_search(false);
      this.progress_dialog = false;
    },
    async view(item) {
      await this.set_complaint(item);
      await this.$router.push("/complaintprofile/" + item.case_no);
      this.set_search(false);
    },
  },
};
</script>
<style scoped>
.active {
  background-image: #1565c0;
}
.v-data-table ::v-deep td {
  font-size: 12px !important;
}
</style>
