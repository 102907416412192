<template>
  <v-container fluid>
    <v-row dense>
      <v-col class="pa-5">
        <span class="deep-purple--text accent-4 font-weight-bold headline">
          PRESCRIBED FORMS
        </span>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-card flat class="pa-4 ml-4">
          <p style="font-weight: bold">GENERATE FORMS</p>
          <v-row class="mt-4 pl-2">
            <v-col>
              <notice-to-constitute-lupon />
              <appointment />
              <notice-of-appointment />
              <list-of-appointed-lupon-members />
              <oath-of-office />
              <withdrawal-of-office />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import NoticeToConstituteLupon from "../components/Forms/PrescribedForms/NoticeToConstituteLupon.vue";
// import Appointment from "../components/Forms/PrescribedForms/Appointment.vue";
// import NoticeOfAppointment from "../components/Forms/PrescribedForms/NoticeOfAppointment.vue";
// import ListOfAppointedLuponMembers from "../components/Forms/PrescribedForms/ListOfAppointedLuponMembers.vue";
// import OathOfOffice from "../components/Forms/PrescribedForms/OathOfOffice.vue";
// import WithdrawalOfOffice from "../components/Forms/PrescribedForms/WithdrawalOfOffice.vue";
import { mapActions } from "vuex";
export default {
  components: {
    NoticeToConstituteLupon: () =>
      import("../components/Forms/PrescribedForms/NoticeToConstituteLupon.vue"),
    Appointment: () =>
      import("../components/Forms/PrescribedForms/Appointment.vue"),
    NoticeOfAppointment: () =>
      import("../components/Forms/PrescribedForms/NoticeOfAppointment.vue"),
    ListOfAppointedLuponMembers: () =>
      import(
        "../components/Forms/PrescribedForms/ListOfAppointedLuponMembers.vue"
      ),
    OathOfOffice: () =>
      import("../components/Forms/PrescribedForms/OathOfOffice.vue"),
    WithdrawalOfOffice: () =>
      import("../components/Forms/PrescribedForms/WithdrawalOfOffice.vue"),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      get_complaints: "complaint/get_complaints",

      get_lupons: "lupon/get_lupons",
      get_notifications: "notification/get_notifications",

      get_dashboard_summary: "complaint/get_dashboard_summary",
    }),
  },
  async created() {
    if (this.complaints == null) {
      this.progress_dialog = true;
      await this.get_complaints();
      await this.get_lupons();
      await this.get_notifications();
      await this.get_dashboard_summary();
      this.progress_dialog = false;
    }
  },
};
// NOTICE TO CONSTITUTE THE LUPON: {
//         lupons: [{ name: "" }],
//         date: {
//           day: "",
//           month: "",
//           year: "",
//         },
//       },
// APPOINTMENT: {
//         lupon_name: "",
//         address: "",
//         date: "",
//         secretary_name: "",
//       },
// NOTICE OF APPOINTMENT: {
//         lupon_name: "",
//         address: "",
//         date: "",
//         secretary_name: "",
//       },
// LIST OF APPOINTED LUPON MEMBERS:{
//         lupons: [{ name: "" }],
//         date: {
//           day: "",
//           month: "",
//           year: "",
//         },
//         seacretary_name: "",
//       },
// OATH OF OFFICE:{
//         lupon_name: "",
//         date: {
//           day: "",
//           month: "",
//           year: "",
//         },
//       },
// WITHDRAWAL OF OFFICE:{
//         lupon_name: "",
//         date: {
//           day: "",
//           month: "",
//           year: "",
//         },
//       },
</script>
