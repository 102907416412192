<template>
  <v-row dense align="center" justify="center">
    <v-col>
      <v-card>
        <v-card-title style="color: grey" class="subtitle-2">
          ARBITRATOR{{
            JSON.parse(complaint.arbitration.arbitrator).length > 1 ? "S" : ""
          }}:
        </v-card-title>

        <v-row class="d-flex justify-center">
          <v-col
            cols="3"
            v-for="(lupon, i) in JSON.parse(complaint.arbitration.arbitrator)"
            :key="i"
          >
            <span class="d-flex justify-center"
              ><v-img src="@/assets/profile.jpg" width="30"></v-img
            ></span>
            <span class="d-flex justify-center">
              {{ display_arbitrator_full_name(lupon) }}</span
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: { complaint: Object },
  data: () => ({
    add_conciliators_dialog: false,
    edit_conciliators_dialog: false,
  }),
  computed: {
    ...mapGetters({
      lupons: "lupon/lupons",
      user: "auth/user",
    }),
  },

  methods: {
    display_arbitrator_full_name(lupon_id) {
      let lupon = "";
      this.lupons.forEach((element) => {
        if (element.id == lupon_id) lupon = element.full_name;
      });
      return lupon;
    },
  },
  created() {
    // console.log(this.complaint);
  },
};
</script>

<style></style>
