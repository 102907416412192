<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Notice of Hearing Failure to Appear</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text text-uppercase">
            Notice of Hearing for Failure to Appear
          </h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <custom-date-picker
              @setDateTime="setDateData(['date_and_time_of_hearing', $event])"
              label="Date and Time of Hearing"
            />

            <br />

            <custom-date-picker
              @setDateTime="setDateData(['none_appearance', $event])"
              label="Date of None Appearance"
            />

            <v-radio-group v-model="complainant_or_respondent">
              <v-radio
                v-for="n in person"
                :key="n.id"
                :label="n.name"
                :value="
                  notice_of_hearing_failure_to_appear_link +
                  '_' +
                  n.name.toLowerCase()
                "
              ></v-radio>
            </v-radio-group>
            <br />
            <v-text-field
              dense
              outlined
              v-model="form.mediator"
              label="Mediator"
            ></v-text-field>
            <br />
            <v-text-field
              v-model="form.venue"
              class="body-2"
              label="Venue"
            ></v-text-field>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
import moment from "moment";
import CustomDatePicker from "../../CustomDatePicker.vue";
export default {
  components: { CustomDatePicker },
  data() {
    return {
      json_dialog: false,
      complainant_or_respondent: "",
      notice_of_hearing_failure_to_appear_link:
        "notice_of_hearing_failure_to_appear",
      person: [
        {
          id: "1",
          name: "Complainant",
        },
        {
          id: "2",
          name: "Respondent",
        },
      ],
      form: {
        date: "",
        non_appearance_date: "",
        mediator: "",
        venue: "Barangay Hall",
      },
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    input_other_data() {
      this.json_dialog = true;
    },

    setDateData([data2, data]) {
      if (data2 == "none_appearance") {
        this.form.non_appearance_date = data;
      } else {
        this.form.date = data;
      }
    },

    print() {
      // check if date are eempty
      if (this.form.date == "" || this.form.non_appearance_date == "")
        return alert("Please enter date first");
      let other_data = {
        date: [
          {
            hour: moment(this.form.date).format("h:mm"),
            month: moment(this.form.date).format("MMMM"),
            year: moment(this.form.date).format("YYYY"),
            day: moment(this.form.date).format("Do"),
            time_of_day: moment(this.form.date).format("a").toUpperCase(),
          },
        ],
        non_appearance_date: [
          {
            hour: moment(this.form.non_appearance_date).format("h:mm"),
            month: moment(this.form.non_appearance_date).format("MMMM"),
            year: moment(this.form.non_appearance_date).format("YYYY"),
            day: moment(this.form.non_appearance_date).format("Do"),
            time_of_day: moment(this.form.non_appearance_date)
              .format("a")
              .toUpperCase(),
          },
        ],
        mediator: [
          {
            mediator_name: this.form.mediator,
          },
        ],
        venue: this.form.venue,
      };
      window.open(
        this.print_url +
          this.user.id +
          "/" +
          this.complainant_or_respondent +
          "/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(other_data)
      );
    },
  },
  created() {
    this.form.mediator = this.user.name_of_punong_barangay;
  },
};
</script>
