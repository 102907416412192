<template>
  <div>
    <v-btn outlined color="deep-purple accent-4" @click="dialog = true"
      >Edit Info</v-btn
    >
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title>Edit Barangay Info</v-card-title>
        <v-card-text>
          <v-text-field
            label="Name of Punong Barangay"
            v-model="form.name_of_punong_barangay"
            prepend-icon="mdi-account"
          ></v-text-field>
          <v-text-field
            label="Account Name"
            v-model="form.name"
            prepend-icon="mdi-account"
          ></v-text-field>
          <v-select
            prepend-icon="mdi-chat-question"
            placeholder="Secret Question"
            :items="secret_questions"
            v-model="form.secret_question"
          ></v-select>
          <v-text-field
            label="Secret Answer"
            v-model="form.secret_answer"
            prepend-icon="mdi-security"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-lock-outline"
            placeholder="Password"
            :rules="confirmPasswordRules"
            v-model="form.password"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-lock-outline"
            placeholder="Confirm password"
            :rules="confirmPasswordRules"
            v-model="confirmPassword"
          ></v-text-field>
          <div v-if="!user.province">
            <address-picker @extract_address_value="extract_address" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="deep-purple accent-4"
            class="white--text"
            @click="submit"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import addressPicker from "../addressPicker.vue";
export default {
  components: { addressPicker },
  data() {
    return {
      confirmPassword: "",
      confirmPasswordRules: [
        (v) => !!v || "Password is required",
        (v) => v === this.form.password || "The password does not match.",
      ],
      secret_questions: [
        "What is your mother's maiden name?",
        "What is the name of your first pet?",
        "What is the name of the school you first enrolled?",
        "What is your nick name?",
      ],
      loading: false,
      dialog: false,
      form: {
        id: "",
        name: "",
        name_of_punong_barangay: "",
        province: "",
        city_or_municipality: "",
        barangay: "",
        password: "",
        secret_question: "",
        secret_answer: "",
      },
    };
  },
  created() {
    this.form.name_of_punong_barangay = this.user.name_of_punong_barangay;
    this.form.id = this.user.id;
    this.form.secret_question = this.user.secret_question;
    this.form.secret_answer = this.user.secret_answer;
    this.form.name = this.user.name;
    // if punong brgy is null
    if (!this.user.name_of_punong_barangay) {
      return (this.dialog = true);
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      update_barangay_info: "auth/update_barangay_info",
    }),
    extract_address(add) {
      this.form.province = add[0];
      this.form.city_or_municipality = add[1];
      this.form.barangay = add[2];
    },
    submit() {
      console.log(this.form);
      let x = window.confirm("Proceed with request?");
      if (x) {
        this.loading = true;

        this.update_barangay_info(this.form)
          .then(() => {
            this.loading = false;
            alert("Successfully updated barangay info!");
          })
          .catch((err) => {
            alert(err);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
