<template>
  <div>
    <v-btn
      depressed
      small
      color="green"
      class="ml-1 rounded-xl white--text"
      @click="dialog = true"
      ><v-icon class="mr-1" small>mdi-printer-outline</v-icon> Generate
      form</v-btn
    >
    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-5">
        <v-card-text>
          <div>
            <p style="font-weight: bold">GENERATE FORMS</p>
            <v-divider class="mb-5"></v-divider>
          </div>
          <div class="pl-2">
            <amicable-settlement />
            <motion-for-execution />
            <notice-of-execution />
            <notice-of-hearing />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AmicableSettlement from "./SettledFormComponents/AmicableSettlement.vue";
import MotionForExecution from "./SettledFormComponents/MotionForExecution.vue";
import NoticeOfExecution from "./SettledFormComponents/NoticeOfExecution.vue";
import NoticeOfHearing from "./SettledFormComponents/NoticeOfHearing.vue";
export default {
  components: {
    AmicableSettlement,
    MotionForExecution,
    NoticeOfExecution,
    NoticeOfHearing,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
    }),
  },
};
</script>
