<template>
  <div>
    <h4>{{ label }}</h4>
    <input type="date" v-model="date" />
    <input type="time" v-model="time" class="ml-2 mr-2" />
    <v-btn small :color="color" @click="update()">Set</v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["label"],
  data() {
    return {
      date: "",
      time: "",
      color: "yellow",
    };
  },

  computed: {},

  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),
    update() {
      if (this.date == "" || this.time == "") {
        return alert("Please check all fields");
      } else {
        // this.color = "grey";
        //emit actions whenever input is clicked
        let dateTime = this.date + " " + this.time;
        this.$emit("setDateTime", dateTime);

        const notification = {
          message: "Date successfully set.",
          color: "success",
          showing: true,
          icon: "mdi-check",
        };

        this.set_notification(notification);
      }
    },
  },
};
</script>

<style></style>
