<template>
  <v-container>
    <v-dialog v-model="new_complaint" width="500" persistent>
      <v-card class="pa-5">
        <v-card-text>
          <div>
            <p style="font-weight: bold">GENERATE FORMS</p>
            <v-divider class="mb-5"></v-divider>
          </div>
          <div class="pl-2">
            <div>
              <!-- <notice-of-hearing /> -->
              <v-row v-for="(x, i) in form_data" :key="i">
                <v-icon color="deep-purple accent-4" class="pr-2"
                  >mdi-printer-outline</v-icon
                >
                <v-btn
                  text
                  color="deep-purple accent-5"
                  dark
                  small
                  class="text-decoration-underline pl-0"
                  @click="print_form(x.form_name, x.other_data)"
                  >{{ x.title }}</v-btn
                >
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="mb-n5 mr-n7"
            text
            small
            width="70px"
            color="red"
            dark
            @click="negate_new_complaint(false)"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text">
            Please enter required information
          </h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <v-text-field
              v-model="form.venue"
              class="body-2"
              label="Venue"
            ></v-text-field>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print(form_name)"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 
    <v-dialog v-model="venue_modal" width="300">
      <v-card>
        <v-card-title> Notice of Hearing </v-card-title>
        <v-card-text>
          <v-text-field
            label="Venue"
            v-model="form_data[1].other_data.venue"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="print" color="primary">Print</v-btn>
          <v-btn @click="venue_modal = false">Close</v-btn>
        </v-card-actions></v-card
      ></v-dialog
    > -->
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
// import NoticeOfHearing from "../Forms/MediationFormComponents/NoticeOfHearing.vue";

export default {
  components: {},
  data() {
    return {
      venue_modal: false,
      form: {
        venue: "Barangay Hall",
      },
      form_name: "",
      other_data: {},
      json_dialog: false,
      form_data: [
        {
          id: "1",
          title: "Complaint",
          form_name: "complaint",
          case_status: "Ongoing",
          other_data: {},
        },
        {
          id: "2",
          title: "Notice of Hearing",
          form_name: "notice_of_hearing",
          case_status: "Ongoing",
          other_data: {},
        },

        {
          id: "3",
          title: "Summons",
          form_name: "summon",
          case_status: "Ongoing",
          other_data: {
            venue: "",
          },
        },
        {
          id: "4",
          title: "Officer's Return",
          form_name: "officers_return",
          case_status: "Ongoing",
          other_data: {},
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      new_complaint: "complaint/new_complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    negate_new_complaint(item) {
      this.$store.commit("complaint/NEGATE_NEW_COMPLAINT", item);
    },
    print_form(form_name, other_data) {
      this.form_name = form_name;
      if (form_name == "notice_of_hearing") {
        this.venue_modal = true;
      }
      if (form_name == "summon") this.other_data = this.form;
      if (Object.keys(other_data).length === 0) this.print(form_name);
      else this.json_dialog = true;
    },
    print(form_name) {
      window.open(
        this.print_url +
          +this.user.id +
          "/" +
          form_name +
          "/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(this.other_data)
      );
    },
  },
};
</script>
