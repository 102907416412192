<template>
  <v-card
    class="pa-4 d-flex justify-center"
    max-width="1200"
    flat
    max-height="320"
  >
    <v-card-text>
      <h2 class="text-center mb-2">{{ this.id_name }}</h2>
      <canvas :id="this.id_name"></canvas>
      <p class="text-center caption mt-5">
        <v-icon small class="mr-">mdi-information</v-icon>
        This graph shows the number of different types of cases for the last 12
        months
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "chart.js";

export default {
  props: {
    id_name: String,
  },

  data: () => ({
    data: null,
    chart_data: null,
  }),
  computed: {
    ...mapGetters({
      linear_scale_data: "complaint/linear_scale_data",
    }),
  },
  beforeMount() {
    this.data = {
      labels: this.linear_scale_data.months,
      datasets: [
        {
          label: "Criminal Case",
          data: this.linear_scale_data.criminal_case,
          borderColor: "RGB(244, 66, 54)",
        },
        {
          label: "Civil Case",
          data: this.linear_scale_data.civil_case,
          borderColor: "RGB(33, 150, 243)",
        },
        // {
        //   label: "Other Case",
        //   data: [1, 32, 18, 19, 15, 5, 20, 10, 25, 39, 10, 22],
        //   borderColor: "RGB(234, 30, 99)",
        // },
      ],
    };

    this.chart_data = {
      type: "line",
      data: this.data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Suggested Min and Max Settings",
          },
        },
        scales: {
          y: {
            // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
            suggestedMin: 30,

            // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
            suggestedMax: 50,
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById(this.id_name);
    new Chart(ctx, this.chart_data);
  },
};
</script>

<style></style>
