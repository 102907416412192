<template>
  <div>
    <v-btn
      depressed
      small
      color="green"
      class="ml-1 rounded-xl white--text"
      @click="dialog = true"
      ><v-icon class="mr-1" small>mdi-printer-outline</v-icon> Generate
      form</v-btn
    >
    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-5">
        <v-card-text>
          <div>
            <p style="font-weight: bold">GENERATE FORMS</p>
            <v-divider class="mb-5"></v-divider>
          </div>
          <div class="pl-2">
            <certificate-to-bar-action
              v-if="complaint.unsettled.case_status == 'Unsettled'"
            />
            <certificate-to-bar-counterclaim
              v-if="complaint.unsettled.case_status == 'Unsettled'"
            />
            <certificate-to-file-action
              v-if="
                complaint.unsettled.case_status == 'Repudiated' ||
                complaint.unsettled.case_status == 'Dismissed' ||
                complaint.unsettled.case_status == 'Unsettled'
              "
            />
            <repudiation
              v-if="complaint.unsettled.case_status == 'Repudiated'"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CertificateToBarAction from "./UnsettledFormComponents/CertificateToBarAction.vue";
import CertificateToBarCounterclaim from "./UnsettledFormComponents/CertificateToBarCounterclaim.vue";
import CertificateToFileAction from "./UnsettledFormComponents/CertificateToFileAction.vue";
import Repudiation from "./UnsettledFormComponents/Repudiation.vue";
export default {
  components: {
    CertificateToBarAction,
    CertificateToBarCounterclaim,
    CertificateToFileAction,
    Repudiation,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
    }),
  },
};
</script>
