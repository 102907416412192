<template>
  <div>
    <v-app-bar
      elevation="4"
      app
      height="50px"
      flat
      class="top_bar"
      color="deep-purple accent-4"
      dark
      v-if="user"
    >
      <v-icon>mdi-scale-balance</v-icon>
      <span class="caption ml-4 font-weight"
        >KATARUNGANG PAMBARANGAY CASE MANAGEMENT SYSTEM</span
      >
      <v-spacer></v-spacer>
      <v-btn right icon @click="open_search_bar">
        <v-icon size="25" class="m-3">mdi-magnify</v-icon></v-btn
      >
      <div class="text-center" v-if="complaints">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn right icon v-bind="attrs" v-on="on">
              <v-badge
                v-if="complaints"
                :content="notifications.length"
                :value="notifications.length"
                color="red"
                overlap
              >
                <v-icon size="25" class="m-3">mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-card v-if="notifications.length >= 1">
            <v-list dense>
              <v-subheader dark class="title pl-4 red mt-n2"
                >NOTIFICATIONS</v-subheader
              >
              <v-divider></v-divider>

              <v-list-item
                dense
                v-for="(notification, i) in notifications"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item class="subtitle-2">
                    {{ notification.message }}
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      right
                      @click="open_notification(notification.exclusive_case_no)"
                      text
                      color="deep-purple accent-4"
                      >view</v-btn
                    >
                    <v-btn
                      small
                      right
                      @click="remove_notification(notification.id)"
                      text
                      color="red"
                      >remove</v-btn
                    > </v-list-item
                  ><v-divider></v-divider>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
      <div class="d-flex mt-4">
        <p class="mr-2">{{ language }}</p>
        <v-switch
          @change="switch_language()"
          v-model="enable_bisaya"
          dense
          class="caption"
          hide-details=""
        >
        </v-switch>
      </div>

      <span class="overline ml-1">{{ user.username }}</span>
      <v-icon size="20" class="ml-3" @click="logout">mdi-logout-variant</v-icon>
    </v-app-bar>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import moment from "moment";
import ProgressLinear from "@/components/ProgressLinear.vue";
// import notifications from "../components/DataPath/notify";

export default {
  components: {
    ProgressLinear,
  },
  data() {
    return {
      enable_bisaya: false,
      menu: false,
      fav: true,
      notification_length: 0,
      progress_dialog: false,
      loading: false,
      drawer: false,
    };
  },
  props: { page_name: String },
  computed: {
    ...mapGetters({
      user: "auth/user",
      complaints: "complaint/complaints",
      mediations: "complaint/mediations",
      conciliations: "complaint/conciliations",
      arbitrations: "complaint/arbitrations",
      notifications: "notification/notifications",
      language: "auth/language",
    }),
  },

  methods: {
    switch_language() {
      this.language == "English"
        ? this.set_language("Cebuano")
        : this.set_language("English");
    },
    ...mapActions({
      set_language: "auth/set_language",
      signout: "auth/signout",
      open_search: "notification/set_search",
      get_search_item: "complaint/get_search_item",
      get_notification_item: "complaint/get_notification_item",
      set_notification: "notification/set_notification",
      read_notification: "notification/read_notification",
      set_complaint: "complaint/set_complaint",
    }),
    open_search_bar() {
      this.open_search(true);
    },
    logout() {
      this.loading = true;
      this.signout().then(() => {
        this.loading = false;
        this.$router.push("/signin");
      });
    },
    async remove_notification(id) {
      this.progress_dialog = true;
      await this.read_notification(id);
      this.menu = false;
      this.progress_dialog = false;
    },
    async open_notification(exclusive_case_no) {
      this.progress_dialog = true;
      var complaint = this.complaints.find((m) => {
        return (m.exclusive_case_no = exclusive_case_no);
      });
      await this.set_complaint(complaint);
      this.$router.push("/complaintprofile/" + complaint.exclusive_case_no);
      this.menu = false;
      this.progress_dialog = false;
    },
    async view(item, id) {
      console.log(id);
      if (item == null) {
        this.progress_dialog = true;
        await this.read_notification(id);
        this.progress_dialog = false;
        this.set_notification({
          message: "No profile!",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
      } else {
        this.progress_dialog = true;
        await this.read_notification(id);
        await this.get_notification_item(item);
        this.$router.push("/complaintprofile/" + item);
        this.progress_dialog = false;
      }
    },
  },
};
</script>

<style scoped></style>
