<template>
  <v-row>
    <v-icon color="deep-purple accent-4" class="pr-2"
      >mdi-printer-outline</v-icon
    >
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="print()"
      >Amicable Settlement</v-btn
    >
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
// import print_url from "../../DataPath/print_url";
export default {
  data() {
    return {
      other_data: {},
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
      print_url: "auth/default_url",
      language: "auth/language",
    }),
  },
  methods: {
    print() {
      window.open(
        this.print_url +
          this.user.id +
          "/amicable_settlement/" +
          this.complaint.id +
          "/" +
          this.language +
          "/" +
          this.complaint.current_table +
          "/" +
          JSON.stringify(this.other_data)
      );
    },
  },
};
</script>
