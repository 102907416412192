import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.js";
import notification from "./notification.js";
import action from "./action.js";
import admin from "./admin.js";
import complaint from "./complaint.js";
// import mediation from "./mediation.js";
// import conciliation from "./conciliation.js";
// import arbitration from "./arbitration.js";
// import execution from "./execution";
import lupon from "./lupon";
// import unsettled from "./unsettled";
import statistics from "./statistics";
import history from "./history";
import backup from "./backup";
import bell_notification from "./bell_notification.js";
// import subscriber from "./subscriber.js";
// import questions from "./questions.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    notification,
    action,
    admin,
    complaint,
    // mediation,
    // conciliation,
    // arbitration,
    // execution,
    lupon,
    // unsettled,
    statistics,
    history,
    backup,
    bell_notification,
    // subscriber
    // questions
  },
});
