import axios from "axios";
export default {
  namespaced: true,
  state: {
    report_data: null,
    mediation_ongoing: null,
    mediation_settled: null,
    mediation_unsettled: null,
    conciliation_ongoing: null,
    conciliation_settled: null,
    conciliation_unsettled: null,
    arbitration_ongoing: null,
    arbitration_settled: null,
    arbitration_unsettled: null,
    unsettleds: null,
    settleds: null,
    dashboardData: null,
    repudiated: null,
    withdrawn: null,
    dismissed: null,
  },
  getters: {
    report(state) {
      return state.report_data;
    },
    mediation_ongoing(state) {
      return state.mediation_ongoing;
    },
    mediation_settled(state) {
      return state.mediation_settled;
    },
    mediation_unsettled(state) {
      return state.mediation_unsettled;
    },
    conciliation_ongoing(state) {
      return state.conciliation_ongoing;
    },
    conciliation_settled(state) {
      return state.conciliation_settled;
    },
    conciliation_unsettled(state) {
      return state.conciliation_unsettled;
    },
    arbitration_ongoing(state) {
      return state.arbitration_ongoing;
    },
    arbitration_settled(state) {
      return state.arbitration_settled;
    },
    arbitration_unsettled(state) {
      return state.arbitration_unsettled;
    },
    unsettleds(state) {
      return state.unsettleds;
    },
    settleds(state) {
      return state.settleds;
    },
    repudiated(state) {
      return state.repudiated;
    },
    withdrawn(state) {
      return state.withdrawn;
    },
    dismissed(state) {
      return state.dismissed;
    },
    dashboardData(state) {
      return (state.dashboardData = {
        type: "bar",
        data: {
          labels: ["Ongoing Settlement", "Settled Cases", "Unsettled Cases"],
          datasets: [
            {
              label: "Mediation",
              data: [
                state.mediation_ongoing,
                state.mediation_settled,
                state.mediation_unsettled,
              ],
              backgroundColor: "#263238",
            },
            {
              label: "Conciliation",
              data: [
                state.conciliation_ongoing,
                state.conciliation_settled,
                state.conciliation_unsettled,
              ],
              backgroundColor: "#6200EA",
            },
            {
              label: "Arbitration",
              data: [
                state.arbitration_ongoing,
                state.arbitration_settled,
                state.arbitration_unsettled,
              ],
              backgroundColor: "#EDE7F6",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      });
    },
  },
  mutations: {
    SET_REPORT(state, report) {
      state.report_data = report;
    },
    SET_MONTHLY_REPORT(state, report) {
      state.report_data = report;
    },
    SET_MEDIATIONS(state, mediations) {
      // console.log(mediations);
      state.mediation_ongoing = mediations.filter((m) => {
        return m.case_status == "Ongoing";
      });
      state.mediation_settled = mediations.filter((m) => {
        return m.case_status == "Settled";
      });
      state.mediation_unsettled = mediations.filter((m) => {
        return m.case_status == "Unsettled";
      });
    },
    SET_CONCILIATIONS(state, conciliations) {
      state.conciliation_ongoing = conciliations.filter((m) => {
        return m.case_status == "Ongoing";
      });
      state.conciliation_settled = conciliations.filter((m) => {
        return m.case_status == "Settled";
      });
      state.conciliation_unsettled = conciliations.filter((m) => {
        return m.case_status == "Unsettled";
      });
    },
    SET_ARBITRATIONS(state, arbitrations) {
      state.arbitration_ongoing = arbitrations.filter((m) => {
        return m.case_status == "Ongoing";
      });
      state.arbitration_settled = arbitrations.filter((m) => {
        return m.case_status == "Settled";
      });
      state.arbitration_unsettled = arbitrations.filter((m) => {
        return m.case_status == "Unsettled";
      });
    },
    SET_UNSETTLEDS(state, unsettleds) {
      state.unsettleds = unsettleds;
      state.repudiated = unsettleds.filter((m) => {
        return m.case_status == "Repudiated";
      });
      state.withdrawn = unsettleds.filter((m) => {
        return m.case_status == "Withdrawn";
      });
      state.dismissed = unsettleds.filter((m) => {
        return m.case_status == "Dismissed";
      });
    },
    SET_SETTLEDS(state, settleds) {
      state.settleds = settleds;
    },
    SET_REPUDIATED(state, repudiated) {
      state.repudiated = repudiated;
    },
    SET_WITHDRAWN(state, withdrawn) {
      state.withdrawn = withdrawn;
    },
    SET_DISMISSED(state, dismissed) {
      state.dismissed = dismissed;
    },
    SET_DASHBOARD(state) {
      state.dashboardData = {
        type: "bar",
        data: {
          labels: ["Ongoing Settlement", "Settled Cases", "Unsettled Cases"],
          datasets: [
            {
              label: "Mediation",
              data: [
                state.mediation_ongoing,
                state.mediation_settled,
                state.mediation_unsettled,
              ],
              backgroundColor: "#263238",
            },
            {
              label: "Conciliation",
              data: [
                state.conciliation_ongoing,
                state.conciliation_settled,
                state.conciliation_unsettled,
              ],
              backgroundColor: "#6200EA",
            },
            {
              label: "Arbitration",
              data: [
                state.arbitration_ongoing,
                state.arbitration_settled,
                state.arbitration_unsettled,
              ],
              backgroundColor: "#EDE7F6",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      };
    },
  },
  actions: {
    async get_report_data({ commit }, form) {
      let response = await axios.post("/report_data", form);
      console.log(response.data);
      commit("SET_REPORT", response.data);
    },
    async get_monthly_report_data({ commit }, request) {
      let response = await axios.get("/monthly_report_data/" + request.year + '/' + request.month);
      console.log(response.data);
      commit("SET_MONTHLY_REPORT", response.data);
    },
    async get_mediations({ commit }) {
      let response = await axios.get("/all_mediations");
      commit("SET_MEDIATIONS", response.data);
    },
    async get_conciliations({ commit }) {
      let response = await axios.get("/all_conciliations");
      commit("SET_CONCILIATIONS", response.data);
    },
    async get_arbitrations({ commit }) {
      let response = await axios.get("/all_arbitrations");
      commit("SET_ARBITRATIONS", response.data);
    },
    async get_unsettleds({ commit }) {
      let response = await axios.get("/all_unsettleds");
      commit("SET_UNSETTLEDS", response.data);
    },
    async get_executions({ commit }) {
      let response = await axios.get("/all_executions");
      commit("SET_SETTLEDS", response.data);
    },
    async set_dashboard({ commit }) {
      commit("SET_DASHBOARD");
    },
  },
};
