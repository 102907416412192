<template>
  <v-card
    width="200"
    class="pa-4 mr-2 ml-2"
    elevation="2"
    @click="dialog = true"
  >
    <v-btn
      fab
      :color="outline_color"
      depressed
      small
      class="justify-right"
      x-large
    >
      <v-icon class="" :color="color">{{ icon }}</v-icon>
    </v-btn>
    <v-row class="mb-n14 d-flex pa-3">
      <p class="black--text lighten-2 subtitle-2 justify-left">{{ title }}</p>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="mb-n8">
      <v-col
        ><p class="font-weight-bold grey--text" style="font-size: 36px">
          {{ data[1] }}
        </p></v-col
      >
    </v-row>
    <v-dialog v-model="dialog" width="1000" persistent>
      <v-card class="pa-4">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
          <v-data-table :items="data[0]" :headers="headers">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                icon
                @click="complaint_profile(item)"
                color="deep-purple accent-4"
                ><v-icon>mdi-list-box</v-icon></v-btn
              >
            </template>

            <template v-slot:[`item.complainant`]="{ item }">
              <div v-if="item.complainant != null">
                <div
                  v-for="item in JSON.parse(item.complainant)"
                  :key="item.id"
                >
                  <div class="col" style="">{{ item.complainant_name }}</div>
                </div>
              </div>
            </template>

            <template v-slot:[`item.respondent`]="{ item }">
              <div v-if="item.respondent != null">
                <div v-for="item in JSON.parse(item.respondent)" :key="item.id">
                  <div class="col" style="">{{ item.respondent_name }}</div>
                </div>
              </div>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <div>
                {{ format_date(item.created_at) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      dialog: false,
      headers: [
        { text: "", value: "action" },
        { text: "Case Number", value: "exclusive_case_no" },
        { text: "Case Title", value: "case_title", width: "300" },
        { text: "Complainant", value: "complainant" },
        { text: "Respondent", value: "respondent" },
        { text: "Filed on", value: "created_at" },
      ],
    };
  },
  mounted() {},
  props: {
    color: String,
    outline_color: String,
    title: String,
    icon: String,
    data: Array,
  },
  computed: {
    date_today() {
      var date = moment().format("MMM Do Y");
      return date;
    },
  },
  methods: {
    ...mapActions({
      set_complaint: "complaint/set_complaint",
    }),
    complaint_profile(item) {
      this.set_complaint(item).then(() => {
        this.$router.push("/complaintprofile/" + item.exclusive_case_no);
      });
    },
    format_date(date) {
      var formatted_date = moment(date).format("MMM D, Y");
      return formatted_date;
    },
  },
};
</script>

<style scoped>
.card_icon {
  transform: translateX();
}
.reload_row {
  transform: translateY(-40px);
  max-height: 20px;
}
</style>
