import axios from "axios";
export default {
  namespaced: true,
  state: {
    new_complaint: false,
    complaints: null,
    complaint: null,
    edit_item: null,
    pre_file_complaint: null,
    dashboard_summary: null,
    linear_scale_data: null,
  },
  getters: {
    new_complaint(state) {
      return state.new_complaint;
    },
    pre_file_complaint(state) {
      return state.pre_file_complaint;
    },
    linear_scale_data(state) {
      return state.linear_scale_data;
    },
    dashboard_summary(state) {
      return state.dashboard_summary;
    },
    complaints(state) {
      return state.complaints;
    },

    complaint(state) {
      return state.complaint;
    },
    edit_item(state) {
      return state.edit_item;
    },
    color_codes(state) {
      return state.color_codes;
    },
  },
  mutations: {
    SET_PRE_FILE_COMPLAINT(state, complaint) {
      state.pre_file_complaint = complaint;
    },
    SET_DASHBOARD_SUMMARY(state, total) {
      state.dashboard_summary = total;
      state.linear_scale_data = {
        months: total.months_data,
        criminal_case: total.criminal_case_data,
        civil_case: total.civil_case_data,
      };
    },
    SET_COMPLAINTS(state, complaints) {
      state.complaints = complaints;
    },
    SET_COMPLAINT(state, complaint) {
      state.complaint = complaint;
    },
    SET_EDIT_ITEM(state, complaint) {
      state.edit_item = complaint;
    },
    ADD_COMPLAINT(state, complaint) {
      if (complaint.referred_to_concerned_agency != null) {
        state.complaints.unshift(complaint);
        return;
      } else state.new_complaint = true;
      state.complaints.unshift(complaint);
      state.complaint = complaint;
    },
    DELETE_COMPLAINT(state, complaint) {
      let filtered = state.complaints.filter((m) => m.id != complaint.id);
      state.complaints = filtered;
    },
    EDIT_COMPLAINT(state, complaint) {
      let filtered = state.complaints.filter((m) => {
        return m.id != complaint.id;
      });
      filtered.unshift(complaint);
      state.complaints = filtered;
    },
    CHANGE_SETTLED_STATUS(state, complaint) {
      state.complaint = complaint;
      state.complaints = state.complaints.filter((m) => {
        return m.id != complaint.id;
      });
      state.complaints.unshift(complaint);
    },
    CHANGE_STATUS(state, complaint) {
      state.complaint = complaint;
      let filtered = state.complaints.filter((m) => {
        return m.id != complaint.id;
      });
      filtered.unshift(complaint);
      state.complaints = filtered;
    },
    ADD_CONCILIATORS(state, complaint) {
      state.complaint = complaint;
      let filtered = state.complaints.filter((c) => {
        return c.id != complaint.id;
      });
      filtered.push(complaint);
      state.complaints = filtered;
    },
    ADD_PERIOD_OF_HEARING(state, complaint) {
      state.complaint = complaint;
      let filtered = state.complaints.filter((c) => {
        return c.id != complaint.id;
      });
      filtered.push(complaint);
      state.complaints = filtered;
    },
    NEGATE_NEW_COMPLAINT(state, request) {
      state.new_complaint = request;
    },
    ADD_AGREEMENT(state, complaint) {
      let filtered = state.complaints.filter((m) => {
        return m.id != complaint.id;
      });
      state.complaints = filtered;
      state.complaints.unshift(complaint);
      state.complaint = complaint;
    },
    UNDO_COMPLAINT(state, complaint) {
      let filtered = state.complaints.filter((m) => {
        return m.id != complaint.id;
      });
      state.complaints = filtered;
      state.complaints.unshift(complaint);
    },
    REFERRED(_, res) {
      console.log(res);
    },
  },
  actions: {
    async set_complaint({ commit }, complaint) {
      commit("SET_COMPLAINT", complaint);
    },
    add_pre_file_complaint({ commit }, complaint) {
      commit("SET_PRE_FILE_COMPLAINT", complaint);
    },
    async get_dashboard_summary({ commit }) {
      let response = await axios.get("/summary");
      commit("SET_DASHBOARD_SUMMARY", response.data);
    },
    async edit_complaint({ commit }, complaint) {
      let response = await axios.post("/edit_complaint", complaint);
      commit("EDIT_COMPLAINT", response.data);
      console.log(response.data);
    },
    async edit_conciliation_lupons({ commit }, item) {
      let response = await axios.put("/edit_lupon_members", item);
      response.data.table_name = item.table_name;
      commit("SET_COMPLAINT", response.data);
      // console.log(response.data);
    },
    async edit_arbitration_lupons({ commit }, item) {
      let response = await axios.put("/edit_lupon", item);
      commit("SET_COMPLAINT", response.data);
      //console.log(response.data);
    },
    async get_complaint({ commit }, complaint_id) {
      let response = await axios.get("/complaint/" + complaint_id);
      commit("SET_COMPLAINT", response.data);
    },
    async delete_complaint({ commit }, complaint) {
      await axios.delete("/complaint/" + complaint.id);
      commit("DELETE_COMPLAINT", complaint);
    },

    async add_complaint({ commit, dispatch }, request) {
      let response = await axios.post("/complaint", request);
      console.log(response.data);
      var history = {
        complaint_id: response.data.id,
        exclusive_case_no: response.data.exclusive_case_no,
        action: "Filed",
        from_table: "Complaint",
      };
      dispatch("history/add_case_history", history, { root: true });

      commit("ADD_COMPLAINT", response.data);
    },
    async referred_to_concerned_agency({ commit }, agency) {
      let response = await axios.post("/referred/" + agency);
      commit("REFERRED", response.data);
    },
    // get complaints
    async get_complaints({ commit }) {
      let response = await axios.get("/complaints");
      commit("SET_COMPLAINTS", response.data);
    },
    async get_notification_item({ commit }, item) {
      let response = await axios.get("/getcomplaint/" + item);
      commit("SET_COMPLAINT", response.data);
    },
    async change_status({ dispatch, commit }, request) {
      let response = await axios.post(
        "/change_status/" +
          request.complaint_id +
          "/" +
          request.table_name +
          "/" +
          request.exclusive_case_no
      );
      var from_table = JSON.parse(response.data.table_route_history);
      var to_table = JSON.parse(response.data.table_route_history);
      var history = {
        complaint_id: response.data.id,
        exclusive_case_no: response.data.exclusive_case_no,
        action: "Referred to " + to_table[from_table.length - 1],
        from_table: to_table[from_table.length - 1],
      };
      dispatch("history/add_case_history", history, { root: true });
      commit("CHANGE_STATUS", response.data);
    },
    async add_agreement({ commit }, request) {
      let response = await axios.post("/add_agreement", request);
      commit("ADD_AGREEMENT", response.data);
    },
    async add_conciliators({ commit, dispatch }, request) {
      let response = await axios.put("/add_conciliators", request);
      commit("ADD_CONCILIATORS", response.data);
      // upon adding a lupon to the complaint, dapat ma kuha utro an complaint
      // ky jaoi lupon.lupons naka relation sa complaint model para makuha an registered lupons
      // need eni sija para sa notice of hearing sa conciliation makuha an pangkat chair
      dispatch("complaint/get_complaint", request.complaint_id, { root: true });
    },
    async edit_conciliators({ commit, dispatch }, request) {
      let response = await axios.put("/add_conciliators", request);
      commit("ADD_CONCILIATORS", response.data);
      // upon adding a lupon to the complaint, dapat ma kuha utro an complaint
      // ky jaoi lupon.lupons naka relation sa complaint model para makuha an registered lupons
      // need eni sija para sa notice of hearing sa conciliation makuha an pangkat chair
      dispatch("complaint/get_complaint", request.complaint_id, { root: true });
    },
    async add_period_of_hearing({ commit }, request) {
      let response = await axios.put("/add_period_of_hearing", request);
      commit("ADD_PERIOD_OF_HEARING", response.data);
    },
    async undo_complaint({ commit, dispatch }, complaint) {
      let response = await axios.post("/undo_complaint", complaint);
      var history = {
        complaint_id: response.data.id,
        exclusive_case_no: response.data.exclusive_case_no,
        action:
          "Referred back to " +
          response.data.current_table +
          " from " +
          complaint.current_table,
        from_table: response.data.current_table,
      };
      dispatch("history/add_case_history", history, { root: true });
      commit("DELETE_COMPLAINT", complaint);
      commit("UNDO_COMPLAINT", response.data);
    },
    async change_settled_status({ commit }, request) {
      let response = await axios.post("/change_settled_status", request);
      commit("CHANGE_SETTLED_STATUS", response.data);
    },
    set_edit_item({ commit }, complaint) {
      complaint.complainant = JSON.parse(complaint.complainant);
      complaint.respondent = JSON.parse(complaint.respondent);
      commit("SET_EDIT_ITEM", complaint);
    },
    // add notice for consitution of pangkat date in concilations table
    async add_constitution_of_pangkat_date({ commit }, request) {
      let response = await axios.get(
        "/add_constitution_of_pangkat_date/" +
          request.complaint_id +
          "/" +
          request.date
      );
      commit("SET_COMPLAINT", response.data);
    },
  },
};
