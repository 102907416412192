<template>
  <div v-if="complaint.current_table == 'Mediation'">
    <v-btn
      color="deep-purple accent-4"
      depressed
      small
      class="rounded-xl white--text"
      >{{ complaint.current_table }}</v-btn
    >
  </div>
  <div v-else-if="complaint.current_table == 'Conciliation'">
    <v-btn depressed color="orange" small class="rounded-xl white--text">{{
      complaint.current_table
    }}</v-btn>
  </div>
  <div v-else-if="complaint.current_table == 'Arbitration'">
    <v-btn depressed color="pink" small class="rounded-xl white--text">{{
      complaint.current_table
    }}</v-btn>
  </div>
  <div v-else-if="complaint.current_table == 'Execution'">
    <v-btn depressed color="blue darken-4" small class="rounded-xl white--text"
      >Execution</v-btn
    >
  </div>
  <div v-else-if="complaint.current_table == 'Unsettled'">
    <v-btn depressed color="red" small class="rounded-xl white--text">{{
      complaint.current_table
    }}</v-btn>
  </div>
</template>

<script>
export default {
  props: { complaint: Object },
};
</script>

<style></style>
