<template>
  <v-container fluid>
    <v-row dense>
      <v-col class="pa-5">
        <span class="deep-purple--text accent-4 font-weight-bold headline">
          CASES FOR ARBITRATION
        </span>
      </v-col>
      <v-col class="col-3 grey--text mt-5">
        <v-icon small>mdi-account-multiple</v-icon>
        Total Number of Cases:
        <span v-if="arbitrations"
          ><v-chip color="pink" class="white--text ml-2">{{
            arbitrations.length
          }}</v-chip></span
        ></v-col
      >
      <v-col class="col-3 grey--text mt-5">
        <v-icon small>mdi-calendar</v-icon>
        As of:
        <v-chip class="ml-2 white--text" color="grey"> {{ date_today }}</v-chip>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-card class="mb-15">
          <arbitration-table />
        </v-card>
      </v-col>
    </v-row>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import ArbitrationTable from "../components/ArbitrationManagement/ArbitrationTable.vue";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  components: {
    ArbitrationTable,
    ProgressLinear,
  },
  data() {
    return {
      progress_dialog: false,
    };
  },
  computed: {
    date_today() {
      var date = new Date();
      date = moment(date).format("MMM D, YYYY");
      return date;
    },
    ...mapGetters({
      // arbitrations: "complaint/arbitrations",
      complaints: "complaint/complaints",
    }),
    arbitrations() {
      if (this.complaints == null) return [];
      else {
        let filtered = this.complaints.filter((m) => {
          return m.current_table == "Arbitration";
        });
        return filtered;
      }
    },
  },
  methods: {
    ...mapActions({
      get_complaints: "complaint/get_complaints",
      get_lupons: "lupon/get_lupons",
      get_notifications: "notification/get_notifications",
      get_dashboard_summary: "complaint/get_dashboard_summary",
    }),
  },
  async created() {
    if (this.complaints == null) {
      this.progress_dialog = true;
      await this.get_complaints();
      await this.get_lupons();
      await this.get_notifications();
      await this.get_dashboard_summary();
      this.progress_dialog = false;
    }
  },
};
</script>
