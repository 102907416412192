<template>
  <v-container>
    <v-tooltip top color="deep-purple accent-4">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-10"
          :to="'/filingofcomplaint'"
          top
          absolute
          right
          fab
          elevation="2"
          color="deep-purple accent-4"
          dark
          v-on="on"
          v-bind="attrs"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </template>
      <span>Click to file a complaint.</span>
    </v-tooltip>
  </v-container>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      snackbar: false,
    };
  },
  methods: {
    async download() {
      window.open("http://127.0.0.1:8000/download_backup");
      this.snackbar = false;
      return;
    },
  },
};
</script>
