<template>
  <v-container fluid>
    <v-row class="pa-5">
      <v-col cols="4">
        <v-row>
          <v-col v-if="user.seal_url">
            <v-avatar size="250">
              <img :src="user.seal_url" />
            </v-avatar>
          </v-col>

          <v-col class="d-flex justify-center" v-else>
            <v-avatar size="250">
              <img src="../assets/default_icon.png" />
            </v-avatar>
          </v-col>
        </v-row>

        <div class="d-flex mt-5">
          <input
            class="black--text"
            type="file"
            ref="photo_upload"
            name="photo_upload"
            @change="insert_image"
            accept="image/jpeg"
          />
          <br />
          <v-btn
            small
            class="mt-2"
            color=""
            @click="upload_image"
            :loading="uploading"
          >
            Upload Picture</v-btn
          >
        </div>
      </v-col>
      <v-col>
        <div class="pa-4">
          <div class="d-flex">
            <h1
              class="deep-purple--text accent-4 font-weight-bold headline mb-2"
            >
              Barangay Information
            </h1>
            <v-spacer></v-spacer>
            <edit-barangay-modal />
          </div>

          <v-divider></v-divider>
          <div class="mt-2">
            <v-row>
              <v-col cols="4">
                <h4 class="grey--text">Name of Punong Barangay:</h4>
                <h4 class="grey--text">Province:</h4>
                <h4 class="grey--text">City/Municipality:</h4>
                <h4 class="grey--text">Barangay:</h4></v-col
              >
              <v-col
                ><h4>{{ user.name_of_punong_barangay }}</h4>
                <h4>{{ user.province }}</h4>
                <h4>{{ user.city_or_municipality }}</h4>
                <h4>{{ user.barangay }}</h4></v-col
              >
            </v-row>
          </div>
        </div>

        <div class="pa-4 mt-1">
          <div class="d-flex">
            <h1
              class="deep-purple--text accent-4 font-weight-bold headline mb-2"
            >
              Account Information
            </h1>
            <v-spacer></v-spacer>
          </div>

          <v-divider></v-divider>
          <div class="mt-2">
            <v-row>
              <v-col cols="4">
                <h4 class="grey--text">Account Name:</h4>
                <h4 class="grey--text">Secret Question:</h4>
                <h4 class="grey--text">Secret Answer:</h4>
              </v-col>
              <v-col
                ><h4>{{ user.name }}</h4>
                <h4>{{ user.secret_question }}</h4>
                <h4>{{ user.secret_answer }}</h4>
              </v-col>
            </v-row>
          </div>
          <br />
          <v-divider></v-divider>
          <div class="mt-2 d-flex">
            <p class="caption">
              <strong>USER CREATION DATE:</strong>
              {{ format_date(user.created_at) }}
            </p>

            <v-spacer></v-spacer>
            <p class="caption"><STRONG>LICENSE EXPIRATION DATE:</STRONG></p>
          </div>
          <div>
            <p class="caption mt-n4">
              <Strong>GET HELP</Strong>: support@kpcms.online
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from "moment";
import EditBarangayModal from "../components/UserInformation/editBarangayModal.vue";
export default {
  components: {
    EditBarangayModal,
  },
  data() {
    return {
      edit_form: false,
      show_details: false,
      picture: null,
      picture_url: null,
      uploading: false,
    };
  },
  computed: {
    ...mapGetters({
      complaints: "complaint/complaints",
      user: "auth/user",
    }),
  },
  methods: {
    format_date(item) {
      console.log(item);
      var x = moment(item).format("MMM D, YYYY");
      return x;
    },
    insert_image(e) {
      this.picture = e.target.files[0];
      this.picture_url = URL.createObjectURL(this.picture);
    },
    upload_image() {
      this.uploading = true;
      const storage = getStorage();
      const storageRef = ref(storage, "Barangay_Logos/" + this.picture_url);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, this.picture).then(() => {
        getDownloadURL(storageRef).then((downloadURL) => {
          this.upload_seal({ id: this.user.id, seal_url: downloadURL }).then(
            () => {
              alert("Seal Uploaded!");
              this.uploading = false;
            }
          );
        });
      });
    },
    ...mapActions({
      signin: "auth/signin",
      upload_seal: "auth/upload_seal",
    }),

    async created() {},
  },
};
</script>
