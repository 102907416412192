<template>
  <v-row
    v-if="complaint.current_table == 'Conciliation'"
    dense
    align="center"
    justify="center"
  >
    <v-col>
      <v-card>
        <v-card-title style="color: grey" class="subtitle-2 pb-0">
          CONCILIATORS
        </v-card-title>
        <v-card-subtitle v-if="complaint.conciliation.conciliators == 'null'">
          <v-card-text>None</v-card-text>
        </v-card-subtitle>
        <v-row v-else class="d-flex justify-center">
          <v-col
            cols="3"
            dense
            v-for="(lupon, i) in JSON.parse(
              complaint.conciliation.conciliators
            )"
            :key="i"
            ><span class="d-flex justify-center"
              ><v-img src="@/assets/profile.jpg" width="30"></v-img
            ></span>
            <span class="d-flex justify-center">
              {{ display_conciliator_full_name(lupon) }}</span
            ></v-col
          >
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <add-conciliator-modal :complaint="complaint" />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddConciliatorModal from "@/components/AddConciliatorModal.vue";
export default {
  components: {
    AddConciliatorModal,
  },
  props: { complaint: Object },
  data: () => ({
    add_conciliators_dialog: false,
    edit_conciliators_dialog: false,
  }),
  computed: {
    ...mapGetters({
      lupons: "lupon/lupons",
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      edit_conciliation_lupons: "complaint/edit_conciliation_lupons",
    }),
    edit_conciliators() {
      this.dialog2 = true;
    },
    add_conciliators() {
      this.add_conciliators_dialog = true;
    },
    display_conciliator_full_name(lupon_id) {
      let lupon = "";
      this.lupons.forEach((element) => {
        if (element.id == lupon_id) lupon = element.full_name;
      });
      return lupon;
    },
  },
  created() {
    // console.log(this.complaint);
  },
};
</script>

<style></style>
