<template>
  <v-container fluid>
    <v-row dense>
      <v-col class="pa-5">
        <span class="deep-purple--text accent-4 font-weight-bold headline">
          FREQUENTLY ASKED QUESTIONS
        </span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card>
          <faq-component />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FaqComponent from "../components/FAQsManagement/FaqComponent.vue";
export default {
  components: {
    FaqComponent,
  },
  data() {
    return {};
  },
};
</script>
