import axios from "axios";

export default {
  namespaced: true,
  state: {
    lupons: null,
    lupon: null,
  },
  getters: {
    lupons(state) {
      return state.lupons;
    },
    lupon(state) {
      return state.lupon;
    },
  },
  mutations: {
    SET_LUPONS(state, lupons) {
      state.lupons = lupons;
    },
    SET_LUPON(state, lupon) {
      state.lupon = lupon;
    },
    ADD_LUPON(state, lupon) {
      state.lupons.unshift(lupon);
      state.lupon = lupon;
    },
    DELETE_LUPON(state, lupon) {
      let filtered = state.lupons.filter((m) => {
        return m.id != lupon.id;
      });
      state.lupons = filtered;
    },
    GET_LUPON(state, lupon_id) {
      //state.lupon = lupon;
      state.lupons.forEach((m) => {
        if (m.id == lupon_id) {
          state.lupon = m;
        }
      });
      //console.log(state.lupon);
    },
    EDIT_LUPON(_, lupon) {
      console.log(lupon);
    },
    ADD_LUPON_CASES_HANDLED(state, lupons) {
      let arr = [];
      JSON.parse(lupons).forEach((id) => {
        let lupon = state.lupons.filter((m) => {
          return m.id == id;
        });
        lupon[0].cases_handled++;
        arr.push(lupon);
      });
      state.lupons = arr;
    },
  },
  actions: {
    async edit_lupon({ commit }, lupon) {
      let response = await axios.put("/lupon/" + lupon.id, lupon);
      commit("EDIT_LUPON", response.data);
      //console.log(response.data);
    },
    get_lupon({ commit }, lupon_id) {
      //let response = await axios.get("/lupon/" + lupon_id);
      commit("GET_LUPON", lupon_id);
      //console.log(response.data);
    },
    async delete_lupon({ commit }, lupon_id) {
      let response = await axios.delete("/lupon/" + lupon_id);
      commit("DELETE_LUPON", response.data);
      console.log(response.data)
    },
    async add_lupon({ commit }, request) {
      let response = await axios.post("/lupon", request);
      commit("ADD_LUPON", response.data);
    },
    async add_captain({ commit }, request) {
      let response = await axios.post("/add_captain", request);
      console.log(response.data);
      commit("SET_LUPON", response.data);
    },
    async edit_captain({ commit }, request) {
      let response = await axios.put("/edit_captain", request);
      console.log(response.data);
      commit("SET_LUPON", response.data);
    },
    // get lupons
    async get_lupons({ commit }) {
      let response = await axios.get("/lupons");
      commit("SET_LUPONS", response.data);
      //console.log(response.data);
    },
    async set_lupon({ commit }, request) {
      console.log(request);
      commit("SET_LUPON", request);
      //console.log(response.data);
    },
    async add_credit({ commit }, request) {
      let response = await axios.put("/add_credit", request);
      commit("SET_LUPONS", response.data);
    },
    async deduct_credit({ commit }, request) {
      let response = await axios.put("/deduct_credit", request);
      commit("SET_LUPONS", response.data);
    },
    async add_handled({ commit }, request) {
      let response = await axios.put("/add_handled", request);
      commit("SET_LUPONS", response.data);
    },
    async deduct_handled({ commit }, request) {
      let response = await axios.put("/deduct_handled", request);
      commit("SET_LUPONS", response.data);
    },
    add_lupon_cases_handled({ commit }, request) {
      commit("ADD_LUPON_CASES_HANDLED", request);
    },
  },
};
