<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          placeholder="type to search"
        ></v-text-field
      ></v-col>
    </v-row>

    <div v-if="settleds">
      <v-data-table
        :items="settleds"
        :headers="headers"
        fixed-header
        :search="search"
      >
        <template v-slot:[`item.complainant`]="{ item }">
          <div v-if="item.complainant != null">
            <div v-for="item in JSON.parse(item.complainant)" :key="item.id">
              {{ item.complainant_name }}
            </div>
          </div>
          <div v-else>
            <div>
              {{ item.complainant_name }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.respondent`]="{ item }">
          <div v-if="item.respondent != null">
            <div
              v-for="item in JSON.parse(item.respondent)"
              :key="item.id"
              class="mt-0"
            >
              {{ item.respondent_name }}
            </div>
          </div>
          <div v-else>
            <div>
              {{ item.respondent_name }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.case_status`]="{ item }">
          <v-chip
            class="font-weight-bold white--text"
            style="font-size: 8.5pt"
            :color="get_status_color(item.settled.case_status)"
          >
            {{ item.settled.case_status.toUpperCase() }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-col class="mt-1">
              <v-icon color="success" size="20" @click="view(item)"
                >mdi-eye-arrow-right
              </v-icon>
              <span
                class="font-weight-bold"
                style="color: green; font-size: 7pt"
                >VIEW</span
              >
            </v-col>
            <v-col class="mt-1">
              <v-icon
                color="red"
                size="20"
                :loading="submit_loading"
                @click="confirm_submit(item)"
                >mdi-keyboard-return
              </v-icon>
              <span class="font-weight-bold" style="color: red; font-size: 7pt"
                >UNDO</span
              >
            </v-col>
            <v-col class="mt-1">
              <v-icon
                :disabled="date_of_repudiation(item.settled.date_settled)"
                size="20"
                @click="confirm_repudiate(item)"
                >mdi-keyboard-return
              </v-icon>
              <span
                class="font-weight-bold"
                style="color: black; font-size: 7pt"
                >REPUDIATE</span
              >
            </v-col>
            <v-col class="mt-1">
              <v-icon color="red" size="20" @click="confirm_del(item)"
                >mdi-delete
              </v-icon>
              <span class="font-weight-bold" style="color: red; font-size: 7pt"
                >DELETE</span
              >
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.period_of_execution`]="{ item }">
          <div>
            {{ format_date(item.settled.date_of_first_hearing) }}
            -
            {{ format_date(item.settled.date_of_last_hearing) }}
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" width="400px" persistent>
        <v-card flat width="400px">
          <v-sheet
            color="deep-purple accent-4"
            class="pt-2 d-flex justify-center"
            height="40px"
            ><h3 class="white--text ml-3">Select Status</h3></v-sheet
          >
          <v-card-text>
            <v-radio-group
              class="mt-6 ml-8"
              dense
              hide-details=""
              v-model="change_status"
              v-for="x in status"
              :key="x"
            >
              <v-radio :value="x" :label="x"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="deep-purple lighten-4" @click="dialog = false">
              cancel
            </v-btn>
            <v-btn
              small
              width="70px"
              color="deep-purple accent-4"
              dark
              @click="change(change_status)"
            >
              next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="show_alert">
        <confirm-notification
          icon="mdi-alert-outline"
          class="alert"
          type="warning"
          color="red"
          :message="this.alert_message"
          @confirm-event="undo"
          @cancel-event="close_confirm"
        />
      </div>
      <div v-if="repudiate_alert">
        <confirm-notification
          icon="mdi-alert-outline"
          class="alert"
          type="warning"
          color="success"
          :message="this.alert_message"
          @confirm-event="repudiate"
          @cancel-event="close_confirm"
        />
      </div>
      <progress-linear :dialog="progress_dialog" />
    </div>
  </v-container>
</template>

<script>
import ProgressLinear from "@/components/ProgressLinear.vue";
import ConfirmNotification from "@/components/ConfirmNotification.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    ConfirmNotification,
    ProgressLinear,
  },
  data() {
    return {
      search: "",
      status_color: "",
      submit_loading: false,
      show_alert: false,
      repudiate_alert: false,
      alert_message: "Are you sure you want to undo this complaint?",
      repudiate_message: "",
      change_status_item: "",

      dialog: false,
      progress_dialog: false,
      default_status: "Ongoing",
      judges: null,

      status: ["Not yet executed", "For execution", "Executed"],

      headers: [
        {
          text: "Docket No.",
          value: "exclusive_case_no",
          width: "120px",
        },
        {
          text: "Case Title",
          value: "case_title",
          width: "300px",
        },
        {
          text: "Complainant/s",
          value: "complainant",
        },
        {
          text: "Respondent/s",
          value: "respondent",
        },

        {
          text: "Period of Execution",
          value: "period_of_execution",
          width: "200px",
        },
        {
          text: "Case Status",
          value: "case_status",
          align: "center",
          width: "50px",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          width: "240px",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      // settleds: "complaint/settleds",
      complaints: "complaint/complaints",
      user: "auth/user",
      complaint: "complaint/complaint",
    }),
    settleds() {
      if (this.complaints == null) return [];
      else {
        let filtered = this.complaints.filter((m) => {
          return m.current_table == "Execution";
        });
        return filtered;
      }
    },
  },
  methods: {
    format_date(item) {
      console.log(item);
      var x = moment(item).format("MMM D, YYYY");
      return x;
    },
    get_status_color(status) {
      if (status == null) return;
      if (status == "Settled") return "green";
      if (status == "Executed") return "green";
      if (status == "Not yet executed") return "red";
      if (status == "Repudiated") return "black";
    },

    ...mapActions({
      undo_complaint: "complaint/undo_complaint",
      change_status: "complaint/change_status",
      set_complaint: "complaint/set_complaint",
      set_notification: "notification/set_notification",
      get_notifications: "notification/get_notifications",
      delete_complaint: "complaint/delete_complaint",
    }),
    confirm_del(item) {
      let confirm = window.confirm("Are you sure you want to proceed?");
      if (confirm) {
        this.delete_complaint(item).then(() => {
          alert("Successfuly deleted");
        });
      }
    },
    date_of_repudiation(d) {
      const date_settled = new Date(
        d.slice(0, 4),
        d.slice(5, 7) - 1,
        d.slice(8, 10)
      );
      const max_date_to_repudiate = moment(
        date_settled.setDate(date_settled.getDate() + 10)
      ).format("YYYY-MM-DD");
      const date_now = moment(new Date()).format("YYYY-MM-DD");
      if (date_now > max_date_to_repudiate) {
        return true;
      } else {
        return false;
      }
    },
    view(item) {
      this.progress_dialog = true;
      item.table_name = "execution";
      this.set_complaint(item).then(() => {
        // this.$emit("clicked");
        this.$router.push("/complaintprofile/" + item.exclusive_case_no);
        this.progress_dialog = false;
      });
    },
    edit(item) {
      item.table_name = "execution";
      item.judge = item.executor;
      this.set_complaint(item).then(() => {
        this.$router.push("/editcomplaint/" + item.exclusive_case_no);
      });
    },
    open_dialog(item) {
      this.change_status_item = item;
      this.dialog = true;
    },
    confirm_repudiate(item) {
      this.alert_message = "Click confirm to repudiate this complaint";
      this.submit_loading = true;
      this.change_status_item = item;
      this.repudiate_alert = true;
    },
    confirm_submit(item) {
      this.submit_loading = true;
      this.change_status_item = item;
      this.show_alert = true;
    },
    close_confirm() {
      this.repudiate_alert = false;
      this.show_alert = false;
      this.submit_loading = false;
    },
    async repudiate() {
      this.repudiate_alert = false;
      this.progress_dialog = true;
      let obj = {
        complaint_id: this.change_status_item.id,
        table_name: "Repudiated",
        exclusive_case_no: this.change_status_item.exclusive_case_no,
      };

      await this.change_status(obj);
      this.progress_dialog = false;
      this.dialog = false;
      this.set_notification({
        message: "Complaint successfully repudiated",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
    },
    async undo() {
      this.show_alert = false;
      this.progress_dialog = true;
      let table_history = JSON.parse(
        this.change_status_item.table_route_history
      );
      let table_to = table_history[table_history.length - 2];
      this.change_status_item.table_name = table_to;
      await this.undo_complaint(this.change_status_item);
      await this.get_notifications();
      this.progress_dialog = false;
      this.set_notification({
        message: "Complaint successfully returned to previous table",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
      this.$router.push("/" + table_to);
    },
  },
  created() {},
};
</script>
<style scoped>
.v-data-table ::v-deep td {
  font-size: 12px !important;
}
</style>
