<template>
  <v-container fluid>
    <v-row dense class="mt-n6">
      <v-col>
        <filing-of-complaint-form />
      </v-col>
    </v-row>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FilingOfComplaintForm from "../components/FilingOfComplaintManagement/FilingOfComplaintForm.vue";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  components: {
    FilingOfComplaintForm,
    ProgressLinear,
  },

  data() {
    return {
      progress_dialog: false,
      printing: false,
      form: true,
      question: false,
    };
  },
  computed: {
    ...mapGetters({
      complaints: "complaint/complaints",
    }),
  },
  methods: {
    ...mapActions({
      get_complaints: "complaint/get_complaints",

      get_lupons: "lupon/get_lupons",
      get_notifications: "notification/get_notifications",
      get_search_items: "complaint/get_search_items",
      get_dashboard_summary: "complaint/get_dashboard_summary",
      get_backups: "backup/get_backups",
    }),
  },
  async created() {
    if (this.complaints == null) {
      this.progress_dialog = true;
      await this.get_complaints();
      await this.get_lupons();
      await this.get_notifications();
      await this.get_dashboard_summary();
      this.progress_dialog = false;
    }
  },
};
</script>
